import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./testimony.css";

import hand from "../../assets/images/hand.png";
import TestimonyCar from "./TestimonyCar";
function Testimony() {
  return (
    <>
      <Container className="cusContainer">
        <Row>
          <Col md={12} className="clientsays-text">
            <div className="text-center bg-white w-full md:w-2/4 center transform -translate-y-2">
              <span className="block text-lg md:text-3xl font-poppins font-semibold">WHAT OUR CLIENT SAYS</span>
            </div>
          </Col>
          <Col xs={10} sm={10} md={3} className="mx-auto">
            <div className="testBG text-center py-16">
              <img src={hand} alt="" className="center w-24 pb-7"/>
              <span className="font-poppins block text-2xl font-semibold text-white pb-7">100 +</span>
              <span className="font-poppins font-semibold text-white block ">SATISFIED CUSTOMERS</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9}>
              <TestimonyCar/>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Testimony;
