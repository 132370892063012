import React from "react";
import {useHistory} from "react-router-dom"
import "./products.css"
function ProductCard({productImg,productName,link}) {

  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo({
      top: 580,
      behavior: "smooth"
    });
  };

  return (
    <>
      <div onClick={() => history.push(link)} onClickCapture={scrollToTop} className="productCard-has-hover cursor-pointer mb-5 border-2 border-gray-300 border-solid hover:shadow-md">

        <div className="h-56 w-full overflow-hidden border-b-2 border-gray-300">
          <img src={productImg} alt="machine" className="h-full w-full object-contain" />
        </div>
        <div className="text-center py-2 px-4 flex justify-center items-center">
          <span className=" cario font-bold block" style={{color: "var(--gray)"}}>{productName}</span>
        </div>
        <button  className="w-full text-white py-3 cario font-bold productBtn" style={{backgroundColor: "var(--darkBlue)"}}>Read More</button>
      
      </div>
    </>
  );
}

export default ProductCard;
