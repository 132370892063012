import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/inline-filter-holders/04.jpg"

function InlineHolder13() {
    return (
        <>
            <ProductDetailHead productTitle={"Inline Filter Holder"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"100 Unit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS 316 / SS 316 L"} />
                <ProductTableCol leftValue={"Shape"} rightValue={"Round"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 13 mm diameter Membrane filters"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are instrumental in providing our clients a comprehensive range of excellent quality Inline Filter Holder. Our offered inline filter holder is developed under the management of skilled professionals according to the set industry norms by utilizing the quality proven basic material. For ensure their quality, the complete range is severely checked on diverse parameters. Offered inline filter holder is accessible in various stipulations and is also provided in customized options for gaining the complete satisfaction of our customers."} />
            <ProductFeatures productFeatures={["Easy to use", "Rust resistance", "Dimensional accuracy"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Unipack Filter Holders are decorously designed to support themembranes against the applied pressure differential`} />
                <ListValue value={`For sterilizing small volume of liquids, small size membrane filter holders`} />
                <ListValue value={`Rigid construction with internationally accepted GMP standard`} />
            </Lists>
        </>
    )
}

export default InlineHolder13
