import React from "react";
import "./innerbanner.css";
import {Container,Row,Col} from "react-bootstrap"

function Innerbanner({heading,banner, title}) {
  return (
    <>
      <div className={`innerBanner`} style={{backgroundImage: `url(${banner})`}}>
        <Container className="cusContainer py-3 d-flex h-100 items-center">
            <Row>
                <Col md={12}>
                    <div className={``}>
                        <span className="text-white font-cario font-semibold text-5xl block leading-tight uppercase">{heading}</span>
                        {title && <span className="text-white font-cario font-medium text-2xl">{title}</span>}
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}

export default Innerbanner;
