import React from 'react'
import "./products.css"
import { NavLink,Link } from "react-router-dom"
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import img from "../../assets/images/down-arrow.png";


function Productsidebar() {

    const scrollToTop = () => {
        window.scrollTo({
          top: 580,
          behavior: "smooth"
        });
    };

    return (
        <>
            <div className="w-full mb-4" style={{ backgroundColor: "var(--darkBlue)" }}>
                <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom border-b-2 border-gray-500 rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-3"
                    >
                        <div className="w-100 flex justify-between items-center">
                            <span className="text-white block font-cario font-14">
                                <NavLink to="/products/filter-press" className="text-white no-underline">
                                    Filtration Equipments
                                </NavLink>
                            </span>
                            <img src={img} alt="down-arrow"/>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white px-4 flex flex-col">
                        <NavLink to="/products/filter-press/plate-and-frame-filter-press" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Plate And Frame Filter Press
                        </NavLink>
                        <NavLink to="/products/filter-press/polypropylene-filter-press" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Polypropylene Filter Press
                        </NavLink>
                        <NavLink to="/products/filter-press/sparkler-filter-press" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Sparkler Filter Press
                        </NavLink>
                        <NavLink to="/products/filter-press/zero-holdup-filter-press" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Zero Hold Up Filter Press Machine
                        </NavLink>
                        <NavLink to="/products/filtration-equipments/sterility-testing-unit" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Sterility Testing Unit
                        </NavLink>
                        <NavLink to="/products/filtration-equipments/inline-filter-holder" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Inline Filter Holders
                        </NavLink>
                        <NavLink to="/products/filtration-equipments/membrane-filter-holder" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Membrane Filter Holders
                        </NavLink>
                        <NavLink to="/products/unipack-pressure-vessel-and-storage-vessel" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Sterile Pressure and Storage Vessel
                        </NavLink>
                        <NavLink to="/products/filtration-equipments/sugar-syrup-filtration" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Sugar Syrup Filtration
                        </NavLink>
                        {/* <NavLink to="/products/filtration-equipments/glucose-syrup-filtrattion" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Glucose Syrup Filtration
                        </NavLink> */}
                        {/* <NavLink to="/products/filter-press/plate-frame-type-filter-press-machine" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                            Plate & Frame Type Filter Press Machine
                        </NavLink> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom border-b-2 border-gray-500 rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-3"
                    >
                        <div className="w-100 flex justify-between items-center">
                            <span className="text-white block font-cario font-14">
                                <NavLink to="/products/pharmaceutical-machinery" className="text-white no-underline">
                                    Pharmaceutical Machinery
                                </NavLink>
                            </span>
                            <img src={img} alt="down-arrow"/>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white px-4 flex flex-col">
                        <NavLink to="/products/pharmaceutical-machinery/linear-bottle-washing-machines" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Linear Bottle Washing Machines
                        </NavLink>
                        <NavLink to="/products/powder-filling-machine/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Powder Filling Machine, Rubber Stoppering and Cap Sealing Machine
                        </NavLink>
                        <NavLink to="/products/vial-filling-machine/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Vial Filling Machine
                        </NavLink>
                        <NavLink to="/products/wire-mesh-conveyor/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Stainless Steel Wire Mesh Conveyor
                        </NavLink>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-3"
                    >
                        <div className="w-100 flex justify-between items-center">
                            <span className="text-white block font-cario font-14">
                                <NavLink to="/products/packaging-machinery" className="text-white no-underline">
                                    Packaging Machinery
                                </NavLink>
                            </span>
                            <img src={img} alt="down-arrow"/>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white px-4 flex flex-col">
                        {/* <NavLink to="/products/packaging-machinery/automatic-adhesive-tape-slicing" onClick={scrollToTop} activeClassName='product-active' className=" no-underline text-white font-cario text-sm block mb-3">
                        Automatic Adhesive Tape Slicing Machine
                        </NavLink> */}
                        <NavLink to="/products/tape-slicing-machine" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Automatic Adhesive Tape Slicing Machine
                        </NavLink>
                        <NavLink to="/products/syrup-blending-machine/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Syrup Blending Machine
                        </NavLink>
                        <NavLink to="/products/milk-storage-tank/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Milk Storage Tanks
                        </NavLink>
                        <NavLink to="/products/depyrogenation-tunnel/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Depyrogenation Tunnel
                        </NavLink>
                        <NavLink to="/products/two-head-vial-filling/info-on-request" onClick={scrollToTop} activeClassName='product-active' className=" no-underline prod-white font-cario text-sm block mb-3">
                        Automatic Two Head Vial Filling & Rubber Stoppering Machine
                        </NavLink>
                        {/* <NavLink to="/" className=" no-underline prod-white font-cario text-sm block mb-3">
                        Automatic Two Head Vial Filling & Rubber Stoppering Machine
                        </NavLink> */}
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/membrane-filter-holder" className="text-white no-underline">
                                    Membrane Filter Holder
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/membrane-filter-holder/membrane-filter-holder-90mm" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            Membrane Filter Holder (90mm) - STD
                        </Link>
                        <Link to="/products/membrane-filter-holder/membrane-filter-holder-bta-with-diaphragm-valve-gm-valve" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            Membrane Filter Holder BTA with Diaphragm Valve Gm Valve
                        </Link>
                        <Link to="/products/membrane-filter-holder/membrane-filter-holder-142mm-bta" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            Membrane Filter Holder (142MM) - BTA
                        </Link>
                        <Link to="/products/membrane-filter-holder/membrane-filter-holder-90mm-bta" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            Membrane Filter Holder (90MM) - BTA
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/filtration-equipments" className="text-white no-underline">
                                    Filteration Equipments
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/filtration-equipments/sparkler-filter" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Sparkler Filter
                        </Link>
                        <Link to="/products/filtration-equipments/sterilizing-tunnel" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Sterilizing Tunnel
                        </Link>
                        <Link to="/products/filtration-equipments/sterile-pressure-vessel" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Sterile Pressure Vessel
                        </Link>
                        <Link to="/products/filtration-equipments/glucose-syrup-filtrattion" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Glucose Syrup Filtration
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/unipack-filter-press" className="text-white no-underline">
                                    Unipack Filter Press
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/unipack-filter-press/unipack-hydraulic-closing" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack Plate and Frame Type Filter Press with Hydraulic Closing System
                        </Link>
                        <Link to="/products/unipack-filter-press/unipack-hydraulic-closing-silicon" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack Plate and Frame Type Filter Press Hydraulic Closing System (With Silicon Washer)
                        </Link>
                        <Link to="/products/unipack-filter-press/unipack-manual-closing" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack Plate and Frame Type Filter Press with Manual Closing Device
                        </Link>
                        <Link to="/products/unipack-filter-press/unipack-manual-closing-silicon" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack Plate and Frame Type Filter Press Manual Closing System (With Silicon Washer)
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/inline-filter-holder" className="text-white no-underline">
                                    Inline Filter Holders
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/inline-filter-holder/inline-filter-holders" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Inline Filter Holders
                        </Link>
                        <Link to="/products/inline-filter-holder/ss-inline-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        SS Inline Filter Holder
                        </Link>
                        <Link to="/products/inline-filter-holder/inline-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Inline Filter Holder
                        </Link>
                        <Link to="/products/inline-filter-holder/inline-filter-holder-13" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Inline Filter Holder
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/unipack-filter-holder" className="text-white no-underline">
                                    Inline Filter Holder
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/unipack-filter-holder/unipack-47mm-inline-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack 47mm Inline Filter Holder
                        </Link>
                        <Link to="/products/unipack-filter-holder/unipack-25mm-inline-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack 25mm Inline Filter Holder
                        </Link>
                        <Link to="/products/unipack-filter-holder/unipack-13mm-inline-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Unipack 13mm Inline Filter Holder
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link className="text-white no-underline"
                                    to="/products/unipack-filter-Unit"
                                >
                                    Sterility Testing Unit
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/unipack-filter-Unit/unipack-single-place-sterility-testing-unit" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Single Place Sterility Testing Unit
                        </Link>
                        <Link to="/products/unipack-filter-Unit/unipack-three-place-sterility-testing-unit" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Three Place Sterility Testing Unit
                        </Link>
                        <Link to="/products/unipack-filter-Unit/unipack-six-place-sterility-testing-unit" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Six Place Sterility Testing Unit
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link className="text-white no-underline"
                                    to="/products/unipack-membrane-filter-holder"
                                >
                                    Membrane Filter Holder
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/unipack-membrane-filter-holder/unipack-293mm-membrane-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            293mm Membrane Filter Holder
                        </Link>
                        <Link to="/products/unipack-membrane-filter-holder/unipack-142mm-membrane-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            142mm Membrane Filter Holder
                        </Link>
                        <Link to="/products/unipack-membrane-filter-holder/unipack-90mm-membrane-filter-holder" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            90mm Membrane Filter Holder
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/tape-slicing-machine" className="text-white no-underline">
                                    Automatic Adhesive Tape Slicing Machine
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/tape-slicing-machine" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            Automatic Adhesive Tape Slicing Machine
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/zero-hold-up-filter-press-machine-for-food-industry" className="text-white no-underline">
                                    Zero Hold Up Filter Press Machine for Food Industry
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/zero-hold-up-filter-press-machine-for-food-industry" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Zero Hold Up Filter Press Machine For Food Industry
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/acid-filtration-system" className="text-white no-underline">
                                    Acid Filtration System
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/acid-filtration-system" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Acid Filtration System
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/unipack-pressure-vessel-and-storage-vessel" className="text-white no-underline">
                                    Sterile Pressure Vessel & Storage Vessel
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/unipack-pressure-vessel-and-storage-vessel" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Sterile Pressure Vessel and Storage Vessel
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link className="text-white no-underline"
                                    to="/products/sterility-testing-unit/sugar-dissolving-tank"
                                >
                                    Sugar Syrup Filtration
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/sterility-testing-unit/sugar-dissolving-tank" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                            Sugar Syrup Filtration
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion className="bg-transparent shadow-none accordHead accordHotelRoom rounded-none">
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className=" accordHead font-14 px-0"
                    >
                        <div className="w-100 flex items-center">
                            <span className="text-white block font-cario font-14">
                                <Link to="/products/turn-key-project-management" className="text-white no-underline">
                                    Turn Key Project Management
                                </Link>
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="text-white p-0 flex flex-col">
                        <Link to="/products/turn-key-project-management" onClick={scrollToTop} className=" no-underline text-white font-cario text-sm block mb-3">
                        Turn Key Project Management
                        </Link>
                    </AccordionDetails>
                </Accordion> */}
            </div>
        </>
    )
}

export default Productsidebar
