import React from 'react'
import { Row, Col } from "react-bootstrap";

function ProductChart({v1,v2,v3,v4,v5,v6,v7,v8}) {
    return (
        <>
            <Row className="mx-0 py-1 border-b border-solid border-gray-300 productList">
                <Col xs={1} sm={1} md={2} className="px-0">
                    <div
                        className="font-cario font-bold text-base font-10"
                        style={{ color: "var(--gray)" }}
                    >
                        {v1}
                    </div>
                </Col>
                <Col xs={2} sm={2} md={2} className="chart-px5">
                    <div
                        className="font-cario font-bold text-base font-10 text-center "
                        style={{ color: "var(--gray)" }}
                    >
                        {v2}
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} className="chart-px5">
                    <div
                        className="font-cario font-bold text-base font-10 text-center"
                        style={{ color: "var(--gray)" }}
                    >
                        {v3}
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} className="chart-px5">
                    <div
                        className="font-cario font-bold text-base font-10 text-center"
                        style={{ color: "var(--gray)" }}
                    >
                        {v4}
                    </div>
                </Col>
                <Col xs={2} sm={2} md={2} className="chart-px5">
                    <div
                        className="font-cario font-bold text-base font-10 text-center"
                        style={{ color: "var(--gray)" }}
                    >
                        {v5}
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} className="chart-px5">
                    <div
                        className="font-cario font-bold text-base font-10 text-center"
                        style={{ color: "var(--gray)" }}
                    >
                        {v6}
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} className="chart-px5 pump-ml">
                    <div
                        className="font-cario font-bold text-base font-10 text-center"
                        style={{ color: "var(--gray)" }}
                    >
                        {v7}
                    </div>
                </Col>
                <Col xs={2} sm={2} md={2} className="chart-px5">
                    <div
                        className="font-cario font-bold text-base font-10 text-right"
                        style={{ color: "var(--gray)" }}
                    >
                        {v8}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ProductChart
