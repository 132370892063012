import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Innerbanner from "../../components/InnerBanner/Innerbanner";

import historybg from "../../assets/images/banner/productsBanner.jpg";
import Productsidebar from "./Productsidebar";
function Products({whichProduct}) {
  return (
    <>
      <Innerbanner
        banner={historybg}
        heading={`our products`}
        title={`Offering a wide range of Machinery & Equipment`}
      />
      <Container className="cusContainer my-8">
        <Row className="mb-5">
          <Col md={12}>
            <div className="text-center">
              <h3
                style={{ borderColor: "var(--gray)" }}
                className="cert relative font-poppins font-bold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
              >
                Machinery & Equipments
              </h3>
            </div>
          </Col>
          <Col md={12} className="margin-t48">
            <div className="text-center">
              <p
                className="font-cario font-medium text-base md:text-lg"
                style={{ color: "var(--gray)" }}
              >
                We are the renowned manufacturers, suppliers, distributors and
                exporters of a highly efficient array of Machinery and
                Equipment. These products are manufactured by skilled
                professionals using optimum quality raw material and innovative
                technology. Owing to their hassle-free functioning, robust
                construction, high performance, optimum efficiency and
                reliability, these products are highly acknowledged and demanded
                in the market. Further, clients can avail the offered range from
                us in various technical specifications at market leading prices.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={3}>
            <Productsidebar />
          </Col>
          <Col xs={12} sm={12} md={9}>
            {whichProduct}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Products;
