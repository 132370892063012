import React from "react";
import TimeLine from "./TimeLine";
import { Row } from "react-bootstrap";

function HistoryTimeLine() {
  return (
    <>
      <Row>
        <TimeLine year={`2022`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
              Joint Venture with Autemi SRL based in Italy who manufacturers Polypropylene Filter Presses 
              & Pumps of various types.
            </span>
            {/* <p
              className="font-cario font-medium"
              style={{ color: "var(--gray)" }}
            >
              Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec
              admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea
              quis iuvaret expetendis his, te elit voluptua dignissim per, habeo
              iusto primis ea eam.
            </p> */}
          </div>
        </TimeLine>
        <TimeLine year={`2017-2019`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
              Participated in the Drink Tech exhibition in Mumbai and showcased all our 
              Filtration Equipment.
            </span>
          </div>
        </TimeLine>
        <TimeLine year={`2016`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
              Started undertaking Turnkey projects by associating Ajay Motlag a senior respected person 
              from Alfa Level & Tertrapack where he has spent 30 yrs. Tied up with Ultra Filtech 
              manufacturers of Polypropylene Filter Presses.
            </span>
          </div>
        </TimeLine>
        <TimeLine year={`2015`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
            Developed Packaging Products i.e Slicing Machines used for slicing of all types 
            of tapes and successfully sold over 10 machines and 2 under production.
            </span>
          </div>
        </TimeLine>
        <TimeLine year={`2010`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
            Introduced Polypropylene Plate and Frame Filter Press, Sparklers, Zero Holder Filter Press 
            and all types of vessels in Stainless Steel.
            </span>
          </div>
        </TimeLine>
        <TimeLine year={`1995`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
              Started serving Fortune 500 companies such as PALL Inc. Proud to have them for 26 yrs. 
              Received top 50 pharma orders after working for PALL.
            </span>
          </div>
        </TimeLine>
        <TimeLine year={`1990`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
            Production started - 2 big products launched i.e Stainless Steel Plate and Frame filter press & 
            Linear Wile washing machine & Liner Bottle Washing machine.
            </span>
          </div>
        </TimeLine>
        <TimeLine year={`1989`}>
          <div className="bg-gray-100 historyPara">
            <span className="font-cario font-bold text-base md:text-lg">
              Incorporated
            </span>
          </div>
        </TimeLine>
      </Row>
    </>
  );
}

export default HistoryTimeLine;
