import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/pharmaceutical-machinery/03.jpg"

function VialFilling() {
    return (
        <>
        <ProductDetailHead productTitle={"Vial Filling Machine"} productPrice={""} carImg={[image]}>
          <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"1 Unit"} />
          <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
          <ProductTableCol leftValue={"Size"} rightValue={"Vials- 2 ml to 50 ml; bottle- 15ml to 500ml"} />
          <ProductTableCol leftValue={"Material"} rightValue={"SS"} />
          <ProductTableCol leftValue={"Applications"} rightValue={"In pharmaceutical"} />
        </ProductDetailHead>
        <ProductDescription productDescription={"We are a leading manufacturer and supplier of a wide assortment of Vial Filling Stoppering. Our offered product is manufactured, using premium quality raw material under the supervision of our skilled professionals. Further, our quality experts test these products on various parameters in accordance with industry set quality standards. Offered range is available in varied specifications and can also be customized, in order to fulfill diverse requirements of our precious clients"} />
        <ProductFeatures productFeatures={["Rust weather resistance", "Functional accuracy", "Lightweight"]} />
      </>
    )
}

export default VialFilling
