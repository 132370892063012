import React from "react";
import "./certificate.css";
import { Container, Row, Col } from "react-bootstrap";
import CertificateCar from "./CertificateCar";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Certificate() {
  return (
    <>
      <div style={{ backgroundColor: "#FBFBFB" }} className="certBG">
        <Container className="pt-5 cusContainer">
          <Row>
            <Col md={12}>
              <div className="text-center">
                <h3
                  style={{ borderColor: "#D5D5D5" }}
                  className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-2/5 mx-auto"
                >
                  CERTIFICATIONS & APPRECIATIONS
                </h3>
              </div>
            </Col>
            <Col md={12}>
                <CertificateCar/>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Certificate;
