import React from 'react'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/unipack-filter-holder/03.jpg"
import image2 from "../../../../assets/images/product/unipack-filter-holder/04.jpg"

function Unipack47mm() {
    return (
        <>
        <ProductDetailHead productTitle={"Unipack 47mm Inline Filter Holder"} productPrice={""} carImg={[image1,image2]}>
          <ProductTableCol leftValue={"Usage/Application"} rightValue={"For Sterile Filtration of Pharmaceuticals and Biological solutions."} />
          <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
          <ProductTableCol leftValue={"Diameter"} rightValue={"47 mm"} />
          <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
          <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
          <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316 / SS 316L"} />
        </ProductDetailHead>
        <OtherDetails title={"Features"} />
        <ProductDescription productDescription={"For sterilizing small volumes of liquids, small size membrane filter holder. "} />
        <OtherDetails title={"Design"} />
        <ProductDescription productDescription={"Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential."} />
      </>
    )
}

export default Unipack47mm
