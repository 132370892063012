import React from 'react'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/unipack-testing-unit/05.jpg"
import image2 from "../../../../assets/images/product/unipack-testing-unit/06.jpg"

function UnipackSix() {
    return (
        <>
      <ProductDetailHead productTitle={"Six Place Sterility Testing Unit"} productPrice={""} carImg={[image1,image2]}>
        <ProductTableCol leftValue={"Usage/Application"} rightValue={"For Sterile Filtration of Pharmaceuticals and Biological solutions."} />
        <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
        <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror polished to 220 grit"} />
        <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
        <ProductTableCol leftValue={"Diameter"} rightValue={"47 mm"} />
        <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316 / SS 316L"} />
      </ProductDetailHead>
      <OtherDetails title={"Features"} />
      <ProductDescription productDescription={"Six place test units are available to simultaneously filter five test samples, respectively with a concurrent control verification of system sterility. The entire unit can be autoclaved. Essential parts of the units are a combined manifold for vacuum and flushing, filter holders & three way valves."} />
      <OtherDetails title={"Design"} />
      <ProductDescription productDescription={"Unipack offers complete systems for aseptically filtering liquid samples. The sterility test in these systems are done by the bacteria-retentive membrane filter method."} />
    </>
    )
}

export default UnipackSix
