import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/pharmaceutical-machinery/02.jpg"

function FillingMachine() {
    return (
        <>
        <ProductDetailHead productTitle={"Powder Filling Machine, Rubber Stoppering and Cap Sealing Machine"} productPrice={""} carImg={[image]}>
        <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"1 Unit"} />
        <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
        <ProductTableCol leftValue={"Capacity"} rightValue={"5 ml to 20 ml"} />
        <ProductTableCol leftValue={"Power Consumption"} rightValue={"415 V 5% 3 ac 4 Wire 50 hz"} />
        <ProductTableCol leftValue={"Machine Type"} rightValue={"Automatic"} />
        <ProductTableCol leftValue={"Vial Height"} rightValue={"25 mm to 70 mm"} />
        <ProductTableCol leftValue={"Dimensions"} rightValue={"2940 mm X 875 mm X 1600 mm"} />
        <ProductTableCol leftValue={"Bung Size"} rightValue={"20 mm"} />
        <ProductTableCol leftValue={"Net Weight"} rightValue={"1150 Kg"} />
        <ProductTableCol leftValue={"Connect Load"} rightValue={"2.5 kw"} />
        <ProductTableCol leftValue={"Production"} rightValue={"100-120 Vpm (Single Dose)"} />
        <ProductTableCol leftValue={"Vacuum Supply"} rightValue={`10 Cfm AT - 25" (635 mm) Hg Through Filters`} />
        <ProductTableCol leftValue={"Vial Capacity"} rightValue={"5 - 20 ml"} />
        <ProductTableCol leftValue={"Vial Diameter"} rightValue={"14 mm to 35 mm"} />
        </ProductDetailHead>
        <ProductDescription productDescription={"The Powder Filling Rubber Stoppering and Cap Sealing Machine is designed and engineered to meet the upcoming demands of the industry backed with rugged construction. It is capable of filling injectable powders into the vials and automatically apply rubber stopper (bung) to the filled vials at a speed of 120 vials per minute. The various sizes of vials can be managed in the same machine with the help of change parts. Utmost care is taken in the design to keep the changeover time as low as possible for improved productivity"} />
        <ProductFeatures productFeatures={["Corrosion resistance", "Negligible maintenance", "Durability", "Accuracy: Within 1% OF Fill Size", "Filing Size: 50 mg TO 450 mg IN Powder Wheel 450, 400 mg TO 1.5g IN Powder Wheel 1500"]} />
        <OtherDetails title={"Additional Information:"} />
        <Lists>
            <ListValue headHighlight headValue={"Item Code"} value={`2`} />
      </Lists>
      </>
    )
}

export default FillingMachine
