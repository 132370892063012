import React from "react";
import { Col } from "react-bootstrap";
function TimeLine(props) {
  return (
    <>
      <Col xs={3} sm={3} md={2}>
        <div className="text-right margin-t32">
          <span className="font-poppins font-bold text-lg md:text-2xl" style={{color: 'var(--darkBlue)'}}>{props.year}</span>
        </div>
      </Col>
      <Col xs={2} sm={2} md={1}>
          <div className="text-center circleContainer h-full flex justify-center margin-t32">
              <div className="circle"></div>
          </div>
      </Col>
      <Col xs={7} sm={7} md={9} className="mb-10 contentContainer">
          {props.children}
      </Col>
    </>
  );
}

export default TimeLine;
