import React from 'react'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Box } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import AutoPopupStyle from "./AutoPopupStyle";
import "./AutoPopup.css"

const useStyles = makeStyles(AutoPopupStyle);

const AutoPopup = (props) => {
    const classes = useStyles(props);
    const rootRef = React.useRef(null);
    const [open, setOpen] = React.useState(props.autoOpen || false);
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <>
        <Box className={classes.root} ref={rootRef}>
        <Box className={classes.triggerComponent} onClick={handleOpen}>
            {props?.triggerComponent}
        </Box>
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            fullWidth="true"
            container={() => rootRef.current}
            open={open}
            onClose={handleClose}
            className={classes.modal}
        >
            <Box className={classes.modalBox} id="AutoPopup-Modal">
            <Box className={classes.closeButton}>
                <CloseIcon onClick={handleClose} className={classes.closeIcon} />
            </Box>
            {/* <Box className={classes.header}>
                {props.header?.map((component) => {
                return component;
                })}
            </Box> */}
            <Box className={classes.modalBody}>
                {props.bodyComponents?.map((component) => {
                return component;
                })}
            </Box>
            </Box>
        </Modal>
        </Box>
    </>
  )
}

export default AutoPopup

export const maxWidth = ["xs", "sm", "md", "ls", "xl"];

AutoPopup.Prototypes = {
  /**component that opens the modal on click, can any Null or any single component */
  triggerComponent: PropTypes.element,
  /**array of components to be displayed inside the modal header*/
  header: PropTypes.arrayOf(PropTypes.element),
  /**if True, open the modal on page load*/
  autoOpen: PropTypes.bool,
  /**The maxWidth the modal body has to span */
  maxWidth: PropTypes.oneOf(maxWidth)
};

AutoPopup.defaultProps = {
  triggerComponent: (
    <Button variant="contained" size="large" color="primary" style={{display: "none"}}>
      AutoPopup Modal
    </Button>
  ),

  header: [
    //<img src="https://media.giphy.com/media/tpTOw6sljB2U/giphy.gif" alt='' />,
    <h1>Header Component</h1>
  ],

  bodyComponents: [

    <div className="iframe-main-video">
      <iframe
        src="https://www.youtube.com/embed/BbnExJzy45s"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
      {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/BbnExJzy45s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
    </div>

  ],

  autoOpen: true,

  maxWidth: "md"
};