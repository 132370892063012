import React from "react";
import "./list.css"
import { Container, Row, Col } from "react-bootstrap";

function Lists(props) {
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className=" mt-4">
                {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Lists;
