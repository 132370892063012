import React from 'react'
import {Container,Row,Col} from "react-bootstrap"
import ProductHeadDescription from '../../ProductHeadDescription';
import ProductCard from '../../ProductCard';
import image1 from "../../../../assets/images/product/filteration-equipments/01.png";
import image2 from "../../../../assets/images/product/filteration-equipments/02.jpg";
import image3 from "../../../../assets/images/product/filteration-equipments/03.png";
import image4 from "../../../../assets/images/product/filteration-equipments/04.jpg";
import image5 from "../../../../assets/images/product/filteration-equipments/05.jpg";
import image6 from "../../../../assets/images/product/filteration-equipments/06.jpg";
import image7 from "../../../../assets/images/product/filteration-equipments/07.jpg";

function Filtrationequipments() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Filtration Equipments"} description={"Providing you the best range of sparkler filter, sterilizing tunnel, glucose syrup filtration, sterile pressure vessel, depyrogeneration tunnel and sterility testing unit with effective & timely delivery."}/>
                    </Col>
                    {/* <Col md={4}>
                        <ProductCard productImg={image1} productName={`Sparkler Filter`} link={"/products/filtration-equipments/sparkler-filter"}/>
                    </Col> */}
                    {/* <Col md={4}>
                        <ProductCard productImg={image2} productName={`Sterilizing Tunnel`} link={"/products/filtration-equipments/sterilizing-tunnel"}/>
                    </Col> */}
                    {/* <Col md={4}>
                        <ProductCard productImg={image3} productName={`Glucose Syrup Filtration`} link={"/products/filtration-equipments/glucose-syrup-filtrattion"}/>
                    </Col> */}
                    {/* <Col md={4}>
                        <ProductCard productImg={image4} productName={`Sterile Pressure Vessel`} link={"/products/filtration-equipments/sterile-pressure-vessel"}/>
                    </Col> */}
                    {/* <Col md={4}>
                        <ProductCard productImg={image5} productName={`Depyrogeneration Tunnel`} link={"/products/filtration-equipments/depyrogeneration-tunnel"}/>
                    </Col> */}
                    {/* <Col md={4}>
                        <ProductCard productImg={image6} productName={`Sterility Testing Unit`} link={"/products/filtration-equipments/sterility-testing-unit"}/>
                    </Col> */}
                    {/* <Col md={4}>
                        <ProductCard productImg={image7} productName={`Sterility Testing Units`} link={"/products/filtration-equipments/sterility-testing-units"}/>
                    </Col> */}
                </Row>
            </Container>
        </>
    )
}

export default Filtrationequipments
