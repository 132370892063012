import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/packaging-machinery/05.jpg"


function AutomaticTwoHeadFilling() {
    return (
        <>
            <ProductDetailHead productTitle={"Automatic Two Head Vial Filling & Rubber Stoppering Machine"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Filling Accuracy"} rightValue={"Within +-1 %"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Filling Heads"} rightValue={"2 Heads"} />
                <ProductTableCol leftValue={"Fill Volume"} rightValue={"0.5 ml to 30ml"} />
                <ProductTableCol leftValue={"Net Weight"} rightValue={"850 Kgs"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Automatic Two Head Vial Filling & Rubber Stoppering Machine (MONOBLOCK)"} />
            <OtherDetails title={"Silent Features:"} />
            <Lists>
                <ListValue value={`Filling and bunging unit is completely in corporate in single body Structure. Mechanical Oriental Bowl & Chute for assy. for rubber stopper Placement. All Liquid Contact Parts are of S.S. 316, the Body Structure is built up of S.S. 304 Pipe.`} />
                <ListValue value={`Filling Nozzles allows very quick and precise setting and making unit compact and manageable. No Container, No Cap – Machine Stop System.`} />
                <ListValue value={`Easy reach compact panel provides ease and operationMinimum Change over Time. From one size of container or fill size to another.`} />
                <ListValue value={`Each Important individual part required for autoclaving/ sterilization can be removed easily.Operation of machine is automatic through PLC and HMI.`} />
            </Lists>
            <OtherDetails title={"TECHNICAL SPECIFICATION"} />
            <div className="px-3">
                <ProductTableCol leftValue={"Model No"} rightValue={"Model – LFB- 80"}/>
                <ProductTableCol leftValue={"Filling Heads"} rightValue={"2 Heads"}/>
                <ProductTableCol leftValue={"Output/ Min"} rightValue={"60 to 80 Vials (Depending on the nature the liquid and its fill size or volume)"}/>
                <ProductTableCol leftValue={"Power Characteristics"} rightValue={"230 V, 50Hz, Single Phase 3 Wire System"}/>
                <ProductTableCol leftValue={"Input (Container Dia/ Height)"} rightValue={"16mm Dia. (2ml) to 34mm Dia.(30 ml)"}/>
                <ProductTableCol leftValue={"Fill Volume"} rightValue={"0.5 ml to 30ml"}/>
                <ProductTableCol leftValue={"Filling Accuracy"} rightValue={"Within ±1 %"}/>
                <ProductTableCol leftValue={"Net Weight"} rightValue={"850 Kgs."}/>
                <ProductTableCol leftValue={"Turn Table"} rightValue={"660 kgs"}/>
                <ProductTableCol leftValue={"Machine Length"} rightValue={"1300mm"}/>
                <ProductTableCol leftValue={"Machine width"} rightValue={"800mm"}/>
                <ProductTableCol leftValue={"Machine height"} rightValue={"1400mm"}/>
            </div>
            <OtherDetails title={"Additional Information:"} />
            <Lists>
                <ListValue value={`Pay Mode Terms: Other`} />
            </Lists>
        </>
    )
}

export default AutomaticTwoHeadFilling
