import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import prod1 from "../../../../assets/images/product/membrane-filter-holder/1.jpg"
import prod2 from "../../../../assets/images/product/membrane-filter-holder/2.jpg"
import prod3 from "../../../../assets/images/product/membrane-filter-holder/3.jpg"

function MembraneFilterNew() {
  return (
    <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <span className="block font-poppins font-bold text-xl text-center mb-5" style={{color: 'var(--darkBlue)'}}>MEMBRANE FILTER HOLDER - STD & BTA*</span>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod1} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold text-lg my-2">
                            90mm
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod2} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold text-lg my-2">
                            293mm
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod3} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold text-lg my-2">
                            142mm
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="">
                        <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                        <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
                        <ProductTableCol leftValue={"Capacity"} rightValue={"For 90mm, 142mm, 293mm diameter membrane filters"} />
                        <ProductTableCol leftValue={"Material Grade"} rightValue={"S.S. 316/S.S. 316L"} />
                        <ProductTableCol leftValue={"Finish"} rightValue={"Mirror polished to 220 grit"} />
                        <ProductTableCol leftValue={`Design`} rightValue={`Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential`} />
                    </div>
                </Col>
                <OtherDetails title={"Special Feature"} />
                <ProductDescription productDescription={"Bubble Test Arrangement - 'Bubble Point Test' is a non-destructive test to check the intergrity of the membrane filter, before and after the filtration. The bubble point is the pressure required to force air through the pores of a wet filter. If a membrane cannot with stand the pressure below bubble point level, it Indicates leak in the filter. A built in system for bubble testing can be provided with the membrane holders by in building a set of liquid & air control valves"} />
            </Row>
        </Container>
    </>
  );
}

export default MembraneFilterNew;