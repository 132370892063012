import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import image from "../../../../assets/images/product/filteration-equipments/06.jpg"


function SterilityTestingUnits() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterility Testing Units"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Owing to our rich industry experience, we are offering a wide range of Sterility Testing Unit. Our offered sterility testing unit is developed under the management of skilled professionals according to the set industry norms by utilizing the quality proven basic material. For ensure their quality, the complete range is severely checked on diverse parameters. Offered sterility testing unit is accessible in various stipulations and is also provided in customized options for gaining the complete satisfaction of our customers."} />
            <ProductFeatures productFeatures={["Application specific design", "Durability", "Easy installation"]} />
        </>
    )
}

export default SterilityTestingUnits
