import React from 'react'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/filter-press/08.jpg"
import ProductChart from '../../ProductComponents/ProductChart'

function SparklerTypeFilterPress() {
    return (
        <>
            <ProductDetailHead productTitle={"Sparkler Type Filter Press"} productPrice={"Approx. Rs 2 Lakh / onwards"} carImg={[image]}>
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"No Of Plates"} rightValue={"6"} />
                <ProductTableCol leftValue={"Body Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Dimension"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"850X550X900 mm"} />
            </ProductDetailHead>
            <div className="padding-x16 margin-t48">
            <ProductChart v1={"MODEL"} v2={"TYPE"} v3={"SIZE"} v4={"FILTER PLATE"} v5={"CAKE HOLDING CAPACITY"} v6={"OUTPUT Ltrs/H"} v7={"PUMP"} v8={"DIMENSION"}/>
            <ProductChart v1={"UMP-FP-ZH-86"} v2={"SHALLOW"} v3={`8"`} v4={"6"} v5={"4.8"} v6={"600"} v7={`1/2"`} v8={"850X550X900"}/>
            <ProductChart v1={"UMP-FP-ZH-88"} v2={"SHALLOW"} v3={`8"`} v4={"8"} v5={"6.4"} v6={"700"} v7={`1/2"`} v8={"850x550x950"}/>
            <ProductChart v1={"UMP-FP-ZH-148"} v2={"SHALLOW"} v3={`14"`} v4={"8"} v5={"25"} v6={"1800"} v7={`1"`} v8={"1000x750x1200"}/>
            <ProductChart v1={"UMP-FP-ZH-1410"} v2={"SHALLOW"} v3={`14"`} v4={"10"} v5={"30"} v6={"2000"} v7={`1"`} v8={"1000x750x1250"}/>
            <ProductChart v1={"UMP-FP-ZH-188"} v2={"DEEP"} v3={`18"`} v4={"8"} v5={"53"} v6={"3500"} v7={`1&1/2"`} v8={"1200x750x1250"}/>
            <ProductChart v1={"UMP-FP-ZH-1812"} v2={"DEEP"} v3={`18"`} v4={"12"} v5={"80"} v6={"4000"} v7={`1&1/2"`} v8={"1200x750x1450"}/>
            <ProductChart v1={"UMP-FP-ZH-1815"} v2={"SHALLOW"} v3={`18"`} v4={"15"} v5={"44"} v6={"5000"} v7={`1&1/2"`} v8={"1200x750x1450"}/>
            <ProductChart v1={"UMP-FP-ZH-1823"} v2={"SHALLOW"} v3={`18"`} v4={"23"} v5={"68"} v6={"7000"} v7={`1&1/2"`} v8={"1200x750x1650"}/>
            <ProductChart v1={"UMP-FP-ZH-2412"} v2={"DEEP"} v3={`24"`} v4={"12"} v5={"149"} v6={"7000"} v7={`2"`} v8={"1100x1550x1350"}/>
            <ProductChart v1={"UMP-FP-ZH-2415"} v2={"SHALLOW"} v3={`24"`} v4={"15"} v5={"84"} v6={"8000"} v7={`2"`} v8={"1100x1550x1550"}/>
            <ProductChart v1={"UMP-FP-ZH-2424"} v2={"SHALLOW"} v3={`24"`} v4={"24"} v5={"125"} v6={"12500"} v7={`2"`} v8={"1100x1550x1850"}/>
            <ProductChart v1={"UMP-FP-ZH-337"} v2={"DEEP"} v3={`33"`} v4={"7"} v5={"176"} v6={"8200"} v7={`2&1/2"`} v8={"1325x1325x1700"}/>
            <ProductChart v1={"UMP-FP-ZH-3310"} v2={"SHALLOW"} v3={`33"`} v4={"10"} v5={"151"} v6={"11500"} v7={`2&1/2"`} v8={"1325x1325x1775"}/>
            <ProductChart v1={"UMP-FP-ZH-3314"} v2={"SHALLOW"} v3={`33"`} v4={"14"} v5={"211"} v6={"15500"} v7={`2&1/2"`} v8={"1325x1325x1975"}/>
            </div>
        </>
    )
}

export default SparklerTypeFilterPress
