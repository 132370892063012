import React from "react";
import { Row, Col } from "react-bootstrap";
import "./gallery.css"
import g1 from "../../assets/images/gallery/Office01.jpg";
import g2 from "../../assets/images/gallery/Office02.jpg";
import g3 from "../../assets/images/gallery/Office03.jpg";
import g4 from "../../assets/images/gallery/Office04.jpg";

function Office() {
  return (
    <>
      <div data-toggle="modal" data-target="#officeModal">
        
      <Row className="mx-0">
        <Col xs={6} sm={6} md={3} className="px-0">
          <div className=" m-1" >
            <a href={g1} class="thumbnail" value="OfficeImage1">
              <img src={g1} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselA" data-slide-to="0"/>
            </a>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className="px-0">
          <div className=" m-1" >
            <a href={g2} class="thumbnail" value="OfficeImage2">
              <img src={g2} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselA" data-slide-to="1"/>
            </a>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className="px-0">
          <div className=" m-1" >
            <a href={g3} class="thumbnail" value="OfficeImage3">
              <img src={g3} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselA" data-slide-to="2"/>
            </a>
          </div>
        </Col>
        {/* <Col xs={6} sm={6} md={3} className="px-0">
          <div className=" m-1" >
            <a href={g4} class="thumbnail" value="OfficeImage4">
              <img src={g4} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselA" data-slide-to="3"/>
            </a>
          </div>
        </Col> */}
      </Row>

    </div>



      <div class="modal fade" id="officeModal" tabIndex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog intermodal modal-dialog-centered" role="document">
          <div class="modal-content w-auto">
            <div class="modal-body">
      
              <div id="myCarouselA" class="carousel slide gallery-carousel" data-interval="false">
                {/* Indicators */}
                {/* <ol class="carousel-indicators">
                  <li data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                  <li data-target="#myCarousel1" data-slide-to="1"></li>
                  <li data-target="#myCarousel1" data-slide-to="2"></li>
                  <li data-target="#myCarousel1" data-slide-to="3"></li>
                  <li data-target="#myCarousel1" data-slide-to="4"></li>
                </ol> */}

                <div class="carousel-inner">
                  <div class="carousel-item active" value="OfficeImage1">
                    <img src={g1} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="OfficeImage2">
                    <img src={g2} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="OfficeImage3">
                    <img src={g3} alt="..." />
                  </div>

                  {/* <div class="carousel-item" value="OfficeImage4">
                    <img src={g4} alt="..." />
                  </div> */}
                </div>
      
                {/* Left and right controls */}
                <a class="carousel-control-prev" href="#myCarouselA" data-slide="prev">
                  <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#myCarouselA" data-slide="next">
                  <span aria-hidden="true" class="carousel-control-next-icon"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
      
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Office;
