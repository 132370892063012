import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/unipack-filter-press/01.jpg";
import image2 from "../../../../assets/images/product/unipack-filter-press/02.jpg";
import image3 from "../../../../assets/images/product/unipack-filter-press/03.jpg";
import image4 from "../../../../assets/images/product/unipack-filter-press/04.jpg";

function UnipackFilterPress() {
    return (
        <>
           <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Unipack Filter Press"} description={"Our product range includes a wide range of unipack plate and frame type filter press with hydraulic closing system, unipack plate and frame type filter press hydraulic closing system (with silicon washer), unipack plate and frame type filter press with manual closing device and unipack plate and frame type filter press manual closing system (with silicon washer)."}/>
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Unipack Plate and Frame Type Filter Press with Hydraulic Closing System`} link={"/products/unipack-filter-press/unipack-hydraulic-closing"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Unipack Plate and Frame Type Filter Press Hydraulic Closing System (With Silicon Washer)`} link={"/products/unipack-filter-press/unipack-hydraulic-closing-silicon"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Unipack Plate and Frame Type Filter Press with Manual Closing Device`} link={"/products/unipack-filter-press/unipack-manual-closing"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image4} productName={`Unipack Plate and Frame Type Filter Press Manual Closing System (With Silicon Washer)`} link={"/products/unipack-filter-press/unipack-manual-closing-silicon"}/>
                    </Col>
                </Row>
            </Container> 
        </>
    )
}

export default UnipackFilterPress
