import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/unipack-testing-unit/01.jpg";
import image2 from "../../../../assets/images/product/unipack-testing-unit/03.jpg";
import image3 from "../../../../assets/images/product/unipack-testing-unit/05.jpg";

function UnipackTestingUnit() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Unipack Testing Unit"} description={"Offering you a complete choice of products which include unipack single place sterility testing unit, unipack three place sterility testing unit and unipack six place sterility testing unit."} />
                    </Col>
                    {/* <Col md={4} >
                        <ProductCard productImg={"dum"} productName={`Unipack Single Place Sterility Testing Unit`} link={"/products/unipack-filter-holder/unipack-13mm-inline-filter-holder"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={"dum"} productName={`Unipack Three Place Sterility Testing Unit`} link={"/products/unipack-filter-Unit/unipack-single-place-sterility-testing-unit"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={"dum"} productName={`Unipack Six Place Sterility Testing Unit`} link={"/products/unipack-filter-Unit/unipack-three-place-sterility-testing-unit"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={"dum"} productName={`Unipack Six Place Sterility Testing Unit`} link={"/products/unipack-filter-Unit/unipack-six-place-sterility-testing-unit"} />
                    </Col> */}
                    <Col md={4}>
                        <ProductCard productImg={image1} productName={`Unipack Single Place Sterility Testing Unit`} link={"/products/unipack-filter-Unit/single-place-sterility-testing-unit"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Unipack Three Place Sterility Testing Unit`} link={"/products/unipack-filter-Unit/three-place-sterility-testing-unit"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Unipack Six Place Sterility Testing Unit`} link={"/products/unipack-filter-Unit/six-place-sterility-testing-unit"} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UnipackTestingUnit
