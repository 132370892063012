import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function OtherDetails({title}) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <span
              className="block font-cario font-bold text-2xl mt-8"
              style={{ color: "var(--darkBlue)" }}
            >
              {title}
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OtherDetails;
