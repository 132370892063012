import React from 'react'
import { Row, Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import profileImg from "../../assets/images/CEO2.jpg"

function Team02() {
    return (
        <>
            <div className="team-content">
                <Row className="team-content-row m-4 justify-center">
                    <Col md={3}>
                        <div className="team-left border-2 border-gray-200 ">
                            <div className="team-img">
                                <Image src={profileImg} className="w-full"/>
                            </div>
                            <div className="team-content">
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-3 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Name : </span>
                                    Mr. Sacchidanand Shah
                                </div>
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-3 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Designation : </span>
                                    Managing Director
                                </div>
                                <div className="team-text font-cario font-medium text-base md:text-lg text-justify mx-3 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Qualification : </span>
                                    Bachelor of Commerce from Bombay University with an expertise in Business Administration
                                </div>
                                <a className="submitform flex w-full p-3 font-cario font-bold text-white justify-center" 
                                    style={{ backgroundColor: "var(--darkBlue)" }}
                                    href="/contact"
                                >CONTACT NOW
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="team-right">
                            {/* <div className="team-thought font-poppins text-white bg-black p-4">
                                <span className="font-poppins font-bold text-4xl">“</span>
                                Bring to the table win-win survival strategies to ensure proactive domination. 
                                At the end of the day, going forward, a new normal that has evolved from generation X.
                            </div> */}
                            <div className="team-experience">
                                {/* <span className="font-bold text-3xl">Experience</span> */}
                                <p className="font-cario font-medium text-base md:text-lg mb-3" style={{ color: "var(--gray)" }}>
                                    Mr Sacchidanand J. Shah is a commerce graduate and  entrepreneur by passion par 
                                    excellence. Because of his self interest he is now versatile and master in all 
                                    factory matters including Accounts, Purchase, Taxation and Finance. Extremely 
                                    sharp and good with numbers also takes keen interest in production and quality 
                                    of all the machinery manufactured at Unipack.</p>
                                <p className="font-cario font-medium text-base md:text-lg mb-3" style={{ color: "var(--gray)" }}>
                                    He is a very pious man who is very much interested in Music and Instruments and 
                                    keen follower of Jainism in his day to day life.</p> 
                                <p className="font-cario font-medium text-base md:text-lg" style={{ color: "var(--gray)" }}>
                                    An avid reader having great knowledge in various subjects such as politics, 
                                    religion etc.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Team02
