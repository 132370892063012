import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/02.jpg"

function  BtaDiaphragm() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter Holder BTA with Diaphragm Valve Gm Valve"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Automatic Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Material"} rightValue={"S.S. 316 / S.S. 316 L"} />
                <ProductTableCol leftValue={"Installation Service"} rightValue={"yes"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 293 mm diameter membrane filters"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Membrane Filter Holder BTA with Diaphragm Valve Gm Valve."} />
            <OtherDetails title={"Specifications:"} />
            <Lists>
                <ListValue value={`Construction: Rigid construction with internationally accepted Gmp standard`} />
                <ListValue value={`Finish: Mirror polished to 220 grit`} />
                <ListValue value={`Design: Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential.`} />
            </Lists>
        </>
    )
}

export default  BtaDiaphragm
