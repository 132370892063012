import React from 'react'
import { Container, Row, Col } from "react-bootstrap";


function InfoOnRequest() {
  return (
    <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <div className="mb-5">
                        <p className=" font-cario font-normal text-xl text-gray-500 text-justify">
                            For detailed information, kindly mail us on 
                            <a  href="mailto:ump1990@gmail.com" 
                                className="font-cario font-bold no-underline mx-1 text-indigo-900">
                                ump1990@gmail.com
                            </a>
                            or call us on 
                            <a href="tel:+919820086877" 
                                className="font-cario font-bold no-underline ml-1 text-indigo-900">
                                9820086877
                            </a> 
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default InfoOnRequest;
