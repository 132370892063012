import Slider from "react-slick";

import indicator from "../../assets/images/indicators.svg";
import client1 from "../../assets/images/clients/pepsi.png";
import client2 from "../../assets/images/clients/coca-cola.png";
import client3 from "../../assets/images/clients/parle-agro.png";
import client4 from "../../assets/images/clients/hershey.png";
import client5 from "../../assets/images/clients/tetrapak.png";
import client6 from "../../assets/images/clients/bacardi.png";
import client7 from "../../assets/images/clients/goma.png";
import client8 from "../../assets/images/clients/repute.png";
import client9 from "../../assets/images/clients/pall.png";
import client10 from "../../assets/images/clients/unicorn.png";
import client11 from "../../assets/images/clients/pharmalab.jpg";
import client12 from "../../assets/images/clients/tataconsumerproducts.png";
import client13 from "../../assets/images/clients/hul.png";
import client14 from "../../assets/images/clients/jaipuria.png";
import client15 from "../../assets/images/clients/ub.png";
import client16 from "../../assets/images/clients/amul.png";
function ClientCar() {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`cusArr ${className}`}
        style={{ ...style, display: "block" }}
      >
        <img src={indicator} alt="ind" className={`indClass`} />
      </div>
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`cusArr ${className}`}
        style={{ ...style, display: "block" }}
      >
        <img
          src={indicator}
          alt="ind"
          className={`indClass transform rotate-180`}
        />
      </div>
    );
  };
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow className="cusArr" />,
    prevArrow: <PrevArrow className="cusArr" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client1} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client2} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client3} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client4} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client5} alt="clients" id="home-client-width"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client6} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client7} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client8} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client9} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client10} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client11} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client12} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client13} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client14} alt="clients" id="home-client-width"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client15} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client16} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client1} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client2} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client3} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client4} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client5} alt="clients" id="home-client-width"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client6} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client7} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client8} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client9} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client10} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client11} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client12} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client13} alt="clients" id="home-client-width2"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client14} alt="clients" id="home-client-width"/>
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client15} alt="clients" />
            </div>
          </div>
          <div className="home-clientsContainer">
            <div className="home-clients-img">
              <img src={client16} alt="clients" />
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default ClientCar;
