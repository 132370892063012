import React from 'react'
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import "./clients.css";
import historybg from "../../assets/images/banner/clientsBanner.jpg";
import ClientContent from "./ClientContent";

function Clients() {
    return (
        <>
            <Innerbanner banner={historybg} heading={`clients`}/>
            <ClientContent/>
        </>
    )
}

export default Clients;
