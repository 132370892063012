import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/membrane-filter-holder/01.jpg";
import image2 from "../../../../assets/images/product/membrane-filter-holder/02.jpg";
import image3 from "../../../../assets/images/product/membrane-filter-holder/03.jpg";
import image4 from "../../../../assets/images/product/membrane-filter-holder/04.jpg";
import image5 from "../../../../assets/images/product/membrane-filter-holder/05.jpg";
import image6 from "../../../../assets/images/product/membrane-filter-holder/06.jpg";
import image7 from "../../../../assets/images/product/membrane-filter-holder/07.jpg";
import image8 from "../../../../assets/images/product/membrane-filter-holder/08.jpg";

function MembraneFilterHolder() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Membrane Filter Holder"} description={"We are a leading Manufacturer of membrane filter holder (90mm) - std, membrane filter holder bta with diaphragm valve gm valve, membrane filter holder (142mm) - bta, membrane filter holder (90mm) - bta, membrane filter holders and 293 membrane filter holders from Thane, India."}/>
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Membrane Filter Holder (90mm) - STD`} link={"/products/membrane-filter-holder/membrane-filter-holder-90mm"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Membrane Filter Holder BTA with Diaphragm Valve Gm Valve`} link={"/products/membrane-filter-holder/membrane-filter-holder-bta-with-diaphragm-valve-gm-valve"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Membrane Filter Holder (142MM) - BTA`} link={"/products/membrane-filter-holder/membrane-filter-holder-142mm-bta"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image4} productName={`Membrane Filter Holder (90MM) - BTA`} link={"/products/membrane-filter-holder/membrane-filter-holder-90mm-bta"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image5} productName={`Membrane Filter Holders`} link={"/products/membrane-filter-holder/membrane-filter-holders"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image6} productName={`293 Membrane Filter Holders`} link={"/products/membrane-filter-holder/293-membrane-filter-holders"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image7} productName={`Membrane Filter Holder (293MM) - STD`} link={"/products/membrane-filter-holder/membrane-filter-holder-293mm"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image8} productName={`Membrane Filter`} link={"/products/membrane-filter-holder/membrane-filter"}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MembraneFilterHolder
