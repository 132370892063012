import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import historybg from "../../assets/images/banner/missionBanner.jpg";
import "./mission.css"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Mission() {
    return (
        <>
            <Innerbanner
                banner={historybg}
                heading={`Mission`}
                // title={`A Group you can trust, dating back to 1989.`}
            />
            <Container className="cusContainer my-8">
                <Row>
                    <Col md={12}>
                        <div className="text-center">
                            <h3
                                style={{ borderColor: "var(--gray)" }}
                                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                            >
                                Mission & Vision
                            </h3>
                        </div>
                    </Col>
                    <Col md={12} className="margin-t48">
                        <div className="text-center">
                            <p className="font-cario font-medium text-base md:text-lg" style={{ color: "var(--gray)" }}>
                                We, Unipack Machines Private Limited, established our operations
                                in the year 1989, as one of the trustworthy manufacturers,
                                suppliers, distributors and exporters of a massive compilation
                                of Machinery and Equipment.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="missionContainer">
                <Row>
                    <Col md={3} className="mission-left-bg">
                    </Col>
                    <Col md={3} className="mission-left-text">
                        <div className="mission-content">
                            <p className="font-poppins text-2xl md:text-3xl text-center font-bold">Mission</p>
                            <p className="font-cario font-medium text-base md:text-lg text-justify mt-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut magna venenatis, convallis metus at, facilisis nisl. 
                                Fusce sodales in mi ac elementum. Nulla congue mattis porta. Nam at est feugiat, facilisis nulla ut, posuere ex. 
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Maecenas interdum, orci at euismod dapibus. Maecenas interdum, orci at euismod dapibus.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            </p>
                        </div>
                    </Col>
                    <Col md={3} className="mission-right-bg">
                    </Col>
                    <Col md={3} className="mission-right-text">
                        <div className="mission-content">
                            <p className="font-poppins text-2xl md:text-3xl text-center font-bold">Vision</p>
                            <p className="font-cario font-medium text-base md:text-lg text-justify mt-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut magna venenatis, convallis metus at, facilisis nisl. 
                                Fusce sodales in mi ac elementum. Nulla congue mattis porta. Nam at est feugiat, facilisis nulla ut, posuere ex. 
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Maecenas interdum, orci at euismod dapibus. Maecenas interdum, orci at euismod dapibus.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Mission
