import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/inline-filter-holders/03.jpg"

function InlineHolder() {
    return (
        <>
            <ProductDetailHead productTitle={"Inline Filter Holder"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"100 Unit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS 316 / SS 316 L"} />
                <ProductTableCol leftValue={"Shape"} rightValue={"Round"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 25 mm diameter Membrane filters"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We have gained the expertise in manufacturing, exporting, distributing and supplying of Inline Filter Holder. The offered inline filter holder is broadly used in various industries for its durability. This inline filter holder is developed by our team of skillful professionals by utilizing finest quality raw materials and latest technology in accordance with universally accepted standards. Available in diverse technical specifications, we provide this inline filter holder in customized options in order to fulfill the needs of our valued customers."} />
            <ProductFeatures productFeatures={["Ruggedness", "Dimensional accuracy", "Easy installation"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Unipack Filter Holders are decorously designed to support the`} />
                <ListValue value={`membranes against the applied pressure differential`} />
                <ListValue value={`For sterilizing small volumes of liquids, small size membrane filter holders`} />
                <ListValue value={`Rigid construction with internationally accepted GMP`} />
            </Lists>
            <OtherDetails title={"Additional Information:"} />
            <Lists>
                <ListValue headHighlight headValue={"Item Code:"} value={`025mm`} />
            </Lists>
        </>
    )
}

export default InlineHolder
