import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// IMAGE
import office from "../../assets/images/gallery/office.png";
import factory from "../../assets/images/gallery/factory.png";
import calendar from "../../assets/images/gallery/calendar.png";
import team from "../../assets/images/gallery/team.png";
import Office from "./Office";
import Factory from "./Factory";
import Events from "./Events";
import Staff from "./Staff";
function GallaryTabs() {
  return (
    <>
      <Tabs className="galleyTab">
        <TabList>
          <Tab>
            <div className="flex items-center justify-center">
              <img src={office} alt="office" className="mr-3 w-10 gallery-logo" />
              <span>Our Office</span>
            </div>
          </Tab>
          <Tab>
            <div className="flex items-center justify-center">
              <img src={factory} alt="factory" className="mr-3 w-10 gallery-logo" />
              <span className="vertical-center">Factory</span>
            </div>
          </Tab>
          <Tab>
            <div className="flex items-center justify-center">
              <img src={calendar} alt="calendar" className="mr-3 w-10 gallery-logo" />
              <span>Events & Exhibitions</span>
            </div>
          </Tab>
          <Tab>
            <div className="flex items-center justify-center">
              <img src={team} alt="team" className="mr-3 w-10 gallery-logo" />
              <span className="vertical-center">Staff</span>
            </div>
          </Tab>
        </TabList>

        <TabPanel className="galleryPannel mt-5">
          <div>
            <Office />
          </div>
        </TabPanel>
        <TabPanel className="galleryPannel mt-5">
          <div>
            <Factory />
          </div>
        </TabPanel>
        <TabPanel className="galleryPannel mt-5">
          <div>
            <Events />
          </div>
        </TabPanel>
        <TabPanel className="galleryPannel mt-5">
          <div>
            <Staff />
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default GallaryTabs;
