import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/sterility-testing-unit/01.jpg"


function SterilityTestUnitSingle() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterility Test Unit (Single Place)"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 47 mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316. 304"} />
                <ProductTableCol leftValue={"Finish"} rightValue={"Mirror finish with electro-polished plates, frames screen"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Sterility Testing Unit."} />
            <OtherDetails title={"Special Features:"} />
            <Lists>
                <ListValue value={`Single place test units are available to filter test samples, respectively with a concurrent control filtration of sterile diluting fluid for an immediate overall verification of system sterility`} />
                <ListValue value={`The entire unit can be autoclaved. Essential parts of the units are for vacuum and flushing, filter holders clamps`} />
            </Lists>
            <OtherDetails title={"Specifications:"} />
            <Lists>
                <ListValue value={`Construction: Rigid construction, Manufactured as per GMP standard`} />
                <ListValue value={`Finish: Mirror polished to 220 grit`} />
                <ListValue value={`Design: Unipack offers complete systems for aseptically filtering liquid samples`} />
                <ListValue value={`The sterility test in these systems are done by the bacteria-retentive membrane filter method`} />
            </Lists>
        </>
    )
}

export default SterilityTestUnitSingle
