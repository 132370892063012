import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/filter-press/05.jpg"
import image2 from "../../../../assets/images/product/filter-press/06.png"

function PlateFilterPress() {
    return (
        <>
            <ProductDetailHead productTitle={"Plate Filter Press"} productPrice={""} carImg={[image1,image2]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"1 Unit"} />
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Filtration Capacity"} rightValue={"50 to 300 liters/hr; 1-500 litres/hr"} />
                <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror finish with electro-polished plates, frames screen"} />
                <ProductTableCol leftValue={"Size"} rightValue={"20 cm x 20 cm x 20 cm"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"Filtration of pharmaceuticals, chemical, Photographic, cosmetic, biochemistry industry"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316, 304"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Plate Filter Press."} />
            <OtherDetails title={"Special Features:"} />
            <Lists>
                <ListValue value={`Compact, easy in operation`} />
                <ListValue value={`Can be mounted on castor trolley along with pump`} />
                <ListValue value={`Construction: Horizontal square body having vertical plates with screen`} />
            </Lists>
        </>
    )
}

export default PlateFilterPress
