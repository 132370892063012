import React from 'react'
import "../products.css"
import {Container,Row,Col} from "react-bootstrap"
import ProductCarousel from "./ProductCarousel"

function ProductDetailHead({productTitle,productPrice,children,carImg}) {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={4} className="">
                        <ProductCarousel carImg={carImg}/>
                    </Col>
                    <Col md={8} className="">
                        <div className="mb-3">
                            <span className="font-poppins block font-bold text-2xl mb-3" style={{color: "var(--darkBlue)"}}>{productTitle}</span>
                            <span className="font-cario block font-bold text-xl mb-3" style={{color: "var(--lightYellow)"}}>{productPrice}</span>
                            <span className="font-cario block font-normal text-lg">Product Details:</span>
                        </div>
                        <div className="">
                            {children}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProductDetailHead
