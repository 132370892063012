import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/inline-filter-holders/01.jpg"

function InlineFilterHolders() {
    return (
        <>
            <ProductDetailHead productTitle={"Inline Filter Holders"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"100 Unit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS"} />
                <ProductTableCol leftValue={"Shape"} rightValue={"Round"} />
                <ProductTableCol leftValue={"Diameter"} rightValue={"25 mm and 47 mm"} />
                <ProductTableCol leftValue={"Packaging Type"} rightValue={"Box"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are involved in providing our clients an ample range of Inline Filter Holders. The offered filter holders are manufactured in a proficient manner with supreme quality raw material and highly advanced technology in sync with the latest market standards. These filter holders are precisely inspected on each part so as to deliver perfect range to our customers. Also, these filter holders are available with various specifications at market leading rates"} />
            <ProductFeatures productFeatures={["Rigid construction", "Fine finish", "Compact design"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`These are ideally suited for in-line filtration or sterilization of air or other gases in line systems`} />
                <ListValue value={`The filters can be replaced without detaching the holders when installing in-line systems`} />
                <ListValue value={`Bubble Test Arrangement: Bubble Point Test is a non-destructive test to check the integrity`} />
                <ListValue value={`of the membrane filter, before and after the filtration.The bubble points is the pressure required to`} />
                <ListValue value={`force air through the pores of a wet filter., If a membrane cannot withstand the pressure`} />
                <ListValue value={`below bubble point level, it indicates the leak min the filter.A built-in system for bubble testing can be`} />
                <ListValue value={`provided with the membrane holders by in building a set of liquid and air control valves`} />
            </Lists>
        </>
    )
}

export default InlineFilterHolders
