import React from 'react'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import image1 from "../../../../assets/images/product/unipack-filter-press/05.jpg"
import image2 from "../../../../assets/images/product/unipack-filter-press/06.jpg"

function UnipackManual() {
    return (
        <>
            <ProductDetailHead productTitle={"Unipack Plate and Frame Type Filter Press with Manual Closing Device"} productPrice={""} carImg={[image1,image2]}>
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Usage/Application"} rightValue={"Filtration of pharmaceutical, chemical, photographic, cosmetic, biochemistry industry."} />
                <ProductTableCol leftValue={"Design"} rightValue={"Vertical plate & frame type"} />
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Manual"} />
                <ProductTableCol leftValue={"Filter Area"} rightValue={"0.0648M (per plate)"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"From 50 to 300 liters/hr"} />
                <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror finish with electro-polished plates, frames & screen."} />
                <ProductTableCol leftValue={"Size"} rightValue={"20 x 20 cm"} />
                <ProductTableCol leftValue={"Cake Holding Frame Size"} rightValue={"13 mm"} />
                <ProductTableCol leftValue={"Cake Holding Frame Capacity"} rightValue={"0.376 liters"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 304/ SS 316"} />
            </ProductDetailHead>
            <ProductFeatures productFeatures={["Compact, easy in operation.  ", "Can be mounted on trolley and also be connected to pump. "]} />
            <OtherDetails title={"Construction :"} />
            <ProductDescription productDescription={"Horizontal square body having vertical plates with screen"} />
        </>
    )
}

export default UnipackManual
