import React , {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./facility.css";
import fact1 from "../../assets/images/Factory1.jpg";
import fact2 from "../../assets/images/Factory2.jpg";
import fact3 from "../../assets/images/Factory3.jpg";
import fact4 from "../../assets/images/Factory4.jpg";
import fact5 from "../../assets/images/Factory5.jpg";

function Facility() {

  return (
    <>
      <Container className="facility-container cusContainer">
        <Row>
          <Col md={12}>
            <div className="text-center">
              <h3
                style={{ borderColor: "#D5D5D5" }}
                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-64 mx-auto"
              >
                OUR FACILITY
              </h3>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="flex homeGallery-section" data-toggle="modal" data-target="#floorModal">
        
        <div>
          <a href={fact1} class="thumbnail floornam" value="FacilityImage1">
            <img src={fact1} class="img-fluid cursor-pointer p-1" data-target="#myCarousel3" data-slide-to="0" />
          </a>
        </div>
        <div>
          <div>
            <a href={fact2} class="thumbnail floornam" value="FacilityImage2">
              <img src={fact2} class="img-fluid cursor-pointer pl-1 pr-1 pb-1" data-target="#myCarousel3" data-slide-to="1" />
            </a>
          </div>
          <div>
            <a href={fact3} class="thumbnail floornam" value="FacilityImage3">
              <img src={fact3} class="img-fluid cursor-pointer pl-1 pr-1" data-target="#myCarousel3" data-slide-to="2" />
            </a>
          </div>
        </div>
        <div>
          <a href={fact4} class="thumbnail floornam" value="FacilityImage4">
            <img src={fact4} class="img-fluid cursor-pointer p-1" data-target="#myCarousel3" data-slide-to="3" />
          </a>
        </div>
        <div>
          <a href={fact5} class="thumbnail floornam" value="FacilityImage5">
            <img src={fact5} class="img-fluid cursor-pointer p-1" data-target="#myCarousel3" data-slide-to="4" />
          </a>
        </div>

      </div>


      <div class="modal fade" id="floorModal" tabIndex="-1" role="dialog" aria-hidden="true" style={{padding:"0px"}}>
        <div class="modal-dialog intermodal modal-dialog-centered justify-center" role="document">
          <div class="modal-content w-auto">
            {/* <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div class="modal-body">
      
              <div id="myCarousel3" class="carousel slide" data-interval="false">
                {/* Indicators */}
                {/* <ol class="carousel-indicators">
                  <li data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                  <li data-target="#myCarousel1" data-slide-to="1"></li>
                  <li data-target="#myCarousel1" data-slide-to="2"></li>
                  <li data-target="#myCarousel1" data-slide-to="3"></li>
                  <li data-target="#myCarousel1" data-slide-to="4"></li>
                </ol> */}

                <div class="carousel-inner">
                  <div class="carousel-item active" value="FacilityImage1">
                    <img src={fact1} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FacilityImage2">
                    <img src={fact2} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FacilityImage3">
                    <img src={fact3} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FacilityImage4">
                    <img src={fact4} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FacilityImage5">
                    <img src={fact5} alt="..." />
                  </div>
                </div>
      
                {/* Left and right controls */}
                <a class="carousel-control-prev" href="#myCarousel3" data-slide="prev">
                  <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#myCarousel3" data-slide="next">
                  <span aria-hidden="true" class="carousel-control-next-icon"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Facility;




























                        
