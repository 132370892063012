import React from 'react'
import { Row, Col } from "react-bootstrap";

function ProductTableCol3({ leftValue , middleValue , rightValue }) {
    return (
        <>
            <Row className="mx-0 py-1 border-b border-solid border-gray-300 productList">
                <Col xs={6} sm={6} md={4} className="px-0">
                    <div
                        className="font-cario font-normal text-base"
                        style={{ color: "var(--gray)" }}
                    >
                        {leftValue}
                    </div>
                </Col>
                <Col xs={3} sm={3} md={4}>
                    <div
                        className="font-cario font-normal text-base"
                        style={{ color: "var(--gray)" }}
                    >
                        {middleValue}
                    </div>
                </Col>
                <Col xs={3} sm={3} md={4}>
                    <div
                        className="font-cario font-normal text-base"
                        style={{ color: "var(--gray)" }}
                    >
                        {rightValue}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ProductTableCol3
