import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/inline-filter-holders/01.jpg";
import image2 from "../../../../assets/images/product/inline-filter-holders/02.jpg";
import image3 from "../../../../assets/images/product/inline-filter-holders/03.jpg";
import image4 from "../../../../assets/images/product/inline-filter-holders/04.jpg";

function Inlinefilterholder() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"INLINE Filter Holder"} description={"Pioneers in the industry, we offer inline filter holders, ss inline filter holder and inline filter holder from India."}/>
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Inline Filter Holders`} link={"/products/inline-filter-holder/inline-filter-holders"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`SS Inline Filter Holder`} link={"/products/inline-filter-holder/ss-inline-filter-holder"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Inline Filter Holder`} link={"/products/inline-filter-holder/inline-filter-holder"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image4} productName={`Inline Filter Holder`} link={"/products/inline-filter-holder/inline-filter-holder-13"}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Inlinefilterholder
