import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./clients.css";

import calender from "../../assets/images/calender.png";
import install from "../../assets/images/install.png";
import factroy from "../../assets/images/factroy.png";
import cup from "../../assets/images/cup.png";
import rep from "../../assets/images/rep.svg";
import ClientCar from "./ClientCar";
function Clients() {
  return (
    <>
      <div className="clientBG border-b border-solid border-gray-300">
        <Container className="clientContainer">
          <Row>
            <Col md={12}>
              <div className="text-center">
                <span
                  style={{ borderColor: "#D5D5D5" }}
                  className="cert relative block font-poppins font-semibold text-base md:text-2xl border-b pb-2 w-full md:w-1/2 mx-auto"
                >
                  Our high end quality manufacturing services are a perfect
                  complement to today's high tech companies
                </span>
              </div>
            </Col>
            <Col md={12} className="pt-5 pb-4">
              <Row className="justify-around">
                <div className="text-center flex flex-col items-center justify-center">
                  <img src={calender} alt="calender" className="achiveImage" />
                  <span
                    style={{ color: "#262476" }}
                    className="font-poppins block text-3xl mt-2 font-semibold"
                  >
                    30+
                  </span>
                  <span
                    style={{ color: "#5B5A5A" }}
                    className="font-poppins font-semibold text-sm"
                  >
                    years of experience
                  </span>
                </div>
                <div className="text-center flex flex-col items-center justify-center">
                  <img src={factroy} alt="calender" className="achiveImage" />
                  <span
                    style={{ color: "#262476" }}
                    className="font-poppins block text-3xl mt-2 font-semibold"
                  >
                    2500+
                  </span>
                  <span
                    style={{ color: "#5B5A5A" }}
                    className="font-poppins font-semibold text-sm"
                  >
                    Products Manufactured
                  </span>
                </div>
                <div className="text-center flex flex-col items-center justify-center install-padding">
                  <img
                    src={rep}
                    alt="calender"
                    className="w-14 center opacity-50"
                  />
                  <span
                    style={{ color: "#262476" }}
                    className="font-poppins block text-3xl mt-2 font-semibold"
                  >
                    1000+
                  </span>
                  <span
                    style={{ color: "#5B5A5A" }}
                    className="font-poppins font-semibold text-sm"
                  >
                    Repeat Orders
                  </span>
                </div>
                <div className="text-center flex flex-col items-center justify-center install-padding">
                  <img src={cup} alt="calender" className="achiveImage" />
                  <span
                    style={{ color: "#262476" }}
                    className="font-poppins block text-3xl mt-2 font-semibold"
                  >
                    50+
                  </span>
                  <span
                    style={{ color: "#5B5A5A" }}
                    className="font-poppins font-semibold text-sm"
                  >
                    Awards Received
                  </span>
                </div>
                <div className="text-center flex flex-col items-center justify-center">
                  <img src={install} alt="calender" className="w-10 center" />
                  <span
                    style={{ color: "#262476" }}
                    className="font-poppins mt-2 block text-3xl font-semibold"
                  >
                    2000+
                  </span>
                  <span
                    style={{ color: "#5B5A5A" }}
                    className="font-poppins font-semibold text-sm"
                  >
                    Installations Done
                  </span>
                </div>
              </Row>
            </Col>
            <Col md={12}>
                <ClientCar/>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Clients;
