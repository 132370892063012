import React from 'react'
function ProductHeadDescription({ heading, description }) {
    return (
        <>
            <div className="mb-5">
                <span className="block font-poppins font-bold text-xl" style={{ color: 'var(--darkBlue)' }}>{heading}</span>
                <p className=" font-cario font-normal text-gray-500">
                    {description}
                </p>
            </div>
        </>
    )
}

export default ProductHeadDescription
