import React from 'react'
import Modal from 'react-bootstrap/Modal'
import "./homeAbout.css"

function IFrameModal2(props) {

    return (
        <>
            <Modal
                {...props}
                dialogClassName="video-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="iframe-content">
                    <iframe 
                        src="https://www.youtube.com/embed/bP9cqtL_0EA" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default IFrameModal2
