import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/sterility-testing-unit/01.jpg";
import image2 from "../../../../assets/images/product/sterility-testing-unit/02.jpg";
import image3 from "../../../../assets/images/product/sterility-testing-unit/03.jpg";
import image4 from "../../../../assets/images/product/sterility-testing-unit/04.jpg";
import image5 from "../../../../assets/images/product/sterility-testing-unit/05.jpg";

function Sterilitytestingunit() {
    return (
        <>
           <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Sterility Testing Unit"} description={"Our range of products include sterility test unit (single place), sterility testing unit, sterility testing equipment, sugar dissolving tank and sterility test unit."}/>
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Sterility Test Unit (Single Place)`} link={"/products/sterility-testing-unit/sterility-test-unit-single-place"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Sterility Testing Unit`} link={"/products/sterility-testing-unit/sterility-testing-unit"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Sterility Testing Equipment`} link={"/products/sterility-testing-unit/sterility-testing-equipment"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image4} productName={`Sugar Dissolving Tank`} link={"/products/sterility-testing-unit/sugar-dissolving-tank"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image5} productName={`Sterility Test Unit`} link={"/products/sterility-testing-unit/sterility-test-unit"}/>
                    </Col>
                </Row>
            </Container> 
        </>
    )
}

export default Sterilitytestingunit
