import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/pharmaceutical-machinery/01.jpg"


function BottleWashing() {
    return (
        <>
      <ProductDetailHead productTitle={"Linear Bottle Washing Machines"} productPrice={""} carImg={[image]}>
        <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"1 Unit"} />
        <ProductTableCol leftValue={"Capacity"} rightValue={"90 BPM to 225 BPM"} />
        <ProductTableCol leftValue={"Washing Type"} rightValue={"Linear tunnel type"} />
        <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
        <ProductTableCol leftValue={"Machine Type"} rightValue={"Automatic"} />
        <ProductTableCol leftValue={"Air Pressure (Bar)"} rightValue={"6 bar for lubricated air and 2.5 bar for non lubricated aie"} />
        <ProductTableCol leftValue={"Cycle Timing (Second)"} rightValue={"24 seconds"} />
        <ProductTableCol leftValue={"Model Number"} rightValue={"UMPL"} />
        <ProductTableCol leftValue={"Water Consumption (Litre)"} rightValue={"Depend upon rated output of washed bottles"} />
        <ProductTableCol leftValue={"Weight (kilogram)"} rightValue={"2.5 ton"} />
        <ProductTableCol leftValue={"Size"} rightValue={"15 ml to 500 ml with set of change parts"} />
        <ProductTableCol leftValue={"Material"} rightValue={"SS 304 /SS 316"} />
        <ProductTableCol leftValue={"Power Consumption"} rightValue={"3.7 Kw without heaters"} />
        <ProductTableCol leftValue={"Condition"} rightValue={"New"} />
        <ProductTableCol leftValue={"Output"} rightValue={"From 90 bottles/minute to 225 bottles/minute"} />
        <ProductTableCol leftValue={"Finish"} rightValue={"Matt"} />
      </ProductDetailHead>
      <ProductDescription productDescription={"We are offering an extensive assortment of Linear Bottle Washing Machines, as we are manufacturer and supplier of the same. Our offered machines are manufactured using premium quality raw material and latest techniques in compliance with the international standards. The raw material used for the manufacturing of these products is checked on various parameters in order to ensure their flawlessness. These machines have remarkable features like corrosion and weather resistance, robust construction, dimensional accuracy, and durability. Clients can avail these machines from us in standard as well as customized options as per their varied specifications"} />
      <ProductFeatures productFeatures={["Durability", "Easy installation", "Fine finish"]} />
      <OtherDetails title={"Other Details:"} />
      <Lists>
        <ListValue value={`Unipack Machines Linear Bottle Washing Machine have developed updated version design which ensures cleaning of round shaped gloss and pet bottles efficiently with air and vacuum facility. This is the new feature added in our existing machine.`} />
        <ListValue value={`New design has added features which result in to ease of operation and user’s friendly.`} />
        <ListValue value={`Linear bottle machines giving output from 90 bottles/minute to 225 bottles/minute with eight wash cycles are available.`} />
        <ListValue value={`Machine design has the flexibility to change the wash cycle to meet the specific requirement of customer`} />
        <ListValue value={`Utility zone and washing zone is separated by a partition to avoid contamination of wash liquid at the time of wash cycle`} />
        <ListValue value={`Recycling of liquid is possible during wash cycle resulting effective utilization of desired liquid to wash the bottle`} />
        <ListValue value={`All operations are automatic once the desired parameters are fed to the machine`} />
        <ListValue value={`In manual mode, each function can be checked before machine is put into the operation of auto mode`} />
        <ListValue value={`Flexibility in set value of each parameter helps to suit specific requirement of particular size of bottle without changing program in PLC`} />
        <ListValue value={`All machines operations are simple and due to flexibility in adopting different size of bottles with bare minimum time for changeover from one`} />
        <ListValue value={`Periodical maintenance schedule given in the technical manual gives trouble free performance throughout the life of machine.`} />
        <ListValue value={`Safety chucks and interlocking are provided to stop the machine and give fault signal on MMI which prevents machine from abnormal load conditions`} />
      </Lists>
      <OtherDetails title={"List of our esteemed customers."} />
      <Lists>
        <ListValue value={`J.B.Chemicals Ankleshwar`} />
        <ListValue value={`Kopan - Khopoli`} />
        <ListValue value={`J.B.Chemicals - Panoli`} />
        <ListValue value={`Unique Pharma -Ankleshwar`} />
        <ListValue value={`Rhone Poulenc - Mumbai`} />
        <ListValue value={`Plethico Pharmaceuticals - Indore`} />
        <ListValue value={`Alkem Lab. - Daman`} />
        <ListValue value={`IPCA Laboratories - Kandla`} />
        <ListValue value={`Centaur Pharma - Goa.`} />
        <ListValue value={`Cipla - Satara`} />
        <ListValue value={`Mediorals - Satara`} />
        <ListValue value={`Hindustan Biological Ltd. - Mumbai`} />
        <ListValue value={`Medley Pharmaceuticals - Daman`} />
        <ListValue value={`Makjai Laboratories Pvt. Ltd. - Kolhapur`} />
        <ListValue value={`Gopaldas Vishram - Mumbai`} />
        <ListValue value={`Vapicare Pharmaceuticals - Vapi`} />
        <ListValue value={`FDC limited - Aurangabad`} />
        <ListValue value={`Hindustan labs - Palghar`} />
        <ListValue value={`Nirma Ltd. - Ahemdabad`} />
      </Lists>
      <OtherDetails title={"Additional Information:"} />
      <Lists>
        <ListValue headHighlight headValue={`Pay Mode Terms`} value={`T/T (Bank Transfer),Other`} />
      </Lists>
    </>
    )
}

export default BottleWashing
