import React,{useState,useEffect} from 'react'
import {Row,Col} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import API_REQUEST from "../../config/client"
import "./enquiry.css"

function EnquiryForm(props) {

    const [detail,getDetails] = useState(null);
    const [successful, setSuccessful] = useState(false);
    const [Message,getMessage] = useState("");
    const [values, setValues] = useState({ name:'' , company:'' , designation:'' , 
                                    location:'' , mobile:'', email:'', details:'' });

    const submitDetails = async (e) => {
        e.preventDefault();
        setSuccessful(false);
        if (values.name !== '' && values.company !== '' && values.designation !== '' && values.location !== '' && values.mobile !== '' && values.email !== '' && values.details !== '') {
            var request = {
                action: 'fetchDetails',
                Name: values.name,
                Company: values.company,
                Designation: values.designation,
                Location: values.location,
                Mobile: values.mobile,
                Email: values.email,
                Details: values.details
            }
            await API_REQUEST('enquiry', request).then((response) => getDetails(response));
            setSuccessful(true);
        }else{
            getMessage("Please fill all the mandatory fields");
        }
    };

    useEffect(() => {
        if (detail) {
            getMessage(detail.Data);
        }
    },[detail]);

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className=" text-indigo-900 text-center w-full mx-auto my-0">
                        Enquiry Form
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-1 px-4">
                    <div className="formContainer">
                    <Form>
                        <Row className="mb-1">
                            <Col>
                                <p className="text-right mb-0" style={{fontSize:"10px"}}><span className="text-red-700">*</span> -mandatory fields</p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control placeholder="Full Name*" name="name"
                                    onChange={e => setValues({...values,name: e.target.value})} 
                                    value={values.name}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control placeholder="Company Name*" name="company"
                                    onChange={e => setValues({...values,company: e.target.value})} 
                                    value={values.company}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control placeholder="Designation*" name="designation"
                                    onChange={e => setValues({...values,designation: e.target.value})} 
                                    value={values.designation}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control placeholder="Location*" name="location"
                                    onChange={e => setValues({...values,location: e.target.value})} 
                                    value={values.location}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control placeholder="Mobile Number*" name="mobile"
                                    onChange={e => setValues({...values,mobile: e.target.value})} 
                                    value={values.mobile}
                                />
                            </Col>
                            <Col>
                                <Form.Control placeholder="Email*" name="email"
                                    onChange={e => setValues({...values,email: e.target.value})} 
                                    value={values.email}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control as="textarea" placeholder="Enquiry Details*" name="details"
                                    onChange={e => setValues({...values,details: e.target.value })} 
                                    value={values.details}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3 text-center">
                            <Col>
                                <Button onClick={submitDetails} style={{backgroundColor:"#262476"}}>Submit</Button>
                            </Col>
                        </Row>
                        {Message && (
                            <Row className="mb-3 mx-5 text-center">
                                <Col className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                                    {Message}
                                </Col>
                            </Row>
                        )}
                    </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EnquiryForm
