import React from 'react'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/unipack-membrane-filter-holder/03.jpg"
import image2 from "../../../../assets/images/product/unipack-membrane-filter-holder/04.jpg"

function Unipack90mm() {
    return (
        <>
            <ProductDetailHead productTitle={"90mm Membrane Filter Holder"} productPrice={""} carImg={[image1,image2]}>
                <ProductTableCol leftValue={"Usage/Application"} rightValue={"For Sterile Filtration of Pharmaceuticals and Biological solutions."} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
                <ProductTableCol leftValue={"Diameter"} rightValue={"90 mm"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316 / SS 316L"} />
                <ProductTableCol leftValue={"Design Type"} rightValue={"Filter Holders are decorously designed to support the membranes against the applied pressure differ."} />
            </ProductDetailHead>
            <OtherDetails title={"Features"} />
            <ProductDescription productDescription={"Bubble Test Arrangement -'Bubble Point Test' is a non-destructive test to check the intergrity of the membrane filter, before and after the filtration. The bubble point is the pressure required to force air through the pores of a wet filter. If a membrane cannot with stand the pressure below bubble point level, it indicates leak in the filter. A built in system for bubble testing can be provided with the membrane holders by in building a set of liquid & air control valves"} />
        </>
    )
}

export default Unipack90mm
