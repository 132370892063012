import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/unipack-tape-slicing-machine/01.jpg";
import image2 from "../../../../assets/images/product/packaging-machinery/02.png";
import image3 from "../../../../assets/images/product/packaging-machinery/03.jpg";
import image4 from "../../../../assets/images/product/packaging-machinery/04.jpg";
import image5 from "../../../../assets/images/product/packaging-machinery/05.jpg";

function Packagingmachinery() {
    return (
        <>
             <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Packaging Machinery"} description={"Leading Manufacturer of automatic adhesive tape slicing machine, syrup blending machine, milk storage tanks, depyrogenation tunnel and automatic two head vial filling & rubber stoppering machine from Thane."}/>
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Automatic Adhesive Tape Slicing Machine`} link={"/products/tape-slicing-machine"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Syrup Blending Machine`} link={"/products/syrup-blending-machine/info-on-request"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Milk Storage Tanks`} link={"/products/milk-storage-tank/info-on-request"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image4} productName={`Depyrogenation Tunnel`} link={"/products/depyrogenation-tunnel/info-on-request"}/>
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image5} productName={`Automatic Two Head Vial Filling & Rubber Stoppering Machine`} link={"/products/two-head-vial-filling/info-on-request"}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Packagingmachinery
