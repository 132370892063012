import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/04.jpg"

function Bta90mm() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter Holder (90MM) - BTA"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Size"} rightValue={"293,142,90 mm"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 142 mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"With our immense domain expertise, we are involved in offering Membrane Filter Holder. The offered filter holders are manufactured in a proficient manner with supreme quality raw material and highly advanced technology in sync with the latest market standards. These filter holders are precisely inspected on each part so as to deliver perfect range to our customers. Also, these filter holders are available with various specifications at market leading rates"} />
            <ProductFeatures productFeatures={["Rigid design", "Require less maintenance", "Smooth performance"]} />
            <OtherDetails title={"Construction:"} />
            <Lists>
                <ListValue value={`Rigid construction with internationally accepted GMP standard.Unipack Filter Holders are decorously designed to`} />
                <ListValue value={`support the membranes against the applied pressure differential.`} />
            </Lists>
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Bubble Test Arrangement ‘Bubble Point Test’ is a non-destructive test to check the integrity of the`} />
                <ListValue value={`membrane filter, before and after the filtration. The bubble point is the pressure required to force air through the pores of a wet filter. If amembrane cannot withstand the pressure below bubble point level, it indicates the leak in the filter`} />
                <ListValue value={`A built-in a system for bubble testing can be provided with the membrane holders by in building a set of liquid air control valves`} />
            </Lists>
        </>
    )
}

export default Bta90mm
