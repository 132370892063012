import axios from 'axios'

function API_REQUEST(param, request_data) {
    const headers = {'Content-Type': 'application/json',};
    // var BASE_URL = 'http://localhost/unipack/okaypaper/api/';
    var BASE_URL = "https://beakservers.com/unipackmachines/enquiry.php";

    // var fetchData = axios.post(BASE_URL+param+'.php', {request_data},{headers}).then((response) => response.data);
    var fetchData = axios.post(BASE_URL, {request_data},{headers}).then((response) => response.data);

    return fetchData;
    
}

export default API_REQUEST;