import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav'
import { Tab } from 'react-bootstrap';
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import historybg from "../../assets/images/about/historybg.jpg";
import "./team.css"
import Team01 from './Team01';
import Team02 from './Team02';
import Team03 from './Team03';

function Team() {
    return (
        <>
            <Innerbanner
                banner={historybg}
                heading={`TEAM`}
                // title={`A Group you can trust, dating back to 1989.`}
            />
            <Container className="cusContainer my-8">
                <Row>
                    <Col md={12}>
                        <div className="text-center">
                            <h3
                                style={{ borderColor: "var(--gray)" }}
                                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                            >
                                TEAM
                            </h3>
                        </div>
                    </Col>
                    <Col md={12} className="margin-t48">
                        <div className="text-center">
                            <p className="font-cario font-medium text-base md:text-lg" style={{ color: "var(--gray)" }}>
                                We, Unipack Machines Private Limited, established our operations
                                in the year 1989, as one of the trustworthy manufacturers,
                                suppliers, distributors and exporters of a massive compilation
                                of Machinery and Equipment.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="teamContainer">
                <Tab.Container defaultActiveKey="#menu1">
                    <Nav variant="pills" className="team-list">
                        <Nav.Link eventKey="#menu1" className="font-poppins team-navigation">Managing Director 1</Nav.Link>
                        <Nav.Link eventKey="#menu2" className="font-poppins team-navigation">Managing Director 2</Nav.Link>
                        <Nav.Link eventKey="#menu3" className="font-poppins team-navigation">Marketing Director</Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="#menu1">
                            <Team01/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#menu2">
                            <Team02/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#menu3">
                            <Team03/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
}

export default Team
