import React from 'react'

function ApplyNow() {
    return (
        <>
            <button className="capitalize font-cario font-bold text-white" style={{backgroundColor:"var(--darkBlue)", padding: "15px 35px"}}>
                apply now
            </button>
        </>
    )
}

export default ApplyNow
