import React from 'react'
import { Row, Col } from "react-bootstrap";

function ProductTableHead3({ head1 , head2 , head3 }) {
    return (
        <>
            <Row className="mx-0 py-1 border-b border-solid border-gray-300 productList">
                <Col xs={6} sm={6} md={4} className="px-0">
                    <div
                        className="font-cario font-bold text-base"
                        style={{ color: "var(--gray)" }}
                    >
                        {head1}
                    </div>
                </Col>
                <Col xs={3} sm={3} md={4}>
                    <div
                        className="font-cario font-bold text-base"
                        style={{ color: "var(--gray)" }}
                    >
                        {head2}
                    </div>
                </Col>
                <Col xs={3} sm={3} md={4}>
                    <div
                        className="font-cario font-bold text-base"
                        style={{ color: "var(--gray)" }}
                    >
                        {head3}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ProductTableHead3
