import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/filter-press/07.jpg"

function SparklerFilterPress() {
    return (
        <>
            <ProductDetailHead productTitle={"Sparkler Filter Pres"} productPrice={"Approx. Rs 2 Lakh / Onwards"} carImg={[image]}>
            <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
            <ProductTableCol leftValue={"Filtration Capacity"} rightValue={"For 600 to 15500 liters"} />
                <ProductTableCol leftValue={"Design"} rightValue={"CGMP standard"} />
                <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror finish"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Material"} rightValue={"AISI S.S. 304 / AISI SS 316 / AISI SS 316L"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
            </ProductDetailHead>
            <ProductDescription productDescription={`"UNIPACK" Horizontal Filter Press - Sparkler Type Consists of a Filter cartridge assembly with SS Filter plates, Support screens, Interlocking cups & SS Tank with Pressure tight fitting system.`} />
            <OtherDetails title={"Salient Features:"} />
            <Lists>
                <ListValue value={`All Contact Parts are in AISI SS 304 / AISI SS 316 / AISI SS 316L.`}/>
                <ListValue value={`Components are Argon Arc welded & polished to Mirror finish.`}/>
                <ListValue value={`SS Jacket can be provided for hot filtration. (Optional).`}/>
                <ListValue value={`Provides crystal clear filtration by filtering particles upto 5 Micron Size.`}/>
                <ListValue value={`No Scavenger arrangement is required.`}/>
                <ListValue value={`No liquid between the Tank & the Cartridge.`}/>
                <ListValue value={`Top dome structure ensures fast & easy removal of cartridge assembly & cleaning of cake. Uniform filter aids & cake distribution.`}/>
                <ListValue value={`SS Pump mounted on SS Trolley with "UNIPACK" make heavy duty thrust ball bearing castor wheels for easy movement.`}/>
                <ListValue value={`Provided with different types of pumps depending on the viscosity of liquid & output i.e. Centrifugal Pump, Gear Pump, Lobe Pump, Roto Pump etc.`}/>
                <ListValue value={`Provided with Safety valve, air vent valve, pressure gauge and sampling valve. No contamination of internal surface of the shell.`}/>
            </Lists>
            <OtherDetails title={"Special Features:"} />
            <Lists>
                <ListValue value={`Horizontal Plate Zero Hold-Up Filter Press is constructed in such a manner that it prevents evaporation, oxidation, leakage & fumes escaping from product.`}/>
                <ListValue value={`Plates are available in two sizes- Deep Plate for higher percentage of caking holding capacity & Shallow Plate for lesser percentage of cake holding capacity.`}/>
                <ListValue value={`Filter plate & thickness of cake prevents the cake dropping as well as cracking, thus assuring better filtrate quality.`}/>
                <ListValue value={`According to requirement of filtration area & cake holding capacity, rated output of filtrate liquid can be increased or decreased (Refer table for diff. models VS diff. capacities).`}/>
            </Lists>
        </>
    )
}

export default SparklerFilterPress
