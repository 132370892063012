import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/sterility-testing-unit/02.jpg"


function SterilityTestingUnitProduct() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterility Testing Unit"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 47 mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316, 304"} />
                <ProductTableCol leftValue={"Finish"} rightValue={"Mirror polished to 220 grit"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are a noteworthy organization in the domain, engaged in providing a high-quality range of Sterility Testing Unit. The offered sterility testing units offered by us are developed by utilizing top grade material and advance technology in accordance with latest industrial standards. Along with that, these sterility testing units are widely known in the market for its durability and quality. Customers can take the complete range of sterility testing units at nominal rates"} />
            <ProductFeatures productFeatures={["Easy installation", "Low maintenance", "High performance"]} />
            <OtherDetails title={"Other Details :"} />
            <Lists>
                <ListValue value={`Six places three place test units are available to simultaneously filter five or two test samples,`} />
                <ListValue value={`respectively with a concurrent control filtration of sterile diluting fluid for an immediate overall`} />
                <ListValue value={`verification of system sterility. The entire unit can be autoclaved. Essential parts of the units are a`} />
                <ListValue value={`combined manifold for vacuum and flushing, filter holders three-way valves.`} />
                <ListValue value={`Unipack offers complete systems for aseptically filtering liquid samples`} />
                <ListValue value={`The sterility test in these systems are done by the bacteria-retentive membrane filter method`} />
                <ListValue value={`Rigid construction with internationally accepted GMP standard`} />
            </Lists>
        </>
    )
}

export default SterilityTestingUnitProduct
