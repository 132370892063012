import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from "../../ProductCard";
import image1 from "../../../../assets/images/product/filter-press/01.jpg";
import image2 from "../../../../assets/images/product/filter-press/02.jpg";
import image3 from "../../../../assets/images/product/filter-press/03.png";
import image4 from "../../../../assets/images/product/filter-press/04.jpg";
import image5 from "../../../../assets/images/product/filter-press/05.jpg";
import image6 from "../../../../assets/images/product/filter-press/07.jpg";
import image7 from "../../../../assets/images/product/filter-press/08.jpg";
import image8 from "../../../../assets/images/product/filter-press/09.png";
import image9 from "../../../../assets/images/product/filteration-equipments/03.png";
import image10 from "../../../../assets/images/product/sterility-testing-unit/2.jpg";
import image11 from "../../../../assets/images/product/inline-filter-holders/1.jpg";
import image12 from "../../../../assets/images/product/membrane-filter-holder/08.jpg";
import image13 from "../../../../assets/images/sugar1.jpeg";
import image15 from "../../../../assets/images/product/unipack-pressure-storage-vessel/01.jpg";

function FilterPress() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="mb-5">
              <span className="block font-poppins font-bold text-xl" style={{color: 'var(--darkBlue)'}}>FILTER PRESS</span>
              <p className=" font-cario font-normal text-gray-500">
                Leading Manufacturer of zero hold up filter press machine, plate
                & frame type filter press machine, plate and frame filter press,
                polypropylene filter press, plate filter press and sparkler
                filter press from Thane.
              </p>
            </div>
          </Col>
          {/* <Col md={4}>
            <ProductCard productImg={image3} productName={`Plate And Frame Filter Press`} link={"/products/filter-press/plate-and-frame-filter-press"}/>
          </Col> */}
          {/* <Col md={4}>
            <ProductCard productImg={image2} productName={`Plate & Frame Type Filter Press Machine`} link={"/products/filter-press/plate-frame-type-filter-press-machine"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image5} productName={`Plate Filter Press`} link={"/products/filter-press/plate-filter-press"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image8} productName={`Plate Frame Filter Press Machine`} link={"/products/filter-press/plate-frame-filter-press-machine"}/>
          </Col> */}
          <Col md={4}>
            <ProductCard productImg={image3} productName={`Plate And Frame Filter Press`} link={"/products/filter-press/plate-and-frame-filter-press"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image4} productName={`Polypropylene Filter Press`} link={"/products/filter-press/polypropylene-filter-press"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image6} productName={`Sparkler Filter Press`} link={"/products/filter-press/sparkler-filter-press"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image1} productName={`Zero Hold-Up Filter Press`} link={"/products/filter-press/zero-holdup-filter-press"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image10} productName={`Sterility Testing Unit`} link={"/products/filtration-equipments/sterility-testing-unit"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image11} productName={`Inline Filter Holders`} link={"/products/filtration-equipments/inline-filter-holder"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image12} productName={`Membrane Filter Holders`} link={"/products/filtration-equipments/membrane-filter-holder"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image13} productName={`Sugar Syrup Filtration`} link={"/products/filtration-equipments/sugar-syrup-filtration"}/>
          </Col>
          <Col md={4}>
            <ProductCard productImg={image15} productName={`Sterile Pressure Vessel & Storage Vessel`} link={"/products/unipack-pressure-vessel-and-storage-vessel"}/>
          </Col>
          {/* <Col md={4}>
            <ProductCard productImg={image9} productName={`Glucose Syrup Filtration`} link={"/products/filtration-equipments/glucose-syrup-filtrattion"}/>
          </Col> */}
          {/* <Col md={4}>
            <ProductCard productImg={image7} productName={`Sparkler Type Filter Press`} link={"/products/filter-press/sparkler-type-filter-press"}/>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default FilterPress;
