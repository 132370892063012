import React , {useState} from 'react'
import "./enquiry.css";
import EnquiryForm from "./EnquiryForm";

function Enquiry() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <button onClick={() => setModalShow(true)} >
      <div className="hidden md:block fixed top-96 right-0 z-50 py-4 px-2 cursor-pointer hover:bg-blue-400 bg-indigo-900"
      // style={{backgroundColor: "#7FC4FD"}}
      >
        <span className="enq text-white font-cario font-bold text-base">ENQUIRE NOW</span>
      </div>
      </button>
      <EnquiryForm show={modalShow} onHide={() => setModalShow(false)}/>
    </>
  );
}

export default Enquiry;