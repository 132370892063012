import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/pharmaceutical-machinery/01.jpg";
import image2 from "../../../../assets/images/product/pharmaceutical-machinery/02.jpg";
import image3 from "../../../../assets/images/product/pharmaceutical-machinery/03.jpg";
import image4 from "../../../../assets/images/product/pharmaceutical-machinery/04.png";

function Pharmaceuticalmachinery() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Pharmaceutical Machinery"} description={"Pioneers in the industry, we offer linear bottle washing machines, powder filling machine, rubber stoppering and cap sealing machine, vial filling machine and stainless steel wire mesh conveyor from India."} />
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Linear Bottle Washing Machines`} link={"/products/pharmaceutical-machinery/linear-bottle-washing-machines"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Powder Filling Machine, Rubber Stoppering and Cap Sealing Machine`} link={"/products/powder-filling-machine/info-on-request"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image3} productName={`Vial Filling Machine`} link={"/products/vial-filling-machine/info-on-request"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image4} productName={`Stainless Steel Wire Mesh Conveyor`} link={"/products/wire-mesh-conveyor/info-on-request"} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Pharmaceuticalmachinery
