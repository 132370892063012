import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/packaging-machinery/03.jpg"


function MilkStorageTanks() {
    return (
        <>
            <ProductDetailHead productTitle={"Milk Storage Tanks"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Storage Capacity"} rightValue={"500-1000 L, 5000-10000 L, 1000-5000 L, 500 L to 1 lakh L"}/>
                <ProductTableCol leftValue={"Storage Material"} rightValue={"Oils, Milk, Water"} />
                <ProductTableCol leftValue={"Tank Orientation"} rightValue={"Horizontal, Vertical"} />
                <ProductTableCol leftValue={"Steel Grade"} rightValue={"SS304"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Tank Capacity"} rightValue={"100 L"} />
                <ProductTableCol leftValue={"Cover Outlet"} rightValue={"38 mm"} />
                <ProductTableCol leftValue={"Water Inlet"} rightValue={"25 mm"} />
                <ProductTableCol leftValue={"Tangent Connection"} rightValue={"25 mm"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We have gained the expertise in manufacturing, exporting, distributing and supplying of Milk Storage Tanks. Our complete range of milk storage tank is designed with top quality of material in accordance with the market norms. The offered milk storage tank is broadly applauded for its quality long life also examined on quality parameters for ensuring its flawless range. Clientele can purchase the whole range of milk storage tank at pocket friendly rates."} />
            <ProductFeatures productFeatures={["Hassle-free performance", "Maintenance free","Easy to operate"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Sparkler Filter Press: SS 304 Construction Horizontal Plate Filter Press (Zero Hold Up – Reverse Flow Design)`} />
                <ListValue value={`Hyflow Pre-Coating Tank: Centrifugal, Designed for sanitary application of SS 304 construction, the Pump is be driven by standard TEFC 3 Phase motor`} />
                <ListValue value={`Syrup Cooler Phe: Plate Heat Exchanger, Plate Pack is made of imported SS 304 plates with food grade nitrile ,clip-on type rubber gaskets`} />
                <ListValue value={`Support frame is of MS with SS 304 cladding; Two Section PHE with Temp program syrup inlet 85 deg`} />
                <ListValue value={`Outlet- 40 deg. with cooling tower water flow rate 3 times of Product.And 40 deg C to 25 deg. C with 0 deg. C glycol having flow rate 0.5 times of product flow`} />
                <ListValue value={`Sight Glass: 02 Nos.SS 304, toughened glass, machined flanges with food grade gasket 38 mm SMS connections`} />
                <ListValue value={`Sample Cock: 01 No`} />
                <ListValue value={`SS304 Skid, Inter-Connecting Pipes And Fittings 01 Lot`} />
                <ListValue value={`SS 304 pipes and fittings as per given in flow drag. Suitable SS frame skid for mounting of above mentioned equipment (except tanks, Plate frame filter press) Skid with height adjustable ball feet`} />
            </Lists>
        </>
    )
}

export default MilkStorageTanks
