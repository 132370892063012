import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/07.jpg"

function MembraneFilter293STD() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter Holder (293MM) - STD"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Size"} rightValue={"293, 142, 99 mm sizes to hold 13 mm"} />
                <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Diameter membrane filters"} rightValue={"25 mm 47 mm 90 mm 142 mm and 293 mm"} />
                <ProductTableCol leftValue={"Material"} rightValue={"S.S 316"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Owing to our extremely talented team of professionals, we are engaged in offering excellent quality Membrane Filter Holder. Our complete range of filter holder is designed with top quality of material in accordance with the market norms. The offered filter holder is broadly applauded for its quality long life also examined on quality parameters for ensuring its flawless range. Clientele can purchase the whole range of filter holder at pocket-friendly rates."} />
            <ProductFeatures productFeatures={["Low power consumption", "Sturdy construction", "High performance"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential`} />
                <ListValue value={`These holders are available in six different`} />
                <ListValue value={`The membrane holders in combination with membrane filters are used for sterile filtration of pharmaceutical and biological solutions`} />
                <ListValue value={`The selection of the holder depends upon the volume of the liquid to be filtered in a day`} />
                <ListValue value={`The filter support screen is offered in S.S with polished surface`} />
                <ListValue value={`A flow deflector under the top plate ensures even distribution of the liquid flow`} />
                <ListValue value={`A back, pressure support screen may also be supplied as on optional accessory to protect the filter against reverse accidental flows`} />
                <ListValue value={`(Always use prefilter while using back pressure screen)These filter holders are used for sterile grade filtration of Pharmaceutical and Biological solutions`} />
                <ListValue value={`The unit is specially designed to support the membrane against the applied pressure differential`} />
                <ListValue value={`Membrane filter holders are available in different The production scale filter holders are also offered with inbuilt bubble test arrangement`} />
                <ListValue value={`On a production scale, either a 293 mm or a 142 mm dia holder is used`} />
                <ListValue value={`Both the 293 mm dia and 142 mm dia holders are made of S.S 316 and are supplied with removable legs`} />
            </Lists>
        </>
    )
}

export default MembraneFilter293STD
