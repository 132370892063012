import React from 'react'
import { Container, Row, Col } from "react-bootstrap"

function ProductDescription({productDescription}) {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div>
                            <span className="font-cario font-normal block text-justify text-gray-500 text-lg mt-4">
                                {productDescription}
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProductDescription
