import React from "react";
import "./makeindia.css";
import { Container, Row, Col } from "react-bootstrap";

import makeindia from "../../assets/images/makeindia.png";
import pepsi from "../../assets/images/pepsi.png";
function MakeIndia() {
  return (
    <>
      <div className="makeIndiaBg relative">
        <Container className="py-8 makeIndiaContainer">
          <Row className="">
            <Col md={6}>
              <div className="">
                {/* <div className=""> */}
                  <span className="weServe-text font-poppins block text-lg md:text-3xl font-semibold text-white">
                    WE SERVE TO MEET ALL YOUR MACHINERY & EQUIPMENT NEEDS
                  </span>
                {/* </div> */}
                <div className=" w-40 md:w-60 makindiaImageCon">
                  <img src={makeindia} alt="makeindia"/>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="caseStudyContainerMain">
                <span className="font-poppins block text-2xl md:text-3xl mb-2 font-semibold text-white text-center caseHead">
                  CASE STUDY
                </span>
                <div className="bg-white text-center h-32 md:h-40 overflow-hidden caseContainerMain">
                  <span
                    className="font-cario font-bold block text-base md:text-xl"
                    style={{ color: "#5B5A5A" }}
                  >
                    Read all about how we Designed, Engineered and Manufactured
                    machines for:
                  </span>
                  <Row className="mx-0 items-center caseContainer">
                    <Col xs={7} sm={7} md={6}>
                      <div className="">
                        <img src={pepsi} alt="pepsi" className="" />
                      </div>
                    </Col>
                    <Col xs={5} sm={5} md={6} style={{textAlign: "-webkit-center"}}>
                      <div className=" w-24 md:w-40">
                        <a
                          href="http://"
                          style={{ backgroundColor: "var(--darkBlue)" }}
                          className="core-detailBtn block rounded-3xl text-sm my-2 text-white no-underline font-cario font-bold uppercase hover:text-gray-400"
                        >                  
                          view details
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MakeIndia;
