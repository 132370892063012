import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"
import "./hero.css";
// Image
import b1 from "../../assets/images/b4.jpg";
import b2 from "../../assets/images/b5.jpg";
import banner1 from "../../assets/images/banner1.jpg";
import banner2 from "../../assets/images/banner2.jpg";
import banner3 from "../../assets/images/banner3.jpg";
import banner4 from "../../assets/images/banner4.jpg";
import playbtn2 from "../../assets/images/play-button-2.png";
import prev from "../../assets/images/banner-arrL.png";
import next from "../../assets/images/banner-arrR.png";
import serve from "../../assets/images/serve.png";
import Services from "./Services";
import PopupVideo from "../popupVideo/PopupVideo";
function Hero() {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const cusSel = (direction) => {
    var carouselItemCount = 3;
    if (direction === "next") {
      if (index >= carouselItemCount) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }
    if (direction === "prev") {
      if (index <= 0) {
        setIndex(carouselItemCount);
      } else {
        setIndex(index - 1);
      }
    }
    setDirection(direction)
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 600,
      behavior: "smooth"
    });
  };

  const scrollToTopMobile = () => {
    window.scrollTo({
      top: 1300,
      behavior: "smooth"
    });
  };

  const handlePopupVideo = () => {
    setModalShow(true);
  };

  return (
    <>
      <div className="hero-section">
        <div className="h-60 md:h-full heroBannerImage banner-desktop" style={{ backgroundImage: `url(${b1})` }}>
          <Container className="cusContainer">
            <Row>
              <Col md={6} className="pt-5 md:pt-24">
                <div>
                  <div className="mb-2">
                    <span className="text-white font-cario font-medium text-xl md:text-3xl block leading-none pb-3">
                      Indias most preferred<br/>Manufacturer-Exporter of
                    </span>
                  </div>
                  <div>
                    <span className="font-cario font-bold text-2xl md:text-4xl block leading-tight uppercase" style={{color:"#ffd700"}}>
                      FILTRATION EQUIPMENTS,<br/>PHARMACEUTICAL MACHINERY,<br/>PACKAGING MACHINERY,<br/>PROCESS EQUIPMENTS<br/>AND TURNKEY SERVICES
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={6} className="pt-5 md:pt-24">
                <div className="banner-right">
                  <Carousel className="bannerCar z-10 home-banner-carousel" fade>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-2xl font-bold ml-16 mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE AND FRAME FILTER PRESS</p>
                        <p className="font-cario text-lg font-semibold text-indigo-900 ml-16 mb-2">with hydraulic closing device (60cms × 60cms)</p>
                        <Link to="/products/filter-press/plate-and-frame-filter-press" 
                          className="font-cario font-bold text-indigo-900 no-underline ml-16 px-3" 
                          style={{backgroundColor:"#ffd700"}}
                          onClick={scrollToTop}>
                          VIEW DETAILS
                        </Link>
                        <button onClick={handlePopupVideo} className="play-btn2 font-cario font-bold text-indigo-900 no-underline">WATCH VIDEO
                        </button>
                        {
                          <PopupVideo 
                          show={modalShow} onHide={() => setModalShow(false)} />
                        }
                      </div>
                      <img src={banner1} alt="core-product" className="banner-img1"/>
                      
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-2xl font-bold ml-16 mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE AND FRAME FILTER PRESS</p>
                        <p className="font-cario text-lg font-semibold text-indigo-900 ml-16 mb-2">with manual closing device, pump and trolley (20cms × 20cms)</p>
                        <Link to="/products/filter-press/plate-and-frame-filter-press" 
                          className="font-cario font-bold text-indigo-900 no-underline ml-16 px-3" 
                          style={{backgroundColor:"#ffd700"}}
                          onClick={scrollToTop}>
                          VIEW DETAILS
                        </Link>
                      </div>
                      <img src={banner2} alt="core-product" className="banner-img2"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-2xl font-bold ml-16 mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE AND FRAME FILTER PRESS</p>
                        <p className="font-cario text-lg font-semibold text-indigo-900 ml-16 mb-2">without washer type, with hydraulic closing device (40cms × 40cms)</p>
                        <Link to="/products/filter-press/plate-and-frame-filter-press" 
                          className="font-cario font-bold text-indigo-900 no-underline ml-16 px-3" 
                          style={{backgroundColor:"#ffd700"}}
                          onClick={scrollToTop}>
                          VIEW DETAILS
                        </Link>
                      </div>
                      <img src={banner3} alt="core-product" className="banner-img3"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-2xl font-bold ml-16 mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE AND FRAME FILTER PRESS</p>
                        <p className="font-cario text-lg font-semibold text-indigo-900 ml-16 mb-2">with washer type and manual closing device (60cms × 60cms)</p>
                        <Link to="/products/filter-press/plate-and-frame-filter-press" 
                          className="font-cario font-bold text-indigo-900 no-underline ml-16 px-3" 
                          style={{backgroundColor:"#ffd700"}}
                          onClick={scrollToTop}>
                          VIEW DETAILS
                        </Link>
                      </div>
                      <img src={banner4} alt="core-product" className="banner-img4"/>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className=" h-96 heroBannerImage banner-mobile" style={{ backgroundImage: `url(${b2})` }}>
          <Container className="cusContainer">
            <Row>
              <Col md={12} className="pt-3 px-0">
                <div>
                  <div className="mb-0">
                    <span className="text-white font-cario font-medium text-sm text-center block leading-none mb-1">
                      Indias most preferred Manufacturer-Exporter of
                    </span>
                  </div>
                  <div>
                    <span className="font-cario font-bold text-sm text-center block leading-tight uppercase" style={{color:"#ffd700"}}>
                      FILTRATION EQUIPMENTS, PHARMACEUTICAL & PACKAGING MACHINERY & PROCESS EQUIPMENTS
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={12} className="mt-4 pt-1 px-1">
                <div className="banner-right">
                  <Carousel className="bannerCar z-10 home-banner-carousel" fade>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-sm font-bold float-right mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE & FRAME FILTER PRESS</p>
                        <p className="font-cario text-sm w-60 text-left leading-none font-semibold text-indigo-900 float-right mb-2">with hydraulic closing device (60cms × 60cms)</p>
                      </div>
                      <Link to="/products/filter-press/plate-and-frame-filter-press" className="no-underline" onClick={scrollToTopMobile}>
                        <img src={banner1} alt="core-product" className="banner-img1"/>
                      </Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-sm font-bold float-right mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE & FRAME FILTER PRESS</p>
                        <p className="font-cario text-sm w-60 text-left leading-none font-semibold text-indigo-900 float-right mb-2">with manual closing device, pump and trolley (20cms × 20cms)</p>
                      </div>
                      <Link to="/products/filter-press/plate-and-frame-filter-press" className="no-underline" onClick={scrollToTopMobile}>
                        <img src={banner2} alt="core-product" className="banner-img2"/>
                      </Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-sm font-bold float-right mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE & FRAME FILTER PRESS</p>
                        <p className="font-cario text-sm w-60 text-left leading-none font-semibold text-indigo-900 float-right mb-2">without washer type, with hydraulic closing device (40cms × 40cms)</p>
                      </div>
                      <Link to="/products/filter-press/plate-and-frame-filter-press" className="no-underline" onClick={scrollToTopMobile}>
                        <img src={banner3} alt="core-product" className="banner-img3"/>
                      </Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="banner-caption">
                        <p className="font-cario text-sm font-bold float-right mb-0" style={{color:"#ffd700"}}>VERTICAL PLATE & FRAME FILTER PRESS</p>
                        <p className="font-cario text-sm w-60 text-left leading-none font-semibold text-indigo-900 float-right mb-2">with washer type and manual closing device (60cms × 60cms)</p>
                      </div>
                      <Link to="/products/filter-press/plate-and-frame-filter-press" className="no-underline" onClick={scrollToTopMobile}>
                        <img src={banner4} alt="core-product" className="banner-img4"/>
                      </Link>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          
          

        <Container className="containerServe z-20 cusContainer">
          <Row>
            {/* <Col md={12} className="carArrCus">
              <div className="flex justify-center mr-4">
                <img src={prev} alt="" className="w-8 mr-4 cursor-pointer" onClick={() => cusSel("prev")}/>
                <img src={next} alt="" className="w-8 ml-4 cursor-pointer" onClick={() => cusSel("next")}/>
              </div>
            </Col> */}
            <Col xs={3} sm={3} md={2} className="pr-0">
              {/* <div style={{backgroundColor:"#F9BF00"}} className="weServe relative text-white font-cario font-bold flex items-center justify-center h-full w-3/4">
                We Serve
              </div> */}
              <div className=" h-12 md:h-full">
                <img src={serve} alt="serve" className="h-full" />
              </div>
            </Col>
            <Col xs={9} sm={9} md={10} className="pl-0">
              <div style={{ backgroundColor: "#F9BF00" }}>
                <Services />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Hero;
