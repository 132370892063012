import React from 'react'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import image1 from "../../../../assets/images/product/unipack-filter-press/01.jpg"
import image2 from "../../../../assets/images/product/unipack-filter-press/02.jpg"

function UnipackHydraulic() {
    return (
        <>
            <ProductDetailHead productTitle={"Unipack Plate and Frame Type Filter Press with Hydraulic Closing System"} productPrice={"Approx. Rs 10 Lakh / Onwards"} carImg={[image1,image2]}>
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Usage/Application"} rightValue={"Filtration of pharmaceuticals, chemicals, beverages, breweries, Distilleries & food industries."} />
                <ProductTableCol leftValue={"Design"} rightValue={"Universally accepted vertical plate & frame type."} />
                <ProductTableCol leftValue={"Filter Area"} rightValue={"0.66 M2 (per plate) - for 13 mm size"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"5000 to 50000 liters/hr"} />
                <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror finish with electro-polished plates, frames & screen."} />
                <ProductTableCol leftValue={"Size"} rightValue={"60 x 60 cm"} />
                <ProductTableCol leftValue={"Cake Holding Capacity"} rightValue={"13.2 Liters (Per Frame) - for 40 mm size"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 304/ SS 316"} />
            </ProductDetailHead>
            <ProductFeatures productFeatures={["Microbiological reliability ", "Universal application ", "Double filtration in one system","Individual adaption of outputs. "]} />
            <OtherDetails title={"Construction :"} />
            <ProductDescription productDescription={"Vertical square body having vertical plates & frames fitted one behind the other making parallel flow passage."} />
        </>
    )
}

export default UnipackHydraulic
