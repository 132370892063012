import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/packaging-machinery/01.jpg"


function AutomaticTapeSlicing() {
    return (
        <>
            <ProductDetailHead productTitle={"Automatic Adhesive Tape Slicing Machine"} productPrice={"Approx. Rs 10 Lakh / Piece"} carImg={[image]}>
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Effective width"} rightValue={"1.5 meter"} />
                <ProductTableCol leftValue={"Max. Roll dia. to slice"} rightValue={"300mm and minimum slice width-3 mm"} />
                <ProductTableCol leftValue={"Model"} rightValue={"UMP-SM-1.5"} />
                <ProductTableCol leftValue={"Paper core dia"} rightValue={"76 mm optionally and 38 mm shall be provided on request"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Our new developed automatic adhesive tape slicing machine is user friendly and new added features in the machine gives better yield of product sliced from log roll and ultimately it results in to improved productivity. Machine is provided with imported servo drive and touch screen HMI gives consistent performance of machine."} />
            <OtherDetails title={"Characteristics -"} />
            <Lists>
                <ListValue value={`Imported servo drive for circular blade cutter feeding with interlocking and safeties.`} />
                <ListValue value={`Main pitch shaft drive motor which imported and gives precision and consistent slice width of product.`} />
                <ListValue value={`Main drive motor 5 HP variable frequency drive to control drive of log roll to infinite speed control.`} />
                <ListValue value={`Circular cutter drive motor 3 HP variable frequency drive to control drive of slicing Cutter to infinite speed control.`} />
                <ListValue value={`Imported PLC with colored touch screen HMI`} />
            </Lists>
            <OtherDetails title={"Application:"} />
            <ProductDescription productDescription={"Slicing of PVC adhesive tape like foam, masking tape, double sided tissue tape, Polyester tape etc."} />
            <OtherDetails title={"Features:"} />
            <ProductDescription productDescription={"High precision cutting width, user’s friendly, servo motor for pitch shaft as well as cutter assy. Circular knife is driven by infinite speed control through variable frequency drive. Variable speed controller for machine main shaft. Circular cutter provided with dual cooling by liquid as well as moist air. Utilities requirement- Dry air at 6 bar 5 CFM Power- 7 kW 415 volts, 3 Phase, 50Hzwith ear thing. Machine is self supporting and does not call for foundation with height adjusting bolts and floor plates. Machine can adapt without extended core log rolls to get better yield of sliced product. Operation of machine is automatic through PLC and touch screen HMI. Diff. slices width facility in PLC so that without stopping machine desired width of slice can be possible."} />
        </>
    )
}

export default AutomaticTapeSlicing
