import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ApplyNow from "./ApplyNow";

import con from "../../assets/images/careers/contentimg.jpg"
function CareerContent() {
  return (
    <>
      <div className="careerbackdrop">
        <Container className="cusContainer pt-28 pb-40">
          <Row>
            <Col md={7}>
              <div>
                <span className="font-medium font-poppins block uppercase text-2xl mb-2">
                  Let's Grow Together
                </span>
                <span className="font-bold font-poppins block uppercase text-2xl mb-3">
                  Are you in need of a job? <br />
                  UNIPACK always welcomes you
                </span>
                <p className="font-cario font-medium text-base md:text-lg text-justify pr-5 mb-5" style={{color: "var(--gray)"}}>
                  Kindly drop your resumes at 
                  <a href="mailto:ump1990@gmail.com"
                    className="font-cario font-bold text-base md:text-lg text-indigo-900 no-underline"
                  > ump1990@gmail.com</a> with the subject as 'Job Opportunities at Unipack' and we shall 
                  revert soon if there are any suitable positions open at our company. You may also call 
                  us directly at the contact numbers displayed in the Contact Page.
                </p>
                {/* <ApplyNow /> */}
              </div>
            </Col>
            <Col md={5}>
              <div className="h-full">
                  <img src={con} alt="con" className="h-full" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>



      <div className="careerbackdrop-mobile">
        <Container className="cusContainer p-4">
          <Row>
            <Col md={12}>
              <div className="text-center">
                <h3 style={{ borderColor: "var(--gray)" }}
                  className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                >
                Careers
                </h3>
              </div>
            </Col>
            <Col md={5}>
              <div className="h-full mt-3">
                  <img src={con} alt="con" className="h-auto" />
              </div>
            </Col>
            <Col md={7}>
              <div>
                <span className="font-medium font-poppins block uppercase text-xl mt-3 mb-0">
                  Let's Grow Together
                </span>
                <span className="font-bold font-poppins block uppercase text-xl mb-3">
                  Are you in need of a job? <br />
                  UNIPACK always welcomes you
                </span>
                <p className="font-cario font-medium text-base md:text-lg text-justify mb-3" style={{color: "var(--gray)"}}>
                  Kindly drop your resumes at 
                  <a href="mailto:ump1990@gmail.com"
                    className="font-cario font-bold text-base md:text-lg text-indigo-900 no-underline"
                  > ump1990@gmail.com</a> with the subject as 'Job Opportunities at Unipack' and we shall 
                  revert soon if there are any suitable positions open at our company. You may also call 
                  us directly at the contact numbers displayed in the Contact Page.
                </p>
                {/* <div className="text-center">
                  <ApplyNow />
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CareerContent;
