import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/filteration-equipments/03.png"


function SterilePressureVessel() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterile Pressure Vessel"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"AISI 316"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"2 liters to 200 liters."} />
                <ProductTableCol leftValue={"Material"} rightValue={"High Alloy Stainless Steel"} />
                <ProductTableCol leftValue={"Features"} rightValue={"Verical Orientation"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror finish"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Sterile Pressure Vessel."} />
            <OtherDetails title={"Special Features:"}/>
            <Lists>
                <ListValue value={`All joints are argon welded. Gaps dead corners are reduced to an absolute minimum`} />
                <ListValue value={`The interior exterior of all vessels are fine grounds to give a mirror polish finish`} />
                <ListValue value={`Internal surface after mirror finish it is electro polished`} />
                <ListValue value={`A long tube at the center (outlet) is provided to ensure complete drawing of the fluid`} />
                <ListValue value={`An air tight lid TC connection and food grade silicon sealing ring`} />
                <ListValue value={`Each vessel is tested at 6 bar pressure`} />
                <ListValue value={`These vessels can be put in autoclave or can be steamed for sterilization`} />
                <ListValue value={`As per cGMP standard`} />
                <ListValue value={`Design: cGMP standard`} />
            </Lists>
        </>
    )
}

export default SterilePressureVessel
