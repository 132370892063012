import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import image from "../../../../assets/images/product/filteration-equipments/01.png"

function SparklerFilter() {
    return (
        <>
            <ProductDetailHead productTitle={"Sparkler Filter"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Usage/Application"} rightValue={"Chemical Industry"} />
                <ProductTableCol leftValue={"Surface Finish"} rightValue={"Polished"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS"} />
                <ProductTableCol leftValue={"Motor Power"} rightValue={"1,2,3,5 HP"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"With the assistance of skilled and dedicated professionals, we bring forth for our clients technologically upgraded Sparkler Filter. Widely demanded in various processing industries, this press is used for the filtration of various fluids that are fed in it. These are specially designed for an efficient functioning and are available in the market in a variety of sizes. We offer six months of warranty on all our products from the date of purchasing so as to win the trust of our customers."} />
            <ProductFeatures productFeatures={["Prevents Evaporation", "Excellent Filtrate Rate", "High Cake Holding Capacity","Energy Efficient"]} />
            
        </>
    )
}

export default SparklerFilter
