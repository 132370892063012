import React from 'react';
import Modal from 'react-bootstrap/Modal'
import "./popupvideo.css"

function PopupVideo(props) {


  return (
    <>
        
        <Modal
                {...props}
                dialogClassName="main-video-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="iframe-main-video">
                        <iframe 
                            src="https://www.youtube.com/embed/BbnExJzy45s" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/BbnExJzy45s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    </div>
                </Modal.Body>
            </Modal>

    </>
  )
}

export default PopupVideo