import React from 'react'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/unipack-testing-unit/01.jpg"
import image2 from "../../../../assets/images/product/unipack-testing-unit/02.jpg"

function UnipackSingle() {
    return (
        <>
      <ProductDetailHead productTitle={"Single Place Sterility Testing Unit"} productPrice={""} carImg={[image1,image2]}>
        <ProductTableCol leftValue={"Usage/Application"} rightValue={"For Sterile Filtration of Pharmaceuticals and Biological solutions."} />
        <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
        <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror polished to 220 grit"} />
        <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
        <ProductTableCol leftValue={"Diameter"} rightValue={"47 mm"} />
        <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316 / SS 316L"} />
      </ProductDetailHead>
      <OtherDetails title={"Features"} />
      <ProductDescription productDescription={"Single place test units are available to filter test samples. respectively with a concurrent control filtration  of sterile diluting fluid for an immediate overall verification of system sterility. The entire unit can be autoclaved. Essential parts of the units are a combined manifold for vacuum and flushing, filter holders & three way valves."} />
      <OtherDetails title={"Design"} />
      <ProductDescription productDescription={"Unipack offers complete systems for aseptically filtering liquid samples. The sterility test in these systems are done by the bacteria-retentive membrane filter method."} />
    </>
    )
}

export default UnipackSingle
