import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/zero-holdup-food-industry/01.png"

function FoodMachine() {
    return (
        <>
            <ProductDetailHead productTitle={"Zero Hold Up Filter Press Machine For Food Industry"} productPrice={"Approx. Rs 3 Lakh / Onwards"} carImg={[image]}>
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack Machines"} />
                <ProductTableCol leftValue={"Material"} rightValue={"High Alloy Stainless Steel"} />
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"No Of Plates"} rightValue={">50"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Capcity"} rightValue={"600 to 15000 LPH"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"With the backing of our skilled obtainment group, we offer a quality scope of Zero Hold Up Filter Press Machine for Food Industry. The offered reach is made utilizing world-class parts and development innovation as indicated by the worldwide norms. Offered machine is generally relevant in different applications. Our offered machine arrangement are accessible in distinctive particulars, according to needs of our clients. We offer this machine at pocket-accommodating costs to our customers."} />
            <ProductFeatures productFeatures={["High performance", "Corrosion heat resistance", "Easy operations"]} />
        </>
    )
}

export default FoodMachine
