import React from "react";
import { Row, Col } from "react-bootstrap";

function ProductTableCol({ leftValue, rightValue }) {
  return (
    <>
      <Row className="mx-0 py-1 border-b border-solid border-gray-300 productList">
        <Col xs={5} sm={5} md={4} className="px-0">
          <div
            className="font-cario font-normal text-base"
            style={{ color: "var(--gray)" }}
          >
            {leftValue}
          </div>
        </Col>
        <Col xs={7} sm={7} md={8}>
          <div
            className="font-cario font-normal text-base"
            style={{ color: "var(--gray)" }}
          >
            {rightValue}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ProductTableCol;
