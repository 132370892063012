import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/03.jpg"

function Bta142mm() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter Holder (142MM) - BTA"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 293 mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"With our rich industry experience, we manufacture, export, distribute and supply an extensive range of Membrane Filter Holder Bta with Diaphragm Valve. At our developing unit, we design this filter holder as per the international standards by making use of best quality basic material and latest technology. The offered filter holder is perfectly examined in terms of quality for ensuring their flawlessness. Along with that, customers can avail the complete assortment of filter holder at the nominal price."} />
            <ProductFeatures productFeatures={["Low noise operation","High output results","Consume less power","Finish: Mirror finish"]}/>
            <OtherDetails title={"Specifications:"} />
            <Lists>
                <ListValue value={`Construction: Rigid construction with internationally accepted Gmp standard`} />
                <ListValue value={`Finish: Mirror polished to 220 grit`} />
                <ListValue value={`Design: Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential.`} />
            </Lists>
        </>
    )
}

export default Bta142mm
