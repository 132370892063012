import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/pharmaceutical-machinery/04.png"

function WireMesh() {
    return (
        <>
        <ProductDetailHead productTitle={"Stainless Steel Wire Mesh Conveyor"} productPrice={""} carImg={[image]}>
          <ProductTableCol leftValue={"Usage/Application"} rightValue={"Used in industrial conveyors for material handling applications"} />
          <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
          <ProductTableCol leftValue={"Material"} rightValue={"SS"} />
        </ProductDetailHead>
        <ProductDescription productDescription={"With rich industry experience, we are manufacturing and supplying a wide assortment of Stainless Steel Wire Mesh Conveyor. Manufactured using optimum quality raw material and sophisticated technology, our offered range is well known for corrosion resistance, high accuracy, durable finish, robust construction and other features. Considered ideal for various applications, the offered conveyor is in compliance with the international quality standards. We provide this product in several sizes and finish to meet clients’ varied needs."} />
        <ProductFeatures productFeatures={["Rigid construction", "Sturdiness", "Reliability"]} />
      </>
    )
}

export default WireMesh
