import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import prod1 from "../../../../assets/images/product/sterility-testing-unit/1.jpg"
import prod2 from "../../../../assets/images/product/sterility-testing-unit/2.jpg"
import prod3 from "../../../../assets/images/product/sterility-testing-unit/3.jpg"

function SterilityTestNew() {
  return (
    <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <span className="block font-poppins font-bold text-xl text-center mb-5" style={{color: 'var(--darkBlue)'}}>STERILITY TESTING UNIT</span>
                </Col>
                <Col md={2}>
                    <div className="text-center mb-5">
                        <img src={prod1} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold my-2">
                            Single Place Unit
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod2} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold my-2">
                            3 Place Unit
                        </p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="text-center mb-5">
                        <img src={prod3} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold my-2">
                            6 Place Unit
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="">
                        <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of Pharmaceuticals and Biological solutions."} />
                        <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
                        <ProductTableCol leftValue={"Capacity"} rightValue={"For 47mm diameter membrane filters"} />
                        <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316 / SS 316L"} />
                        <ProductTableCol leftValue={"Finish"} rightValue={"Mirror polished to 220 grit"} />
                        <ProductTableCol leftValue={`Design`} rightValue={`Unipack offers complete systems for aseptically filtering liquid samples. The sterility test in these systems are done by the bacteria-retentive membrane filter method.`} />
                    </div>
                </Col>
                <OtherDetails title={"Special Features"} />
                <ProductDescription productDescription={"Single place test units are available to filter test samples, respectively with a concurrent control filtration of sterile diluting fluid for an immediate overall verification of system sterility. The entire unit can be autoclaved. Essential parts of the units are for vacuum and flushing, filter holders & clamps."} />
                <ProductDescription productDescription={"Six place & three place test units are available to simultaneously filter five or two test samples, respectively with a concurrent control filtration of sterile diluting fluid for an immediate overall verification of system sterility. The entire unit can be autoclaved. Essential parts of the units are a combined manifold for vacuum and flushing, filter holders & three way valves."} />
            </Row>
        </Container>
    </>
  );
}

export default SterilityTestNew;
