import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/08.jpg"

function MembraneFilter() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter"} productPrice={"Approx. Rs 25,000 / onwards"} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"100 onwards"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 293 mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Length"} rightValue={"0-5 inch,5-10 inch,10-15 inch"} />
                <ProductTableCol leftValue={"Diameter"} rightValue={"0-1 inch,1-2 inch,2-3 inch"} />
                <ProductTableCol leftValue={"Pore Size"} rightValue={"5-10 micron,10-20 micron"} />
                <ProductTableCol leftValue={"Usage"} rightValue={"Water Filter,Oil Filter"} />
                <ProductTableCol leftValue={"Body Material"} rightValue={"S.S. 316 / S.S. 316L"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Finish"} rightValue={"Mirror polished to 220 grit"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Membrane Filter."} />
            <OtherDetails title={"Special Features:"} />
            <Lists>
                <ListValue value={`Bubble Test Arrangement`} />
                <ListValue value={`Bubble Point Test’ is a non-destructive test to check the integrity of the membrane filter, before and after the filtration`} />
                <ListValue value={`The bubble point is the pressure required to force air through the pores of a wet filter`} />
                <ListValue value={`If a membrane cannot withstand the pressure below bubble point level, it indicates leak in the filter`} />
                <ListValue value={`A built-in system for bubble testing can be provided with the membrane holders by in building a set of liquid air control valves.`} />
            </Lists>
            <OtherDetails title={"Specifications:"} />
            <Lists>
            <ListValue value={`Construction: Rigid construction with internationally accepted GMP standard`} />
            <ListValue value={`Design: Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential`} />
            </Lists>
            <OtherDetails title={"Additional Information:"} />
            <Lists>
                <ListValue headHighlight headValue={"Item Code"} value={`293mmBta`} />
            </Lists>
        </>
    )
}

export default MembraneFilter
