import Slider from "react-slick";
import "./testimony.css";

import indicator from "../../assets/images/indicators.svg";
import client1 from "../../assets/images/repute.jpg";
import client2 from "../../assets/images/unicorn.png";
import client3 from "../../assets/images/hccb.jpg";

function TestimonyCar() {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`cusArrTest cusArr ${className}`}
        style={{ ...style, display: "block" }}
      >
        <img src={indicator} alt="ind" className={`indClass`} />
      </div>
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`cusArrNext cusArr ${className}`}
        style={{ ...style, display: "block" }}
      >
        <img
          src={indicator}
          alt="ind"
          className={`indClass transform rotate-180`}
        />
      </div>
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="cusArr cusArrTest" />,
    prevArrow: <PrevArrow className="cusArr cusArrTest" />,
  };
  return (
    <>
      <div className="home-testimony">
        <Slider {...settings} className="home-testimony-slider">
          <div className="mb-3 px-1">
            <div className="flex items-center">
              <div className="w-20 md:w-28 h-20 md:h-28 rounded-full">
                <img src={client1} alt="cert" className="w-full h-full object-cover" />
              </div>
              <div className="ml-2">
                <h4 className="relative block text-xl md:text-2xl font-poppins font-semibold mb-0" style={{color:"#262476"}}>DINESH NAMBIAR</h4>
                <span className="relative font-cario font-medium text-lg">Managing Director at Repute Engineers Pvt Ltd</span>
              </div>
            </div>
            <div className="hometestimony-text">
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                M/s Unipack Machines Pvt Ltd., Mumbai is an approved vendor for Plate
                and Frame Filter press and allied equipment for all our CSD and Juice Plants in India since last
                10 years to our company M/s Repute Engineers Pvt Ltd.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                M/s Unipack Machines Pvt Ltd, Mumbai has supplied over 50 numbers of Sparkler type Filter
                Press and Plate and Frame Filter Presses of capacity ranging from 1000 to 12000 LPH for
                our various installations in India.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                We would like to add that the filtration systems supplied and installed by M/s Unipack
                Machines Pvt Ltd. Mumbai are working satisfactorily since 2011 till date.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                We are very happy with their work and certify that their filter presses are of top quality as
                per international standards.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-0 text-sm">
                They are an ISO 2000 2019 ISO and CE certified company of Repute and our esteemed supplier.
              </p>
            </div>
          </div>
          <div className="mb-3 px-1">
            <div className="flex items-center">
              <div className="w-20 md:w-28 h-20 md:h-28 rounded-full">
                <img src={client2} alt="cert" className="w-20 h-auto  mx-auto"/>
              </div>
              <div className="ml-2">
                <h4 className="relative block text-xl md:text-2xl font-poppins font-semibold mb-0" style={{color:"#262476"}}>RAJESH SANGHANI</h4>
                <span className="relative font-cario font-medium text-lg">Managing Director at Unicorn Industries Private Limited</span>
              </div>
            </div>
            <div className="hometestimony-text">
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                M/s Unipack Machines Pvt Ltd., Mumbai is an approved vendor
                for Plate and Frame Filter press and allied equipment for all our CSD and Juice Plants
                in India since last 31 years to our company Unicorn Industries Private Limited. A
                Member of KRONES Group.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                M/s Unpack Machines Pvt Itd, Mumbai has supplied over 50 numbers of Plate and
                Frame Filter Presses of capacity ranging from 5000 to 30000 LPH for our various
                installations in India.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                We would like to add that the filtration systems supplied and installed by M/s Unipack
                Machines Pvt Ltd. Mumbai are working satisfactorily till date.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-2 text-sm">
                We are very happy with their work and certify that their filter presses are of top quality
                as per international standards.
              </p>
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-0 text-sm">
                They are an ISO 2000 2019 ISO and CE certified company of Repute and our
                esteemed supplier.
              </p>
            </div>
          </div>
          <div className="mb-3 px-1">
            <div className="flex items-center">
              <div className="w-20 md:w-28 h-20 md:h-28 rounded-full">
                <img src={client3} alt="cert" className="w-full h-full object-cover" />
              </div>
              <div className="ml-2">
                <span className="relative block text-xl md:text-2xl font-poppins font-semibold mb-0" style={{color:"#262476"}}>V KISHAN</span>
                <span className="relative font-cario font-medium text-lg">Vice President(Technical) at Hindustan Coca-Cola Bevarages Pvt Ltd</span>
              </div>
            </div>
            <div className="hometestimony-text">
              <p style={{color:"#5B5A5A"}} className="relative font-cario font-medium mb-0 text-sm">
                M/s UNIPACK MACHINES PVT LTD, Mumbai
                represented by Mr Jaipal Shah and Mr Sacchidanand Shah supplying
                Plate and Frame Filter Presses to our Bottling Plants for Sugar Syrup
                Filtration systems from Year 1993 onwards of various capacities are working
                satisfactorily.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default TestimonyCar;
