const AutoPopupStyle = (theme) => ({
    triggerComponent: {
        cursor: "pointer"
    },
  
    modal:{
      overflowY: 'auto',
    },
    
    modalBox: {
        position: "absolute",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%,-50%)`,
        // maxWidth: (props) =>
        // ({
        //   "xs": "20%",
        //   "sm": "35%",
        //   "md": "50%",
        //   "lg": "70%",
        //   "xl": "85%",
        // }[props.maxWidth]),
        margin: "auto",
        padding: "10px",
        // borderRadius: "0px 0px 200px 0px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        outline: 0
    },
  
    closeButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: "90%",
        transform: "translate(180%,-50%)",
        width: "40px",
        height: "40px",
        borderRadius: "70px",
        color: "#4D4D4D",
        background: "#F1F1F1 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 8px #00000029",
        cursor: "pointer",
        opacity: 1
    },
  
    closeIcon: {
        fontSize: 25
    }
});
  
export default AutoPopupStyle;