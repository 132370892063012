import React from 'react'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/unipack-pressure-storage-vessel/01.jpg"
import image2 from "../../../../assets/images/product/unipack-pressure-storage-vessel/02.jpg"
function StorageVessel() {
    return (
        <>
            <ProductDetailHead productTitle={"Unipack Sterile Pressure Vessel and Storage Vessel"} productPrice={""} carImg={[image1]}>
                <ProductTableCol leftValue={"Material"} rightValue={"High Alloy Stainless Steel"} />
                <ProductTableCol leftValue={"Application"} rightValue={"These vessels are used in Membrane filtration system"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"10 - 300 L"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack Machines"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316/316L"} />
                <ProductTableCol leftValue={"Design Type"} rightValue={"CGMP"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror finish"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Unipack Membrane filter holders are designed to filter relatively higher volumes of filtration. It is mainly used for the higher flow rates required products namely injectable / Ophthalmic / Vaccine / herbal / products etc. This is a cGMP model which can be supplied with sanitary fitting at inlet and outlet connections."} />
            <OtherDetails title={"Special Feature:"} />
            <ProductDescription productDescription={"All vessels are manufactured from high alloy stainless steel (AIS1316 Quality). All joints are argon welded. Gaps & dead corners are reduced to an absolute minimum. The interior & exterior of all vessels are fine grounds to give a mirror polish finish. Internal surface after mirror finish it is electro polished. A long tube at the center (outlet) is provided to ensure complete drawing of the fluid. An air tight lid TC connection and food grade silicon sealing ring. Each vessel is tested at 6 bar pressure. These vessels can be put in autoclave or can be steamed for sterilization. Available in standard model capacities."} />

        </>
    )
}

export default StorageVessel
