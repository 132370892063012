import React from 'react'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/filter-press/04.jpg"

function Polypropylene() {
    return (
        <>
            <ProductDetailHead productTitle={"Polypropylene Filter Press"} productPrice={"Approx. Rs 10 Lakh / Unit"} carImg={[image]}>
            <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Filter Type"} rightValue={"Recessed Plate type (Chamber Plate), Plate Frame type"} />
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Polypropylene, Cast Iron"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Closing Device"} rightValue={"Capstan type, Ratchet, Pinion Gear type, Hand Hydraulic type, Motorized Power Hydraulic type"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Polypropylene Filter Press."} />
            <OtherDetails title={"Technical Specifications:"} />
            <div className="px-3">
                <ProductTableCol leftValue={"Material of Construction"} rightValue={"Polypropylene, Cast Iron"} />
                <ProductTableCol leftValue={"Types"} rightValue={"Recessed Plate type (Chamber Plate), Plate Frame type"} />
                <ProductTableCol leftValue={"Delivery"} rightValue={"Open delivery through two-way spigot, Closed delivery"} />
                <ProductTableCol leftValue={"Closing Device"} rightValue={"Capstan type, Ratchet, Pinion Gear type, Hand Hydraulic type, Motorized Power Hydraulic type"} />
                <ProductTableCol leftValue={"Cake Discharge"} rightValue={"Manual plate shifting, auto cake discharge with plate vibrating, individual plate shifting with pick place type reciprocating shuttle"} />
            </div>
            <OtherDetails title={"Applications:"} />
            <div className="px-3">
                <ProductTableCol leftValue={"Dyestuff"} rightValue={"Dyes, Dyestuffs, Intermediates for Dyes, Optical whitening agents, Pigments etc"} />
                <ProductTableCol leftValue={"Chemicals"} rightValue={"Basic Chemical, Fine Chemical, Organic Inorganic Chemicals, Leather Chemicals, Agro Chemicals, Pesticides"} />
                <ProductTableCol leftValue={"Pharmaceuticals, mediates, Syrup"} rightValue={""} />
                <ProductTableCol leftValue={"Metallurgy"} rightValue={"Zinc, Copper, Nickel, Gold, Platinum, Silver Nonferrous metals"} />
                <ProductTableCol leftValue={"Ceramics"} rightValue={"Kaolin, Clay, Porcelain, Industrial Sands, Fine Earth"} />
                <ProductTableCol leftValue={"Food"} rightValue={"Fruit juice, Squash, Strach Glucose, Fats EdibleOil, Glycerin"} />
                <ProductTableCol leftValue={"Pollution"} rightValue={"Effluent Treatment, Industrial waste water, Water recycling. Sludge De watering"} />
                <ProductTableCol leftValue={"Other"} rightValue={"All applications requiring solid-liquid separations"} />
            </div>
        </>
    )
}

export default Polypropylene
