import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/inline-filter-holders/02.jpg"


function SSInline() {
    return (
        <>
            <ProductDetailHead productTitle={"SS Inline Filter Holder"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"100 Unit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS 316 / SS 316 L"} />
                <ProductTableCol leftValue={"Shape"} rightValue={"Round"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 47 mm diameter Membrane filters"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are instrumental in providing our clients a comprehensive range of Inline Filter Holder. At our developing unit, we design this inline filter holder as per the international standards by making use of best quality basic material and latest technology. The offered inline filter holder is perfectly examined in terms of quality for ensuring their flawlessness. Along with that, customers can avail the complete assortment of inline filter holder at the nominal price."} />
            <ProductFeatures productFeatures={["Easy installation", "Rust proof", "Rugged design", "Rigid construction with internationally accepted GMP standard"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Unipack Filter Holders are decorously designed to support the membranes`} />
                <ListValue value={`Against the applied pressure differential. For sterilizing small volumes of liquids, small size membrane filter holder`} />
            </Lists>
        </>
    )
}

export default SSInline
