import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/filteration-equipments/02.jpg"


function SterilizingTunnel() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterilizing Tunnel"} productPrice={"Approx. Rs 10 Lakh / Unit"} carImg={[image]}>
                <ProductTableCol leftValue={"Application"} rightValue={"Laboratory & Pharmaceuticals"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Shape"} rightValue={"Horizontal"} />
                <ProductTableCol leftValue={"Mount Type"} rightValue={"Floor Mounted Autoclave"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"Standard"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We feel proud to introduce ourselves as the leading manufacturers, exporters, distributors, and suppliers of Sterilizing Tunnel. At our developing unit, we design this sterilizing tunnel as per the international standards by making use of best quality basic material and latest technology. The offered sterilizing tunnel is perfectly examined in terms of quality for ensuring their flawlessness. Along with that, customers can avail the complete assortment of sterilizing tunnel at the nominal price."} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`The vial depyrogenation tunnel will be used for depyrogenation of the vials through conventional heating system to achieve the 3 log reduction of endotoxin`} />
                <ListValue value={`The exposure time for all the vials shall be more than 3 min at or above 300 when pass through the depyrogenation tunnel`} />
                <ListValue value={`Vials will pass from all the 4 different zone of depyrogenation tunnel`} />
                <ListValue value={`Air comes to the 4 zone of the depyrogenation tunnel will be through Hepa filters`} />
                <ListValue value={`The air from drying zone and cooling zone will be exhausted out from the area and air from depyrogenation zone will be recirculated.`} />
                <ListValue value={`After completion of depyrogenation operation, vials will directly transfer to the vial filling machine`} />
                <ListValue value={`The vial depyrogenation tunnel operation must be performed in strict conformance with current good manufacturing practice (CGMP)`} />
                <ListValue value={`Vials will come from the vial washing machine to the depyrogenation tunnel and from the depyrogenation tunnel, must be capable of interfacing with the vials washing machine and vial filling machine`} />
                <ListValue value={`The vial depyrogenation tunnel control system shall include a temperature control system`} />
                <ListValue value={`The control system must have the capability to display the set parameters and actual parameters`} />
                <ListValue value={`Overall dimension of the machine shall match with the area layout draw`} />
                <ListValue value={`The conveyor width shall match with the output requirement`} />
                <ListValue value={`Depyrogenation tunnel shall have following zone`} />
                <ListValue value={`Drying zone`} />
                <ListValue value={`Depyrogenation zone`} />
                <ListValue value={`Cooling zone`} />
                <ListValue value={`Stabilizing zone`} />
                <ListValue value={`The viewing port for Drying and cooling zone shall be air tight`} />
                <ListValue value={`The Drying zone and cooling zone shall have EU 14 type Hepa filters`} />
                <ListValue value={`Depyrogenation zone shall have you 13 type Hepa filters`} />
                <ListValue value={`The machine shall have differential pressure indicator across the Help filters for the four zones`} />
                <ListValue value={`Each zone or the depyrogenation tunnel shall have aerosol introduction port for the Hepa filter integrity testing`} />
            </Lists>
            <OtherDetails title={"Specifications:"} />
            <div className="px-3">
                <ProductTableCol leftValue={"Alarm Description"} rightValue={"Interlock"} />
                <ProductTableCol leftValue={"In adequate in feed"} rightValue={"Conveyor stop"} />
                <ProductTableCol leftValue={"Max accumulation at out feed"} rightValue={"Conveyor stop"} />
                <ProductTableCol leftValue={"Any of the blower fail"} rightValue={"Conveyor stop And heater off"} />
                <ProductTableCol leftValue={"Variation of conveyor speed"} rightValue={"Conveyor stop"} />
                <ProductTableCol leftValue={"Temperature below the set point"} rightValue={"Conveyor stop and restart after the temperature is reached."} />
                <ProductTableCol leftValue={"Temperature overshoot in Depyrogenation zone"} rightValue={"Heater off"} />
                <ProductTableCol leftValue={"Turbine motor over load"} rightValue={"Conveyor + Heater + Turbine stop"} />
                <ProductTableCol leftValue={"Differential pressure across the HEPA"} rightValue={"Conveyor stop"} />
                <ProductTableCol leftValue={"Conveyor motor overload"} rightValue={"Conveyor stop"} />
            </div>
        </>
    )
}

export default SterilizingTunnel
