import React from "react";
import Slider from "react-slick";
import "../products.css"

import indicator from "../../../assets/images/indicators.svg";

function ProductCarousel({carImg}) {
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                onClick={onClick}
                className={`cusArrTest cusArr productArr productPrev ${className}`}
                style={{ ...style, display: "block" }}
            >
                <img src={indicator} alt="ind" className={`indClass`} />
            </div>
        );
    };
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                onClick={onClick}
                className={`cusArrNext cusArr productArr productNext ${className}`}
                style={{ ...style, display: "block" }}
            >
                <img
                    src={indicator}
                    alt="ind"
                    className={`indClass transform rotate-180`}
                />
            </div>
        );
    };
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow className="cusArr productArr" />,
        prevArrow: <PrevArrow className="cusArr productArr" />,
    };
    return (
        <>
            <div className="mb-5">
                <Slider {...settings}>
                    {carImg?.map((img,idx) => {
                        return <div key={idx}>
                        <img src={img} alt="pr" className="mx-auto"/>
                    </div>
                    })}
                </Slider>
                {/* <div className=" text-center">
                    <button className="w-100 py-2 text-center font-cario font-bold" style={{backgroundColor: "var(--lightBlue)"}}>WATCH PRODUCT VIDEO</button>
                </div> */}
            </div>
        </>
    );
}

export default ProductCarousel;
