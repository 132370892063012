import React from "react";

import poly from "../../assets/images/poly.svg";
function ListValue({ value, headHighlight, headValue }) {
  return (
    <>
      <div className="flex mb-3">
        <div className="" style={{ flex: "0 0 4%" }}>
          <img src={poly} alt="poly" className=" w-4 pt-2" />
        </div>
        <div className="ml-2">
          {headHighlight ? (
            <>
              <span
                className="font-cario font-bold text-lg"
                style={{ color: "var(--darkBlue)" }}
              >
                {headValue}
              </span>
              <span
                className="font-cario font-normal text-lg ml-1"
                style={{ color: "var(--gray)" }}
              >
                {value}
              </span>
            </>
          ) : (
            <span
              className="block font-cario font-normal text-lg"
              style={{ color: "var(--gray)" }}
            >
              {value}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default ListValue;
