import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/unipack-filter-holder/01.jpg";
import image2 from "../../../../assets/images/product/unipack-filter-holder/03.jpg";
import image3 from "../../../../assets/images/product/unipack-filter-holder/05.jpg";

function UnipackFilterHolder() {
    return (
        <>
            <>
                <Container>
                    <Row>
                        <Col md={12}>
                            <ProductHeadDescription heading={"Inline Filter Holder"} description={"Offering you a complete choice of products which include unipack 25mm inline filter holder, unipack 47mm inline filter holder and unipack 13mm inline filter holder."} />
                        </Col>
                        <Col md={4}>
                            <ProductCard productImg={image2} productName={`Unipack 47mm Inline Filter Holder`} link={"/products/unipack-filter-holder/unipack-47mm-inline-filter-holder"} />
                        </Col>
                        <Col md={4} >
                            <ProductCard productImg={image1} productName={`Unipack 25mm Inline Filter Holder`} link={"/products/unipack-filter-holder/unipack-25mm-inline-filter-holder"} />
                        </Col>
                        <Col md={4}>
                            <ProductCard productImg={image3} productName={`Unipack 13mm Inline Filter Holder`} link={"/products/unipack-filter-holder/unipack-13mm-inline-filter-holder"} />
                        </Col>
                    </Row>
                </Container>
            </>
        </>
    )
}

export default UnipackFilterHolder
