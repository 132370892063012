import React from 'react'
import { Row, Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import profileImg from "../../assets/images/CEO3.jpg"

function Team03() {
    return (
        <>
            <div className="team-content">
                <Row className="team-content-row m-4 justify-center">
                    <Col md={3}>
                        <div className="team-left border-2 border-gray-200 ">
                            <div className="team-img">
                                <Image src={profileImg} className="w-full"/>
                            </div>
                            <div className="team-content">
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-3 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Name : </span>
                                    Mr. Yash Shah
                                </div>
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-3 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Designation : </span>
                                    Marketing Director
                                </div>
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-3 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Qualification : </span>
                                    Chemical Engineer
                                </div>
                                <a className="submitform flex w-full p-3 font-cario font-bold text-white justify-center" 
                                    style={{ backgroundColor: "var(--darkBlue)" }}
                                    href="/contact"
                                >CONTACT NOW
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="team-right">
                            <div className="team-thought font-poppins text-white bg-black p-4">
                                <span className="font-poppins font-bold text-4xl absolute">“</span>
                                <p className="mb-0 pl-3 pt-3">Carpe Diem – Live in the moment</p>
                            </div>
                            <div className="team-experience">
                                {/* <span className="font-bold text-3xl">Experience</span> */}
                                <p className="font-cario font-medium text-base md:text-lg margin-t48 mb-3" style={{ color: "var(--gray)" }}>
                                    Diploma holder in Chemical Engineering from Mumbai. BSCIT from Bombay University 
                                    and a chemical engineer from University of Sheffield (UK). He handles the 
                                    Marketing for Unipack since a decade. Actively involved in the companies Growth 
                                    & Expansion plan in the international market. Is instrumental in getting 
                                    international business to Unipack.</p>
                                <p className="font-cario font-medium text-base md:text-lg" style={{ color: "var(--gray)" }}>
                                    He is an avid sportsman with  keen interest in Music and Movies. Actively plays 
                                    cricket at club levels in Mumbai.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Team03