import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/06.jpg"

function MembraneHolders293() {
    return (
        <>
            <ProductDetailHead productTitle={"293 Membrane Filter Holders"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Usage/Application"} rightValue={"Chemical Laboratory"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack Machines"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS 316 / SS 316L"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 293mm Diameter Membrane Filters"} />
                <ProductTableCol leftValue={"Finish"} rightValue={"Mirror Polished To 220 Grit"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Unipack Membrane filter holders are designed to filter relatively higher volumes of filtration. It is mainly used for the higher flow rates required products namely injectable / Ophthalmic / Vaccine / herbal / products etc. This is a cGMP model which can be supplied with sanitary fitting at inlet and outlet connections."} />
            <OtherDetails title={"Unipack Membrane filter holders are available in two models :"} />
            <Lists>
                <ListValue value={`Membrane filter holder – Bubble test arrangement.`} />
                <ListValue value={`Membrane filter holder – Standard model`} />
            </Lists>
            <OtherDetails title={"Special Features:"} />
            <ProductDescription productDescription={" Bubble Test Arrangement –‘Bubble Point Test’ is a non-destructive test to check the integrity of the Membrane filter, before and after the filtration. The bubble point is the pressure required to force air through the pores of a wet filter. If a Membrane cannot withstand the pressure below bubble point level, it indicates leak in the filter. A built in system for bubble testing can be provided with the membrane holders by building a set of liquid & air control valves"} />
        </>
    )
}

export default MembraneHolders293
