import React from "react";
import { Link, NavLink } from "react-router-dom";
import arrow from "../../assets/images/arrow-down.svg";
import pdf from "../../assets/pdf/unipack-brochure.pdf"
import "./header.css"
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

function BottomMenu() {

  return (
    <>
      <Navbar collapseOnSelect expand="md">
        <p></p>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="bottomNav__ul md:flex justify-between mb-0 items-center">
            <li>
              <div
                className="flex items-center cursor-pointer relative"
              >


                <Nav>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="About"
                    className="font-cario block font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
                  >
          
                    {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}

                    <Link
                      to="/history"
                      className="about-list text-black font-cario font-bold no-underline border-b-2 px-4 py-2 block hover:bg-indigo-900 hover:text-white w-52 md:w-72"
                    >
                      Company History
                    </Link>
                    <Link
                      to="/team"
                      className="about-list text-black font-cario font-bold no-underline border-b-2 px-4 py-2 block hover:bg-indigo-900 hover:text-white"
                    >
                      Our Team
                    </Link>
                    <Link
                      to="/mission"
                      className="about-list text-black font-cario font-bold no-underline border-b-2 px-4 py-2 block hover:bg-indigo-900 hover:text-white"
                    >
                      Mission & Vision
                    </Link>
                    {/* <Link
                      to="/csr"
                      className="about-list text-black font-cario font-bold no-underline border-b-2 px-4 py-2 block hover:bg-indigo-900 hover:text-white"
                    >
                      Corporate Social Responsibility
                    </Link> */}
                    <Link
                      to="/environment-responsibility"
                      className="about-list text-black font-cario font-bold no-underline px-4 py-2 block hover:bg-indigo-900 hover:text-white"
                    >
                      Environment Responsibility
                    </Link>

                  </NavDropdown>
                </Nav>
                
                
              </div>
            </li>
            <li>
              <NavLink
                to="/products/filter-press"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                Products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/turnkey-services"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover "
              >
                Turnkey Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/gallery" 
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/clients"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                Clients
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/testimonials"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                Testimonials
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/rnd"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                R&D
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/careers"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                Jobs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName='is-active'
                className="font-cario font-bold text-gray-600 no-underline hover:text-gray-600 blue-onHover"
              >
                Contact
              </NavLink>
            </li>
            <li>
              {/* <button
                className="flex items-center justify-center px-3 py-2"
                style={{ backgroundColor: "var(--darkBlue)" }}
              >
                <a
                  href={pdf}
                  download
                  className="font-cario font-bold text-white no-underline hover:text-gray-600"
                >
                  Downloads
                </a>
                <img src={arrow} alt="arrow" className="ml-2 w-3" />
              </button> */}

              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" 
                  className="download-pdf-btn font-cario font-bold"
                  style={{ backgroundColor: "var(--darkBlue)" }}>
                  Downloads
                  <img src={arrow} alt="arrow" className="d-inline ml-1 w-3"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={pdf} className="font-cario brochure">Brochure-1</Dropdown.Item>
                  <Dropdown.Item href="#" className="font-cario brochure">Brochure-2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default BottomMenu;