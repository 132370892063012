import React , {useState} from 'react'
import "./homeAbout.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import vplay from "../../assets/images/play-button.png";
import video1 from "../../assets/images/videos/video1.mp4"
import mac1 from "../../assets/images/m1.jpg";
import mac2 from "../../assets/images/m2.jpg";
import mac3 from "../../assets/images/m3.jpg";
import mac4 from "../../assets/images/m5.jpg";
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';
import HomeAboutCar from './HomeAboutCar';
import IFrameModal2 from './IFrameModal2';
import IFrameModal3 from './IFrameModal3';

function HomeAbout() {
  const [modalShow, setModalShow] = useState(false);
  const [vid, setvid] = useState(0);

  const handleVideo1 = () => {
    setModalShow(true)
    setvid(1);
  };

  const handleVideo2 = () => {
    setModalShow(true)
    setvid(2);
  };

  const handleVideo3 = () => {
    setModalShow(true)
    setvid(3);
  };

  const handleVideo4 = () => {
    setModalShow(true)
    setvid(4);
  };

  return (
    <>
      <div className="aboutBg">
        <Container className=" cusContainer">
          <Row className="">
            <Col md={12}>
              <div className="home--aboutContainer">
                <div className="text-center mb-3">
                  <p
                    className="aboutHead relative font-poppins font-semibold text-lg md:text-2xl w-full md:w-1/2 mx-auto border-b pb-2"
                    style={{ borderColor: "#D5D5D5" }}
                  >
                    WELCOME TO UNIPACK MACHINES PVT. LTD.
                  </p>
                </div>
                {/* <div className="homeAbout-slider-container">
                  <HomeAboutCar/>
                </div> */}
                <div
                  className="font-cario font-semibold text-base md:text-lg mb-2 leading-6 text-gray-500"
                  style={{ color: "#5B5A5A" }}
                >
                  <p className="homeAbout-establish">
                    Established in the year <span className="text-gray-600 font-bold">1989</span>, we, <span className="text-gray-600 font-bold">Unipack Machines Private
                    Limited</span>, are one of distinguished manufacturers, suppliers,
                    distributors and exporters of an unlimited series of
                    Machinery and Equipment. Our product range comprises of
                    Filter Presses, Pharmaceutical Machinery and Packaging
                    Machinery, which find a vast application in cosmetics,
                    chemical, paints, food and oil industries. In addition to
                    the outstanding product range, we are engaged in proffering
                    Turn Key Project Management Services for Filtration System
                    and Equipment. Extremely concerned with the quality
                    proffered, we make sure to execute the services as per 
                    industry procedures and norms, for manufacturing the entire range.
                  </p>
                  {/* <p className="mb-4">
                    Ours is a Private Ltd. Co., with a magnificent
                    infrastructure facility that is completely equipped with
                    multiple machines, tools and equipment based on the
                    cutting-edge & latest technology machinery. Moreover, we
                    have recruited a team of professionals, who work extremely
                    hard to attain the organizational goals, as per clients’
                    needs. Having marked our presence all over the globe, we
                    have garnered a long list of customers, which includes J.B.
                    Chemicals, Pepsi Group of Companies and Himalaya Drugs Co.
                    We have set examples for our peers and received their
                    confidence & dependence upon us. We export our products all
                    over the world.
                  </p> */}
                </div>
                <div className="knowMore-btn">
                  <Link   to="/history"
                    className="text-xs md:text-sm block text-white text-center no-underline font-cario font-bold uppercase hover:text-gray-400"
                    style={{ backgroundColor: "var(--darkBlue)" }}
                  >
                      KNOW MORE
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="coreProd-section shadow-lg bg-white">
                <div className=" mb-4 text-center">
                  <p
                    style={{ borderColor: "#D5D5D5" }}
                    className="core relative font-poppins font-semibold border-b text-lg md:text-2xl w-full md:w-1/4 mx-auto pb-2"
                  >
                    CORE PRODUCTS
                  </p>
                </div>
                <Row>
                  <Col xs={6} sm={6} md={3}>
                    <div className="mb-4">
                      <div className="h-36 md:h-60 border">

                        <button onClick={handleVideo1} className="video-play-btn">
                          <img src={vplay} alt="play"/>
                        </button>
                        {
                          vid===1 ? 
                          <MyVerticallyCenteredModal 
                          show={modalShow} onHide={() => setModalShow(false)} 
                          video={video1} title={"Stainless Steel Plate & Frame Filter Press"}/> : <span></span>
                        }
                        
                        <img
                          src={mac1}
                          alt="core"
                          className="h-full object-cover w-full"
                        />

                      </div>
                      <div
                        style={{ backgroundColor: "#BCE0FD" }}
                        className="core-content"
                      >
                        <p className="font-cario font-bold text-sm md:text-lg mb-0 leading-5">
                          {" "}
                          Stainless Steel Plate & Frame Filter Press
                        </p>
                        {/* <p className="font-cario font-normal mb-3 text-sm leading-4">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.{" "}
                        </p> */}

                        <a
                          href="/products/filter-press/plate-frame-type-filter-press-machine"
                          className="core-detailBtn block rounded-3xl mx-3 my-2 text-white no-underline font-cario font-bold uppercase hover:text-gray-400"
                          style={{ backgroundColor: "var(--darkBlue)" }}
                        >
                          view details
                        </a>

                      </div>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3}>
                    <div className="mb-4">
                      <div className="h-36 md:h-60 border">
                        
                        <button onClick={handleVideo2} className="video-play-btn">
                          <img src={vplay} alt="play"/>
                        </button>
                        {
                          vid===2 ? 
                          <IFrameModal2 
                          show={modalShow} onHide={() => setModalShow(false)} 
                          /> : <span></span>
                        }

                        <img
                          src={mac2}
                          alt="core"
                          className="h-full object-cover w-full"
                        />

                      </div>
                      <div
                        style={{ backgroundColor: "#BCE0FD" }}
                        className="core-content"
                      >
                        <p className="font-cario font-bold text-sm md:text-lg margin-b1 leading-5">
                          Linear Vial Washing Machine
                        </p>
                        {/* <a
                          href="/"
                          className="px-4 block rounded-3xl text-sm py-3 mx-2 mb-3 text-white no-underline font-cario font-bold uppercase hover:text-gray-400"
                          style={{ backgroundColor: "var(--darkBlue)" }}
                        >
                          WATCH VIDEO
                        </a> */}
                        <a
                          href="/products/pharmaceutical-machinery"
                          className="core-detailBtn block rounded-3xl mx-3 my-2 text-white no-underline font-cario font-bold uppercase hover:text-gray-400"
                          style={{ backgroundColor: "var(--darkBlue)" }}
                        >
                          view details
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3}>
                    <div className="mb-4">
                      <div className="h-36 md:h-60 border">

                        <button onClick={handleVideo3} className="video-play-btn">
                          <img src={vplay} alt="play"/>
                        </button>
                        {
                          vid===3 ? 
                          <IFrameModal3 
                          show={modalShow} onHide={() => setModalShow(false)} 
                          /> : <span></span>
                        }

                        <img
                          src={mac3}
                          alt="core"
                          className="h-full object-cover w-full"
                        />

                      </div>
                      <div
                        style={{ backgroundColor: "#BCE0FD" }}
                        className="core-content"
                      >
                        <p className="font-cario font-bold text-sm md:text-lg mb-0 leading-5">
                          {" "}
                          Linear Bottle Washing Machine
                        </p>
                        <a
                          href="/products/pharmaceutical-machinery"
                          className="core-detailBtn block rounded-3xl mx-3 my-2 text-white no-underline font-cario font-bold uppercase hover:text-gray-400"
                          style={{ backgroundColor: "var(--darkBlue)" }}
                        >
                          view details
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3}>
                    <div className="mb-4">
                      <div className=" h-36 md:h-60 border">

                        <button onClick={handleVideo4} className="video-play-btn">
                          <img src={vplay} alt="play"/>
                        </button>
                        {
                          vid===4 ? 
                          <MyVerticallyCenteredModal 
                          show={modalShow} onHide={() => setModalShow(false)} 
                          video={video1} title={"Process Equipment Products"}/> : <span></span>
                        }

                        <img
                          src={mac4}
                          alt="core"
                          className="h-full object-cover w-full"
                        />

                      </div>
                      <div
                        style={{ backgroundColor: "#BCE0FD" }}
                        className="core-content"
                      >
                        <p className="font-cario font-bold text-sm md:text-lg mb-0 leading-5">
                          {" "}
                          Process Equipment for Sugar Filtration
                        </p>
                        <a
                          href="/products/pharmaceutical-machinery"
                          className="core-detailBtn block rounded-3xl mx-3 my-2 text-white no-underline font-cario font-bold uppercase hover:text-gray-400"
                          style={{ backgroundColor: "var(--darkBlue)" }}
                        >
                          view details
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HomeAbout;
