import Slider from "react-slick";

import indicator from "../../assets/images/arServ.png";
import serv1 from "../../assets/images/serv1.png";
import serv2 from "../../assets/images/serv2.png";
import serv3 from "../../assets/images/serv3.png";
import serv4 from "../../assets/images/serv4.png";
import serv5 from "../../assets/images/serv5.png";
import serv6 from "../../assets/images/serv6.png";

function Services() {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`cusArr serverArr ${className}`}
        style={{ ...style, display: "block" }}
      >
        <img src={indicator} alt="ind" className={`indClass`} />
      </div>
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`cusArr serverArrNext ${className}`}
        style={{ ...style, display: "block" }}
      >
        <img
          src={indicator}
          alt="ind"
          className={`indClass transform rotate-180`}
        />
      </div>
    );
  };
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="cusArr serverArr" />,
    prevArrow: <PrevArrow className="cusArr serverArr" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  return (
    <>
      <div className="serveCarMain shadow-md">
        <Slider {...settings} className="px-3 md:px-10">
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv1} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">BEVERAGE</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv2} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">PHARMA</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv3} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">DAIRY</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv4} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">CHEMICALS</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv5} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">DISTILLARIES</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv6} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">BREWERIES</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv1} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">BEVERAGE</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv2} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">PHARMA</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv3} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">DAIRY</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv4} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">CHEMICALS</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv5} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">DISTILLARIES</p>
            </div>
          </div>
          <div className="service-item">
            <div className="flex items-center justify-center">
              <img src={serv6} alt="cert"/>
              <p className="service-text font-cario font-bold text-white uppercase">BREWERIES</p>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default Services;
