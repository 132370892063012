import { Link , NavLink } from "react-router-dom";
import Card from 'react-bootstrap/Card'
import "./header.css"
// Image
import reg from "../../assets/images/headerReg.png";
import home from "../../assets/images/home.svg";
import call from "../../assets/images/call.svg";
import mail from "../../assets/images/mail.svg";

import linkedIn from "../../assets/images/linkedin.png";

function TopMenu() {
  return (
    <>
      <div className="topMenuField hidden md:flex items-center">
        <Link to="/" activeClassName='is-active' className="w-full flex no-underline hover:no-underline blue-onHover">
          <img src={home} alt="home" className="w-6 mr-3" />
          <Link to="/" className="font-cario font-bold text-gray-600 no-underline hover:no-underline blue-onHover">Home</Link>
        </Link>
      </div>
      <div className="topMenuField flex items-center">
        <img src={call} alt="call" className="w-4 md:w-6 mr-1 md:mr-3" />
        <a
          href="tel:+919820086877"
          className="font-cario font-bold text-xs md:text-base text-gray-600 no-underline hover:text-gray-600 blue-onHover"
        >
          9820086877
        </a> 
        <span className="mx-1"> / </span>
        <a
          href="tel:+919833320909"
          className="font-cario font-bold text-xs md:text-base text-gray-600 no-underline hover:text-gray-600 blue-onHover"
        >
           9833320909
        </a>
      </div>
      <div className="topMenuField flex items-center">
        <img src={mail} alt="mail" className="w-4 md:w-6 mr-1 md:mr-3" />
        <a
          href="mailto:ump1990@gmail.com"
          className="font-cario font-bold text-xs md:text-base text-gray-600 no-underline hover:text-gray-600 blue-onHover"
        >
          ump1990@gmail.com
        </a>
      </div>
      <div className="topMenuField hidden md:block">
        <img src={reg} alt="reg" className="regBrand w-36" />
      </div>
      <div className="topMenuField hidden md:flex items-center">
        <Card.Link href="https://www.linkedin.com/company/unipackmachines/" target="_blank" className="w-full flex mr-4">
          <img src={linkedIn} alt="tw" className="w-5 md:w-8" />
        </Card.Link>
      </div>
    </>
  );
}

export default TopMenu;