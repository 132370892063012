import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/sterility-testing-unit/03.jpg"

function SterilityTestingEquipment() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterility Testing Equipment"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"SS 316 / SS 316L"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Membrane filters diameter"} rightValue={"47 mm"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Finish"} rightValue={"Mirror polished to 220 grit."} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are offering a wide range of quality oriented Sterility Testing Equipment. The offered sterility testing units are manufactured in a proficient manner with supreme quality raw material and highly advance technology in sync with the latest market standards. These sterility testing units are precisely inspected on each part so as to deliver perfect range to our customers. Also, these sterility testing units are available with various specifications at market leading rates."} />
            <ProductFeatures productFeatures={["Easy installation", "Low maintenance", "High performance"]} />
            <OtherDetails title={"Construction :"} />
            <Lists>
                <ListValue value={`Rigid construction with internationally accepted GMP standard`} />
            </Lists>
            <OtherDetails title={"Other Details :"} />
            <Lists>
                <ListValue value={`Unipack offers complete systems for aseptically filtering liquid samples`} />
                <ListValue value={`The sterility test in these systems are done by the bacteria-retentive membrane filter method`} />
                <ListValue value={`Six places three place test units are available to simultaneously filter five or two test samples,`} />
                <ListValue value={`respectively with a concurrent control filtration of sterile diluting fluid for an immediate overall`} />
                <ListValue value={`verification of system sterility. The entire unit can be autoclaved`} />
                <ListValue value={`Essential parts of the units are a combined manifold for vacuum and flushing, filter holders three-way valves.`} />
            </Lists>
            <OtherDetails title={"Additional Information :"} />
            <Lists>
                <ListValue value={`Item Code: SixPlace`} />
            </Lists>
        </>
    )
}

export default SterilityTestingEquipment
