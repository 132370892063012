import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import historybg from "../../assets/images/banner/turnkeyBanner.jpg";
import "./turnkey.css"
import calendar from "../../assets/images/gallery/calendar.png";

function TurnkeyServices() {
    return (
        <>
            <Innerbanner
                banner={historybg}
                heading={`turnkey services`}
                // title={`A Group you can trust, dating back to 1989.`}
            />
            <Container className="cusContainer turnkeyContainer my-8">
                <Row>
                    <Col md={12}>
                        <div className="text-center">
                            <h3
                                style={{ borderColor: "var(--gray)" }}
                                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                            >
                                TURNKEY SERVICES
                            </h3>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="pt-16">
                            <p className="font-poppins font-bold text-3xl mb-6">Unipack stands apart from our competition as a result of our ability to design and manufacture bespoke solutions</p>
                            <p className="font-cario font-medium text-lg mb-4" style={{ color: "var(--gray)" }}>
                                Our turnkey services are one of the most significant aspects of our value proposition and are designed to allow our clients to get the best out of their machinery.<br/>
                                We provide an end-to-end service that allows manufacturers to get everything they require from one easy place. Unipack stands apart from our competition as a result of our ability to design and manufacture bespoke solutions, specific to an individual’s needs.
                            </p>
                            <p className="font-cario text-lg font-bold" style={{ color: "var(--gray)" }}>Our process is broken down into four stages as shown in the chart on the right side :</p>
                            <p className="font-cario font-medium text-lg" style={{ color: "var(--gray)" }}>
                                Through these stages, we are able to ensure that we provide the best possible solution, likely to yield the optimum in process enhancements and the best ROI possible.<br/>
                                We have a well-established reputation for best-in-class man and machine solutions to provide everything from consultancy to training and maintenance.<br/>
                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="my-16 px-2 py-4 bg-gray-100">
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900 p-">
                                    <img src={calendar} alt="office" className="mr-3 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-2 px-7">Evaluate</span>
                            </div>
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-3 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-2 px-7">Design</span>
                            </div>
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-3 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-2 px-7">Implement</span>
                            </div>
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-3 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-2 px-7">Aftercare</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>




            <Container className="cusContainer turnkeyContainer-mobile my-8">
                <Row>
                    <Col md={12}>
                        <div className="text-center">
                            <h3
                                style={{ borderColor: "var(--gray)" }}
                                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                            >
                                TURNKEY SERVICES
                            </h3>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="pt-4">
                            <p className="font-poppins font-bold text-2xl mb-6">Unipack stands apart from our competition as a result of our ability to design and manufacture bespoke solutions</p>
                            <p className="font-cario font-medium text-base text-justify mb-4" style={{ color: "var(--gray)" }}>
                                Our turnkey services are one of the most significant aspects of our value proposition and are designed to allow our clients to get the best out of their machinery.<br/>
                                We provide an end-to-end service that allows manufacturers to get everything they require from one easy place. Unipack stands apart from our competition as a result of our ability to design and manufacture bespoke solutions, specific to an individual’s needs.
                            </p>
                            <p className="font-cario text-lg font-bold" style={{ color: "var(--gray)" }}>Our process is broken down into four stages as shown in the chart below:</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="my-6 mx-3 px-2 py-2 bg-gray-100">
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-2 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-3 px-3">Evaluate</span>
                            </div>
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-2 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-3 px-3">Design</span>
                            </div>
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-2 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-3 px-3">Implement</span>
                            </div>
                            <div className=" flex px-4 py-3">
                                <div className="h-14 w-14 p-2 bg-indigo-900">
                                    <img src={calendar} alt="office" className="mr-2 w-10" />
                                </div>
                                <span className="font-poppins font-semibold text-2xl py-3 px-3">Aftercare</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="mt-3">
                            <p className="font-cario font-medium text-base text-justify" style={{ color: "var(--gray)" }}>
                                Through these stages, we are able to ensure that we provide the best possible solution, likely to yield the optimum in process enhancements and the best ROI possible.<br/>
                                We have a well-established reputation for best-in-class man and machine solutions to provide everything from consultancy to training and maintenance.<br/>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TurnkeyServices
