import React from 'react'
import Modal from 'react-bootstrap/Modal'
import "./homeAbout.css"

function MyVerticallyCenteredModal(props) {

    return (
        <>
            <Modal
                {...props}
                size="lg"
                dialogClassName="video-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" 
                        className="text-indigo-900 text-center font-12">
                            {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="video-section">
                        <video autoPlay controls >
                            <source src={props.video} type="video/mp4"/>
                        </video>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MyVerticallyCenteredModal
