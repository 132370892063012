import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/turnkey-project-management/01.png"

function TurnKeyProject() {
    return (
        <>
            <ProductDetailHead productTitle={"Turn Key Project Management"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Mode of Service"} rightValue={"Offline"} />
                <ProductTableCol leftValue={"Project Duration"} rightValue={"3-5 years"} />
                <ProductTableCol leftValue={"Project Location"} rightValue={"Maharashtra"} />
                <ProductTableCol leftValue={"Business / Industry Type"} rightValue={"Project Developers"} />
                <ProductTableCol leftValue={"Type of Service Provider"} rightValue={"Individual Consultant, Company"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Our organization has gained recognition as an affluent service provider of Turn Key Project Management. Our offered turn-key management service is rendered under the management of skilled professionals according to the set industry norms. Our turn-key management service is reliable cost effective provides perfect solutions. Offered turnkey management service is provided with various specifications for gaining the complete satisfaction of our customers."} />
            <OtherDetails title={"Other Details :"} />
            <Lists>
                <ListValue value={`Rendered by experts.`} />
                <ListValue value={`Cost effective.`} />
                <ListValue value={`Effective management`} />
            </Lists>
        </>
    )
}

export default TurnKeyProject
