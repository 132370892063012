import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BusinessIcon from "@material-ui/icons/Business";
import "./contact.css";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import bg from "../../assets/images/banner/contactBanner.jpg"
import factory from "../../assets/images/factory.png";
function Contact() {
  return (
    <>
      <Innerbanner banner={bg} heading={`contact us`}/>
      <Container className="mt-4 cusContainer contactContainer">
        <Row className="items-center">
          <Col md={12}>
            <div className="text-center">
              <h3 style={{ borderColor: "var(--gray)" }}
                  className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
              >
              CONTACT US
              </h3>
            </div>
          </Col>
          <Col md={6}>
            <div className="leftForm">
              <div className="contact-headtext">
                <span className="font-poppins block text-3xl font-semibold mb-3">
                  We’d love to hear from you
                </span>
                <span className="font-cario block text-xl font-semibold">
                  If you have any queries simply fillup the form
                </span>
              </div>
              <div className="shadow py-3 mb-4 mt-4">
                <Row className="mx-0 items-center">
                  <Col md={3}>
                    <div className="h-full">
                      <BusinessIcon className="add-logo"/>
                      <span className="font-poppins block text-2xl font-semibold contact-addr-mobile ml-2">
                        OFFICE ADDRESS
                      </span>
                    </div>
                  </Col>
                  <Col md={9}>
                    <div>
                      <span className="font-poppins block text-2xl font-semibold contact-addr">
                        OFFICE ADDRESS
                      </span>
                      <p className="mb-0 font-cario font-medium text-base md:text-lg">
                        Unipack Machines Private Limited<br/>
                        8, Krishna Mahal, 2nd Floor, Churchgate, 'D' Road, Mumbai-400020, India.
                      </p>
                      <span className="mb-0 font-cario font-medium text-base md:text-lg">Tel.: </span>
                      <a href="tel:+9102222812918"
                        className="font-cario font-medium text-base md:text-lg text-black no-underline"> 
                         +91 022 22812918
                      </a>
                      <span className="mx-1">/</span>
                      <a href="tel:+9102222810408"
                        className="font-cario font-medium text-base md:text-lg text-black no-underline">
                        22810408
                      </a>
                      <span className="mx-1">/</span>
                      <a href="tel:+9102232473338"
                        className="font-cario font-medium text-base md:text-lg text-black no-underline">
                        32473338
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="shadow py-3 mb-4">
                <Row className="mx-0 items-center">
                  <Col md={3}>
                    <div className="h-full">
                      <img src={factory} alt="factory" className="add-logo" />
                      <span className="font-poppins block text-2xl font-semibold contact-addr-mobile ml-2">
                        WORK ADDRESS
                      </span>
                    </div>
                  </Col>
                  <Col md={9}>
                    <div>
                      <span className="font-poppins block text-2xl font-semibold contact-addr">
                        WORK ADDRESS
                      </span>
                      <p className="mb-0 font-cario font-medium text-base md:text-lg">
                      Agarwal Industrial Estate, No. 6, Unit No. 8,9 & 10, Sativali Road, Village Waliv, 
                      Vasai (East) 401 208. Dist. Palghar Maharashtra State. INDIA.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <div className="contact-headtext-mobile">
                <span className="font-poppins block text-xl font-medium mt-3 mb-0">
                  We’d love to hear from you
                </span>
                <span className="font-poppins block text-xl font-bold mb-3">
                  If you have any queries simply fillup the form
                </span>
              </div>
              <form>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Email"
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Mobile No."
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Location"
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded mb-4 overflow-hidden shadow">
                  <textarea
                    name="message"
                    placeholder="Comment"
                    className="w-full px-3 h-24 py-3 font-cario font-medium"
                  ></textarea>
                </div>
                <div className="w-full border rounded mb-4 overflow-hidden shadow">
                  <button
                    className="flex items-center font-cario font-bold text-white justify-center px-3 py-3 w-full submitform"
                    style={{ backgroundColor: "var(--darkBlue)" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>




      {/* <Container className="mt-3 cusContainer contactContainer-mobile">
        <Row className="items-center">
          <Col md={12}>
            <div className="text-center">
              <h3 style={{ borderColor: "var(--gray)" }}
                  className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
              >
              CONTACT US
              </h3>
            </div>
          </Col>
          <Col md={6}>
            <div className="leftForm">
              <div className="shadow py-3 mb-4 mt-4">
                <Row className="mx-0 items-center">
                  <Col md={12} className="flex">
                    <div className="h-full">
                      <BusinessIcon className="add-logo"/>
                    </div>
                    <span className="font-poppins block text-2xl font-semibold py-2 ml-1">
                      OFFICE ADDRESS
                    </span>
                  </Col>
                  <Col md={12}>
                    <p className="mb-0 font-cario font-medium text-base md:text-lg">
                      Unipack Machines Private Limited<br/>
                      8, Krishna Mahal, 2nd Floor, Churchgate, 'D' Road, Mumbai-400020, India.
                    </p>
                    <span className="mb-0 font-cario font-medium text-base md:text-lg">Tel.: </span>
                      <a href="tel:+9102222812918"
                        className="font-cario font-medium text-base md:text-lg text-black no-underline"> 
                         +91 022 22812918
                      </a>
                      <span className="mx-1">/</span>
                      <a href="tel:+9102222810408"
                        className="font-cario font-medium text-base md:text-lg text-black no-underline">
                        22810408
                      </a>
                      <span className="mx-1">/</span>
                      <a href="tel:+9102232473338"
                        className="font-cario font-medium text-base md:text-lg text-black no-underline">
                        32473338
                      </a>
                  </Col>
                </Row>
              </div>
              <div className="shadow py-3 mb-4">
                <Row className="mx-0 items-center">
                  <Col md={12} className="flex">
                    <div className="h-full">
                      <img src={factory} alt="factory" className="add-logo" />
                    </div>
                    <span className="font-poppins block text-2xl font-semibold py-2 ml-2">
                      WORK ADDRESS
                    </span>
                  </Col>
                  <Col md={12}>
                    <p className="mb-0 font-cario font-medium text-base md:text-lg">
                      Agarwal Industrial Estate, No. 6, Unit No. 8,9 & 10, Sativali Road, Village Waliv, 
                      Vasai (East) 401 208. Dist. Palghar Maharashtra State. INDIA.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <span className="font-poppins block text-xl font-medium mt-3 mb-0">
                We’d love to hear from you
              </span>
              <span className="font-poppins block text-xl font-bold mb-3">
                If you have any queries simply fillup the form
              </span>
              <form>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Email"
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Mobile No."
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded h-10 mb-4 overflow-hidden shadow">
                  <input
                    type="text"
                    placeholder="Location"
                    className="w-full h-full px-3 font-cario font-medium"
                  />
                </div>
                <div className="w-full border rounded mb-4 overflow-hidden shadow">
                  <textarea
                    name="message"
                    placeholder="Comment"
                    className="w-full px-3 h-24 py-3 font-cario font-medium"
                  ></textarea>
                </div>
                <div className="w-full border rounded mb-4 overflow-hidden shadow">
                  <button
                    className="flex items-center font-cario font-bold text-white justify-center px-3 py-3 w-full submitform"
                    style={{ backgroundColor: "var(--darkBlue)" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container> */}
    </>
  );
}

export default Contact;
