import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import prod1 from "../../../../assets/images/product/filteration-equipments/sugar1.jpeg"
import prod2 from "../../../../assets/images/product/filteration-equipments/sugar2.jpeg"


function SugarSyrupNew() {
  return (
    <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <div className="text-center mb-5">
                        <span className="block font-poppins font-bold text-xl mb-5" style={{color: 'var(--darkBlue)'}}>SUGAR SYRUP FILTRATION</span>
                        <img src={prod1} alt="filter-press1" className=" h-auto mx-auto"/>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-5">
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            Sugar syrup used for soft drinks mainly is from cane sugar with 60° -68° brix and at a 
                            filtration temperature of 70° - 85°C. The quality of sugar differs widely depending on 
                            its origin and refining process. In the beverage industry the purity of the sugar 
                            solution is measured in ICUMSA or RBU units. A high ICUMSA Or RBU reading (150 - 450) 
                            indicates a poor quality sugar and means it is difficult to process. The international 
                            accepted standard for sugar syrup with 65° brix is less than 50 ICUMSA.
                        </p>
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            To reduce the ICUMSA/RBU value the syrup often is treated with activated carbon (AC) 
                            and hyflowsupercell. The AC dosages range from 0.2 to 0.8 %. Because of the high dosage 
                            of AC, filters with a large sludge volume should be used. In cases where a continuous 
                            Filtration process is required, it is advisable to use two filters so that while one 
                            filter is filtering, the second filter can be in preparation or CIP.
                        </p>
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            For the filtration of sugar syrup, excellent results are obtained with a UMPL make Zero 
                            hold up Sparkler filter press or Vertical plate and frame filter press whichever 
                            filteration capacity system is preferred. Both these filters are designed to withstand 
                            high temperatures, leakproof, robust construction in superior stainless steel.
                        </p>
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            Furthermore the filters press (Plate & Frame) are available either as manually closing 
                            system operated plants or with hydraulic closing systems.
                        </p>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="mb-5">
                        <img src={prod2} alt="filter-press2"/>
                    </div>
                </Col>
                <Col md={7}>
                    <div className="mb-5">
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            Zero hold up Sparkler filter press. Compact skid mounted unit complete with Hyflow 
                            coating tank, syrup cooling PHE, SS control panel, pipes and fittings. Capacity range 
                            starting from 200 LPH to 3000 LPH
                        </p>
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            Vertical plate and frame filter press with or without hydraulic closing device. 
                            Capacity range starting from 200 LPH to 50,000 LPH
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default SugarSyrupNew;
