import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import image from "../../../../assets/images/product/filter-press/09.png"

function PlateFrameFilterMachine() {
    return (
        <>
            <ProductDetailHead productTitle={"Plate Frame Filter Press Machine"} productPrice={"Approx. Rs 10 Lakh / Onwards"} carImg={[image]}>
                <ProductTableCol leftValue={"Filtration Grade"} rightValue={"Medium Filter"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Size"} rightValue={"20X 20,40X 40,60X 60, 100 X 100 cm"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Owing to our rich industry experience, we are offering a wide range of Plate Frame Filter Press Machine. At our developing unit, we design this filter press as per the international standards by making use of best quality basic material and latest technology. The offered filter press is perfectly examined in terms of quality for ensuring their flawlessness. Along with that, customers can avail the complete assortment of filter press at a nominal price."} />
            <ProductFeatures productFeatures={["Prevents Evaporation", "Excellent Filtrate Rate", "High Cake Holding Capacity","Energy Efficient"]} />
        </>
    )
}

export default PlateFrameFilterMachine
