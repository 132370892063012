import "./header.css";
import { Container, Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom"
import TopMenu from "./TopMenu";

// Image
import logo from "../../assets/images/logoblue.svg"
import BottomMenu from "./BottomMenu";
function Header() {
  return (
    <>
      <div className="header bg-white sticky top-0 z-50 shadow">
        <nav>
          <Container className="cusContainer py-3">
            <Row className="items-center">
              <Col md={2}>
                  <div className="w-36">
                    <Link to="/">
                        <img src={logo} alt="logo" className="w-full h-full" />
                    </Link>
                  </div>
              </Col>
              <Col md={10}>
                  <div className="navMenu__wrapper flex flex-col">
                      <div className="topNav flex justify-between border-b border-solid border-gray-400 pb-2">
                          <TopMenu/>
                      </div>
                      <div className="bottomNav pt-2">
                          <BottomMenu/>
                      </div>
                  </div>
              </Col>
            </Row>
          </Container>
        </nav>
      </div>




      <div className="header-mobile bg-white sticky top-0 z-50 shadow">
        <nav>
          <Container className="cusContainer px-1 py-3">
            <Row className="items-center w-full mx-auto">
            <Col md={12}>
                <div className="topNav flex justify-between border-b border-solid border-gray-400 pb-2">
                    <TopMenu/>
                </div>
            </Col>
            </Row>
            <Row className="items-center w-full mx-auto">
              <Col xs={4} sm={4} md={2}>
                  {/* <div className="w-36"> */}
                  <div>
                    <Link to="/">
                        <img src={logo} alt="logo" className=" w-10/12 md:w-full h-5/6 md:h-full" />
                    </Link>
                  </div>
              </Col>
              <Col xs={8} sm={8} md={10}>
                  <div className="navMenu__wrapper flex flex-col">
                      <div className="bottomNav pt-2">
                          <BottomMenu/>
                      </div>
                  </div>
              </Col>
            </Row>
          </Container>
        </nav>
      </div>
    </>
  );
}

export default Header;