import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import gallryBanner from "../../assets/images/banner/galleryBanner.jpg";
import GallaryTabs from "./GallaryTabs";
import "./gallery.css"
function Gallery() {
  return (
    <>
      <Innerbanner banner={gallryBanner} heading={`gallery`} />
      <Container className="cusContainer my-8">
        <Row>
          <Col md={12}>
            <div className="text-center">
              <h3
                style={{ borderColor: "#D5D5D5" }}
                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-64 mx-auto uppercase"
              >
                OUR GALLERY
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="cusContainer">
        <Row>
          <Col md={12}>
            <GallaryTabs />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Gallery;
