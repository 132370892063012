import React from 'react'
import "./clients.css";
import { Container, Row, Col ,Image } from "react-bootstrap";
import logo01 from "../../assets/images/clients/pepsi.png"
import logo02 from "../../assets/images/clients/coca-cola.png"
import logo03 from "../../assets/images/clients/parle-agro.png"
import logo04 from "../../assets/images/clients/hershey.png"
import logo05 from "../../assets/images/clients/tetrapak.png"
import logo06 from "../../assets/images/clients/bacardi.png"
import logo07 from "../../assets/images/clients/goma.png"
import logo08 from "../../assets/images/clients/repute.png"
import logo09 from "../../assets/images/clients/pall.png"
import logo10 from "../../assets/images/clients/unicorn.png"
import logo11 from "../../assets/images/clients/pharmalab.jpg"
import logo12 from "../../assets/images/clients/tataconsumerproducts.png"
import logo13 from "../../assets/images/clients/hul.png"
import logo14 from "../../assets/images/clients/jaipuria.png"
import logo15 from "../../assets/images/clients/ub.png"
import logo16 from "../../assets/images/clients/amul.png"
import logo17 from "../../assets/images/clients/itc.png"
import logo18 from "../../assets/images/clients/mother-dairy.png"
import logo19 from "../../assets/images/clients/frooti.png"
import logo20 from "../../assets/images/clients/bisleri.png"
import logo21 from "../../assets/images/clients/dabur.png"
import logo22 from "../../assets/images/clients/gowardhan.png"
import logo23 from "../../assets/images/clients/oxyrich.png"
import logo24 from "../../assets/images/clients/bingo.png"
import logo25 from "../../assets/images/clients/tata-tea.png"
import logo26 from "../../assets/images/clients/del-monte.png"
import logo27 from "../../assets/images/clients/bayer.png"
import logo28 from "../../assets/images/clients/cloud-9.png"
import logo29 from "../../assets/images/clients/alkem.png"
import logo30 from "../../assets/images/clients/orchid.png"

//            dr-reddys daimond 

function ClientContent() {
    return (
        <>
        <Container className="cusContainer my-8">
        <Row>
          <Col md={12}>
            <div className="text-center">
              <h3
                style={{ borderColor: "var(--gray)" }}
                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
              >
                OUR CLIENTS
              </h3>
            </div>
          </Col>
          <Col md={12} className="margin-t48">
            <div className="text-center">
              <p className="font-cario font-medium text-base md:text-lg" style={{color: "var(--gray)"}}>
                We, Unipack Machines Private Limited, established our operations
                in the year 1989, as one of the trustworthy manufacturers,
                suppliers, distributors and exporters of a massive compilation
                of Machinery and Equipment.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div class="grid-container-gallery">
                <div class="grid-item-gallery shadow-md"><Image src={logo01} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo02} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo03} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo04} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo05} className="common-image-size" id="cus-width1"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo06} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo07} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo08} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo09} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo10} className="common-image-size" id="cus-width2"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo11} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo12} className="common-image-size" id="cus-width2"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo13} className="common-image-size" id="cus-width2"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo14} className="common-image-size" id="cus-width1"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo15} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo16} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo17} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo18} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo19} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo20} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo21} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo22} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo23} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo24} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo25} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo26} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo27} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo28} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo29} className="common-image-size"/></div>
                <div class="grid-item-gallery shadow-md"><Image src={logo30} className="common-image-size"/></div>
            </div>
          </Col>
        </Row>

        

      </Container>
            
        </>
    )
}

export default ClientContent
