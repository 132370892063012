import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import prod1 from "../../../../assets/images/product/inline-filter-holders/1.jpg"
import prod2 from "../../../../assets/images/product/inline-filter-holders/2.jpg"
import prod3 from "../../../../assets/images/product/inline-filter-holders/3.jpg"

function InlineFilterNew() {
  return (
    <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <span className="block font-poppins font-bold text-xl text-center mb-5" style={{color: 'var(--darkBlue)'}}>INLINE FILTER HOLDERS</span>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod1} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold text-lg my-2">
                            47mm
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod2} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold text-lg my-2">
                            25mm
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="text-center mb-5">
                        <img src={prod3} alt="sterility-testing-unit-single" className=" h-60 mx-auto"/>
                        <p className=" font-cario font-bold text-lg my-2">
                            13mm
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="">
                        <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                        <ProductTableCol leftValue={"Construction"} rightValue={"Rigid construction with internationally accepted GMP standard"} />
                        <ProductTableCol leftValue={"Capacity"} rightValue={"For 47mm, 25mm, 13 mm diameter Membrane filters"} />
                        <ProductTableCol leftValue={"Material Grade"} rightValue={"S.S. 316/S.S. 316L"} />
                        <ProductTableCol leftValue={"Finish"} rightValue={"Mirror polished to 220 grit"} />
                        <ProductTableCol leftValue={`Design`} rightValue={`Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential`} />
                    </div>
                </Col>
                <OtherDetails title={"Special Feature"} />
                <ProductDescription productDescription={"For sterilizing small volumes of liquids, small size membrane filter holder"} />
            </Row>
        </Container>
    </>
  );
}

export default InlineFilterNew;