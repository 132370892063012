import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card'
import "./footer.css";

// import facebook from "../../assets/images/facebook.png"
import linkedin from "../../assets/images/linkedin.png"
import mail from "../../assets/images/mail-w.png";
import call from "../../assets/images/call-w.png";
import loc from "../../assets/images/loc-w.png";
import logo from "../../assets/images/logowhite.svg";
function Footer() {

  return (
    <>
      <footer className="footer pt-5 pb-4"
        // style={{ backgroundColor: "#080737" }} 
        style={{ backgroundColor: "var(--darkBlue)" }}>
        <Container className="cusContainer">
          <Row>
            <Col md={3}>
              <div>
                <div>
                  <img src={logo} alt="" className="w-40" />
                </div>
                <p className="text-white text-sm mb-0">
                  Website designed & developed by
                </p>
                <Card.Link href="https://www.beakmedia.com/" target="_blank" className="text-white cursor-pointer no-underline">
                  Beak Media
                </Card.Link>
                <div className="flex my-4">
                  {/* <Card.Link href="https://www.facebook.com/pages/Unipack%20Machines%20Pvt.%20Ltd./146235019241382/" target="_blank" className="flex">
                    <img src={facebook} alt="fd" className="w-8" />
                  </Card.Link> */}
                  <Card.Link href="https://www.linkedin.com/company/unipackmachines/" target="_blank" className="flex">
                    <img src={linkedin} alt="tw" className="w-8" />
                  </Card.Link>
                </div>
              </div>
            </Col>
            <Col md={3} className="pt-5">
              <div className="mb-4">
                <span className="font-poppins block text-2xl font-semibold text-white">
                  Contact Company
                </span>
              </div>
              <div>
                <div className="flex mb-4">
                  <img src={loc} alt="loc" className=" w-7 h-7 mr-4" />
                  <p className="font-cario font-medium text-white text-justify text-sm mb-0">
                    Agarwal Industrial Estate, No. 6, Unit No. 8,9 & 10, Sativali Road, Village Waliv, Vasai (East) 401 208.<br/>
                    Dist. Palghar Maharashtra State. INDIA.
                  </p>
                </div>
                <div className="flex mb-4">
                  <img src={call} alt="loc" className="w-6 h-6 mr-4" />
                  <a
                    href="tel:+919820086877"
                    className="font-cario font-medium text-sm text-white py-1 no-underline"
                  >
                    9820086877
                  </a>
                  <span className="mx-1 text-white"> / </span>
                  <a
                    href="tel:+919833320909"
                    className="font-cario font-medium text-sm text-white py-1 no-underline"
                  >
                    9833320909
                  </a>
                </div>
                <div className="flex mb-4">
                  <img src={mail} alt="loc" className="w-6 h-6 mr-4" />
                  <a
                    href="mailto:info@unipackmachines.in"
                    className="font-cario font-medium text-sm text-white no-underline"
                  >
                    info@unipackmachines.in
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3} className="pt-5">
              <div className="center w-3/5">
                <div className="mb-4">
                  <span className="font-poppins block text-2xl font-semibold text-white">
                    Legal
                  </span>
                </div>
                <div>
                  <a
                    href="http://"
                    className="font-cario font-medium text-lg mb-2 text-white block no-underline"
                  >
                    Disclaimer
                  </a>
                  <a
                    href="http://"
                    className="font-cario font-medium text-lg mb-2 text-white block no-underline"
                  >
                    Terms of Service
                  </a>
                  <a
                    href="http://"
                    className="font-cario font-medium text-lg mb-2 text-white block no-underline"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="http://"
                    className="font-cario font-medium text-lg mb-2 text-white block no-underline"
                  >
                    Sitemap
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3} className="pt-5">
              <div>
                <div className="mb-4">
                  <span className="font-poppins block text-2xl font-semibold text-white">
                    Subscribe to our newsletter
                  </span>
                </div>
                <div>
                    <div className="w-full h-10 rounded-sm">
                        <input type="text" className="h-full bg-transparent newsInput w-4/5 pl-2 text-white" placeholder="Email Address"/>
                        <button className="bg-white text-blue-400 h-full w-1/5">ok</button>
                    </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>



      <footer className="footer-mobile pt-5 pb-1"
        // style={{ backgroundColor: "#080737" }} 
        style={{ backgroundColor: "var(--darkBlue)" }}>
        <Container className="cusContainer">
          <Row>
            <Col xs={4} sm={4} md={4}>
              <div>
                <div>
                  <img src={logo} alt="" className=" w-28"/>
                </div>
                <div className="flex my-2">
                  <Card.Link href="https://www.linkedin.com/company/unipackmachines/" target="_blank" className="flex">
                    <img src={linkedin} alt="tw" className=" w-7" />
                  </Card.Link>
                </div>
              </div>
            </Col>
            <Col xs={8} sm={8} md={8}>
              <div className="mb-2">
                <span className="font-poppins block text-lg font-semibold text-white">
                  Contact Company
                </span>
              </div>
              <div>
                <div className="flex mb-2">
                  <img src={loc} alt="loc" className=" w-5 h-5 mt-1 ml-1 mr-2 pr-1"/>
                  <p className="font-cario font-medium text-white text-sm mb-0">
                    Agarwal Industrial Estate, No. 6, Unit No. 8,9 & 10, Sativali Road, Village Waliv, Vasai (East) 401 208.<br/>
                    Dist. Palghar Maharashtra State. INDIA.
                  </p>
                </div>
                <div className="flex mb-2">
                  <img src={call} alt="loc" className="w-5 h-5 mr-2" />
                  <a
                    href="tel:+919820086877"
                    className="font-cario font-medium text-sm text-white py-1 no-underline"
                  >
                    9820086877
                  </a>
                  <span className="mx-1 text-white"> / </span>
                  <a
                    href="tel:+919833320909"
                    className="font-cario font-medium text-sm text-white py-1 no-underline"
                  >
                    9833320909
                  </a>
                </div>
                <div className="flex">
                  <img src={mail} alt="loc" className="w-5 h-5 mr-2" />
                  <a
                    href="mailto:info@unipackmachines.in"
                    className="font-cario font-medium text-sm text-white no-underline"
                  >
                    info@unipackmachines.in
                  </a>
                </div>
              </div>
            {/* </Col> */}
          {/* </Row>
          <Row> */}
            {/* <Col xs={8} sm={8} md={8} className="py-3 "> */}
              <div className="w-full py-3">
                <div className="mb-2">
                  <span className="font-poppins block text-lg font-semibold text-white">
                    Legal
                  </span>
                </div>
                <div>
                  <a
                    href="http://"
                    className="font-cario font-medium text-base mb-1 text-white block no-underline"
                  >
                    Disclaimer
                  </a>
                  <a
                    href="http://"
                    className="font-cario font-medium text-base mb-1 text-white block no-underline"
                  >
                    Terms of Service
                  </a>
                  <a
                    href="http://"
                    className="font-cario font-medium text-base mb-1 text-white block no-underline"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="http://"
                    className="font-cario font-medium text-base mb-1 text-white block no-underline"
                  >
                    Sitemap
                  </a>
                </div>
              </div>
            {/* </Col> */}
          {/* </Row>
          <Row> */}
            {/* <Col xs={8} sm={8} md={8} className="flex items-center"> */}
              <div className="center mb-4">
                <div className="mb-2">
                  <span className="font-poppins block text-lg font-semibold text-white">
                    Subscribe to our newsletter
                  </span>
                </div>
                <div>
                    <div className="w-full h-10 rounded-sm">
                        <input type="text" className="h-full bg-transparent newsInput w-4/5 pl-2 text-white" placeholder="Email Address"/>
                        <button className="bg-white text-blue-400 h-full w-1/5">ok</button>
                    </div>
                </div>
              </div>
            </Col>
          {/* </Row>
          <Row> */}
            <Col md={12} className="border-t-2 border-white">
              <p className="text-white text-center text-base mt-2 my-1">
                Website designed & developed by 
                <Card.Link href="https://www.beakmedia.com/" target="_blank" className="text-white cursor-pointer no-underline ml-1">
                  Beak Media
                </Card.Link>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
