import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import prod1 from "../../../../assets/images/banner1.jpg"
import prod2 from "../../../../assets/images/banner2.jpg"
import prod3 from "../../../../assets/images/banner3.jpg"
import prod4 from "../../../../assets/images/banner4.jpg"


function PlateandFrameNew() {
  return (
    <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <div className="text-center mb-5">
                        <span className="block font-poppins font-bold text-xl mb-5" style={{color: 'var(--darkBlue)'}}>PLATE AND FRAME FILTER PRESS</span>
                        <img src={prod1} alt="filter-press1" className="h-auto mx-auto"/>
                        <p className=" font-cario font-bold text-lg">
                            60 cms × 60 cms with hydraulic closing device
                        </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mb-5">
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            The Plate and Frame Type Filter Presses are designated for the clarification of pharmaceuticals, 
                            chemicals, antibiotics and bacteriological products. The bigger size units find a wide range of 
                            applications in the beverage industry.
                        </p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="mb-5">
                        <img src={prod2} alt="filter-press2" className="h-auto"/>
                        <p className=" font-cario font-bold text-lg text-center">
                            20 cms × 20 cms with manual closing device, pump and trolley
                        </p>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="mb-5">
                        <p className=" font-cario font-bold text-lg text-gray-500">
                            AN ASSET THAT ELIMINATES LIQUID LIABILITIES
                        </p>
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            This filtration unit consists of several parts of filter plates and frames arranged vertically 
                            on an S. S. chassis between a pair of end plates so that alternate sets are connected through 
                            sockets forming separate channels for inlet and outlet. The fixed end plate has the main inlet 
                            and outlet connections mounted on it, while the moving end plate has tightening screw which 
                            serves as a closure.
                        </p>
                        <p className=" font-cario font-normal text-lg text-gray-500 text-justify">
                            The liquid under pressure is fed through the inlet channel. After proper venting, it enters the 
                            frames and gets filtered through filter pads placed on both sides of the plate. The filtrate 
                            passes through the plate to the outlet channel whereas the impurities get collected on the frame 
                            in the form of a cake.
                        </p>
                    </div>
                </Col>
                <Col md={5} className="filterpress3-mobile">
                    <div className="mb-5">
                        <img src={prod3} alt="filter-press3" className="h-auto"/>
                        <p className=" font-cario font-bold text-lg text-center">
                            40 cms × 40 cms without washer type with hydraulic closing device
                        </p>
                    </div>
                </Col>
                <Col md={7}>
                    <div className="mb-5">
                        <p className=" font-cario font-bold text-lg text-gray-500 mb-0">UNIQUE DESIGN FEATURE</p>
                        <Lists>
                            <ListValue value={`Robust construction in superior Stainless Steel (304 or 316 grade of materials) with fabricated plates and frames.`}/>
                            <ListValue value={`The number of plates and frames can be increased and decreased depending on the requirement of filtration area.`}/>
                            <ListValue value={`The frames can be fabricated in enlarged thickness of 25 mm and 40 mm, if higher cake holding capacity is desired.`}/>
                            <ListValue value={`Precisely customized to meet users needs and is backed by a sophisticated filter support laboratory.`}/>
                        </Lists>
                    </div>
                </Col>
                <Col md={5} className="filterpress3-desktop">
                    <div className="mb-5">
                        <img src={prod3} alt="filter-press3" className="h-auto"/>
                        <p className=" font-cario font-bold text-lg text-center">
                            40 cms × 40 cms without washer type with hydraulic closing device
                        </p>
                    </div>
                </Col>
                <Col md={5}>
                    <div>
                        <img src={prod4} alt="filter-press4" className="h-auto"/>
                        <p className=" font-cario font-bold text-lg text-center">
                            40 cms × 40 cms with washer type and manual closing device
                        </p>
                    </div>
                </Col>
                <Col md={7}>
                    <div>
                        <Lists>
                            <ListValue value={`The unit can be used for Kieselguhr filtration with the use of filter-aid.`}/>
                            <ListValue value={`A change over i.e. Bypass plate can be arranged at a desired position to obtain pre and final filtration in one operation e.g. clarifying and sterilizing in the case of sterile products.`}/>
                            <ListValue value={`Provided with suitable vent valves, diaphragm type pressure gauge etc.`}/>
                            <ListValue value={`Provided with specially designed mechanical closing devices wherever required. Inlet and Outlet with butterfly valves.`}/>
                        </Lists>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default PlateandFrameNew;
