import React from "react";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import { Container, Row, Col } from "react-bootstrap";
import historybg from "../../assets/images/banner/historyBanner.jpg";
import HistoryTimeLine from "./HistoryTimeLine";
import "./about.css"
function History() {
  return (
    <>
      <Innerbanner
        banner={historybg}
        heading={`company history`}
        title={`A Group you can trust, dating back to 1989.`}
      />
      <Container className="cusContainer my-8">
        <Row>
          <Col md={12}>
            <div className="text-center">
              <h3
                style={{ borderColor: "var(--gray)" }}
                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
              >
                OUR COMPANY HISTORY
              </h3>
            </div>
          </Col>
          <Col md={12} className="margin-t48">
            <div className="text-center">
              <p className="font-cario font-medium text-base md:text-lg" style={{color: "var(--gray)"}}>
                We, Unipack Machines Private Limited, established our operations
                in the year 1989, as one of the trustworthy manufacturers,
                suppliers, distributors and exporters of a massive compilation
                of Machinery and Equipment. Our product series comprises of
                Filter Presses, Pharmaceutical Machinery and Packaging Machinery
                that is massively demanded in beverage, packaging,
                pharmaceutical, distillery and breweries. Moreover, we are
                involved in presenting Turn Key Project Management Services for
                Filtration System and Equipment. Considered a noted name in the
                business, we are highly acclaimed for the quality grade range
                and reliable services. We export our products all over the
                world.
              </p>
            </div>
          </Col>
          <Col md={12} className="margin-t48">
            <div className="text-center">
              <h3
                style={{color: 'var(--darkBlue)'}}
                className="relative font-poppins font-bold text-lg md:text-2xl pb-2 mx-auto uppercase"
              >
                UNIPACK Group History Timeline
              </h3>
            </div>
          </Col>
          <Col md={12} className="margin-t48">
              <HistoryTimeLine/>
          </Col>
          {/* <Col md={12} className="mt-4">
              <div className="text-center flex justify-center">
                  <button className="flex items-center justify-center px-9 py-2 font-cario font-bold text-white no-underline hover:text-gray-600" style={{backgroundColor: "var(--darkBlue)"}}>
                      Load More
                  </button>
              </div>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default History;
