import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import image from "../../../../assets/images/product/sterility-testing-unit/05.jpg"

function SterilityTestUnit() {
    return (
        <>
            <ProductDetailHead productTitle={"Sterility Test Unit"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Model"} rightValue={"3/6 Place"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 47mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
            </ProductDetailHead>
            <OtherDetails title={"Construction :"} />
            <ProductDescription productDescription={"Rigid construction with internationally accepted GMP standard."} />
            <OtherDetails title={"Design :"} />
            <ProductDescription productDescription={"Unipack offers complete systems for aseptically filtering liquid samples. The Sterility tests in these systems are done by the bacteria-retentive membrane filter method. "} />
            <OtherDetails title={"Special Features :"} />
            <ProductDescription productDescription={"Six place & three place test units are available to simultaneously filter five or two test samples, respectively with a concurrent control filtration of sterile diluting fluid for an immediate overall verification of sterility system The entire unit can be autoclaved. Essential parts of the units are a combined Manifold for vacuum and flushing, filter holders & three way valves.  "} />
        </>
    )
}

export default SterilityTestUnit
