import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import bg from "../../assets/images/banner/researchBanner.jpg"
import con from "../../assets/images/research/research.jpg"
import "./research.css"

function Research() {
    return (
        <>
            <Innerbanner banner={bg} heading={`Research & Development`} />
            <div className="researchbackdrop">
                <Container className="cusContainer pt-28 pb-40">
                    <Row>
                        <Col md={5}>
                            <div className="h-full">
                                <img src={con} alt="con" className="h-full" />
                            </div>
                        </Col>
                        <Col md={7}>
                            <div>
                                {/* <span className="font-medium font-poppins block uppercase text-2xl mb-2">
                                    Let’s Grow Together
                                </span> */}
                                <span className="font-bold font-poppins block uppercase text-2xl mb-3">
                                    Research & Development has been the thrust area of Unipack since its inception
                                </span>
                                <p className="font-cario font-medium text-base md:text-lg text-justify mb-5" style={{ color: "var(--gray)" }}>
                                Together with our customers and engineers, our best-in-class R&D teams 
                                develop the most innovative products, solutions and services, as well as advanced 
                                manufacturing processes to suit your business needs and production process.<br/><br/>
                                Our R&D is driven by our customers, who face today’s major challenges: 
                                achieving high production efficiency, quick turnaround time, lowering the cost 
                                of manufacturing, reducing the environmental footprint and at the same time 
                                meeting high standards of production.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>




            <div className="researchbackdrop-mobile">
                <Container className="cusContainer p-4">
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <h3 style={{ borderColor: "var(--gray)" }}
                                    className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                                >
                                Research & Development
                                </h3>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="h-full mt-3">
                                <img src={con} alt="con" className="h-auto" />
                            </div>
                        </Col>
                        <Col md={7}>
                            <div>
                                <span className="font-bold font-poppins block uppercase text-xl mt-2 mb-3">
                                    Research & Development has been the thrust area of Unipack since its inception
                                </span>
                                <p className="font-cario font-medium text-base md:text-lg text-justify" style={{ color: "var(--gray)" }}>
                                Together with our customers and engineers, our best-in-class R&D teams 
                                develop the most innovative products, solutions and services, as well as advanced 
                                manufacturing processes to suit your business needs and production process.<br/><br/>
                                Our R&D is driven by our customers, who face today’s major challenges: 
                                achieving high production efficiency, quick turnaround time, lowering the cost 
                                of manufacturing, reducing the environmental footprint and at the same time 
                                meeting high standards of production.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Research
