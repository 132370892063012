import React from 'react'
import { Image} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./testimonial.css";

function TestimonialContent({profImg,profQuote,profName,profTitle}) {
    console.log(profQuote);
    return (
        <>
            <div className="test-content">
                <div className="content-profile flex">
                    <div className="profile-img">
                        <Image src={profImg} rounded />
                    </div>
                    <div className="profile-desc">
                        <p className="profile-name text-lg md:text-xl font-bold m-0">{profName}</p>
                        <p className="font-cario m-0">{profTitle}</p>
                    </div>
                </div>
                <div className="content-text relative">
                    <div className="test-quote">
                        <div class="test-quote-icon">
                            <FontAwesomeIcon icon={["fas", "quote-right"]}/>
                        </div>
                    </div>
                    
                    {profQuote?.map((profileQuote) => {
                        return <><p className="font-cario text-sm m-0 text-justify">{profileQuote}</p></>
                    })}
                    
                </div>
            </div>
        </>
    )
}

export default TestimonialContent
