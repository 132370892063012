import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/01.jpg"

function MembraneFilter90() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter Holder (90mm) - STD"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Filter Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Capacity"} rightValue={"For 90 mm diameter membrane filters"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are instrumental in providing our clients a comprehensive range of excellent quality Membrane Filter Holder. The offered filter holder is broadly used in various industries for its durability. This filter holder is developed by our team of skillful professionals by utilizing finest quality raw materials and latest technology in accordance with universally accepted standards. Available in diverse technical specifications, we provide this filter holder in customized options in order to fulfill the needs of our valued customers"} />
            <ProductFeatures productFeatures={["Sturdy design", "Smooth performance", "Low maintenance"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Bubble Test Arrangement ‘Bubble Point Test’ is a non-destructive test to check the integrity`} />
                <ListValue value={`of the membrane filter, before and after the filtration`} />
                <ListValue value={`The bubble point is the pressure required to force air through the pores of a wet filter`} />
                <ListValue value={`If a membrane cannot withstand the pressure below bubble point level, it indicates leak in the filter`} />
                <ListValue value={`A built-in system for bubble testing can be provided with the membrane holders by in building a set of liquid air control valves`} />
                <ListValue value={`Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential`} />
                <ListValue value={`Rigid construction with internationally accepted GMP standard`} />
            </Lists>
        </>
    )
}

export default MembraneFilter90
