import React from "react";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import "./career.css";
import bg from "../../assets/images/careers/careerbanner.jpg"
import CareerContent from "./CareerContent";
function Careers() {
  return (
    <>
      <Innerbanner banner={bg} heading={`careers`}/>
      <CareerContent/>
    </>
  );
}

export default Careers;
