import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/membrane-filter-holder/05.jpg"

function MembraneHolders() {
    return (
        <>
            <ProductDetailHead productTitle={"Membrane Filter Holders"} productPrice={""} carImg={[image]}>
            <ProductTableCol leftValue={"Minimum Order Quantity"} rightValue={"10 Unit"} />
                <ProductTableCol leftValue={"Application"} rightValue={"For Sterile Filtration of pharmaceuticals and Biological solutions"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Size"} rightValue={"293,142,90 mm"} />
                <ProductTableCol leftValue={"Finishing Type"} rightValue={"Mirror polished to 220 grit"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 316"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are leading supplier of Membrane Filter Holders."} />
            <OtherDetails title={"Specifications:"} />
            <Lists>
                <ListValue value={`Construction: Rigid construction with internationally accepted GMP standard`} />
                <ListValue value={`Design: Unipack Filter Holders are decorously designed to support the membranes against the applied pressure differential.`} />
            </Lists>
            <OtherDetails title={"Additional Information:"} />
            <Lists>
                <ListValue headHighlight headValue={"Item Code"} value={`90mmStd`} />
            </Lists>
        </>
    )
}

export default MembraneHolders
