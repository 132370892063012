import React from 'react'
import {Container,Row,Col} from "react-bootstrap"

function ProductFeatures({productFeatures}) {
    return (
        <>
        <Container fluid>
            <Row>
                <Col md={12}>
                    <span className="block font-cario font-bold text-2xl my-3" style={{color:"var(--darkBlue)"}}>Features:</span>
                    <div>
                        <span className="prodFeatures-desktop block text-lg font-cario font-bold text-gray-500">
                            {productFeatures?.map((prodFeature) => {
                                return <>{prodFeature} <span className="features-line mx-2">|</span> </>
                            })}
                        </span>



                        <span className="prodFeatures-mobile block text-lg font-cario font-bold text-gray-500 pl-4">
                            {productFeatures?.map((prodFeature) => {
                                return <>{prodFeature} <br/> </>
                            })}
                        </span>
                    </div>
                </Col>
            </Row>
        </Container>   
        </>
    )
}

export default ProductFeatures
