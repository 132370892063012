import Certificate from "../../components/certificate/Certificate"
import Clients from "../../components/clients/Clients"
import Facility from "../../components/facility/Facility"
import Hero from "../../components/Hero/Hero"
import HomeAbout from "../../components/homeAbout/HomeAbout"
import MakeIndia from "../../components/Makeindia/MakeIndia"
import Testimony from "../../components/testimony/Testimony"
import AutoPopup from "./AutoPopup"

function Home() {
    return (
        <>
           <Hero/>
           <HomeAbout/>
           <Certificate/>
           <MakeIndia/>
           <Facility/>
           <Clients/>
           <Testimony/>
           <AutoPopup/>
        </>
    )
}

export default Home
