import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/filteration-equipments/07.jpg"


function GlucoseSyrupFiltration() {
    return (
        <>
            <ProductDetailHead productTitle={"Glucose Syrup Filtration"} productPrice={"Approx. Rs 10 Lakh / Unit"} carImg={[image]}>
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Frequency"} rightValue={"50Hz"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Size"} rightValue={"20 cms x 20 cms 40 cms x 40 cms and 60 cmz x 60 cms"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"Pharmaceuticals, Chemicals, and bacteriological products"} />
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"We are counted among the renowned manufacturers, exporters, distributors, and suppliers of the comprehensive range of Glucose Syrup Filtration. Our complete range of syrup filtration machine is designed with top quality of material in accordance with the market norms. The offered syrup filtration machine is broadly applauded for its performance long life also examined on quality parameters for ensuring its flawless range. Clientele can purchase the whole range of syrup filtration machine at pocket-friendly rates."} />
            <ProductFeatures productFeatures={["Sturdiness", "Robust construction", "Precisely designed"]} />
            <OtherDetails title={"Other Details:"} />
            <Lists>
                <ListValue value={`Varied designed for the clarification of Pharmaceuticals, Chemicals, and bacteriological products`} />
                <ListValue value={`These find a wide range of application in the beverage brewry, distillery plastics, dyestuff alcohol, gelatin and many other industries`} />
                <ListValue value={`The filtration unit consists of several parts of filter plates and frames arranged vertically on`} />
                <ListValue value={`S.S. Chasis between a pair of fixed body and moving body, so that alternate sets are`} />
                <ListValue value={`connected through sockets forming separate channels for inlet and outlet.`} />
                <ListValue value={`The fixed body has the main inlet and outlet connections mounted on it`} />
                <ListValue value={`While the moving body has a tightening screw connected to a power pack mechanism for higher sizes of filter presses`} />
            </Lists>
        </>
    )
}

export default GlucoseSyrupFiltration
