import React from "react";
import { Row, Col } from "react-bootstrap";
import g1 from "../../assets/images/gallery/Factory01.jpg";
import g2 from "../../assets/images/gallery/Factory02.jpg";
import g3 from "../../assets/images/gallery/Factory03.jpg";
import g4 from "../../assets/images/gallery/Factory04.jpg";
import g5 from "../../assets/images/gallery/Factory05.jpg";
import g6 from "../../assets/images/gallery/Factory06.jpg";
import g7 from "../../assets/images/gallery/Factory07.jpg";
import g8 from "../../assets/images/gallery/Factory08.jpg";

function Factory() {
  return (
    <>
      <div data-toggle="modal" data-target="#factoryModal">
        
        <Row className="mx-0">
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g1} class="thumbnail" value="FactoryImage1">
                <img src={g1} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="0"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g2} class="thumbnail" value="FactoryImage2">
                <img src={g2} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="1"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g3} class="thumbnail" value="FactoryImage3">
                <img src={g3} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="2"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g4} class="thumbnail" value="FactoryImage4">
                <img src={g4} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="3"/>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g5} class="thumbnail" value="FactoryImage5">
                <img src={g5} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="4"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g6} class="thumbnail" value="FactoryImage6">
                <img src={g6} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="5"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g7} class="thumbnail" value="FactoryImage7">
                <img src={g7} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="6"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className=" m-1" >
              <a href={g8} class="thumbnail" value="FactoryImage8">
                <img src={g8} alt="" className="h-auto md:h-full object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="7"/>
              </a>
            </div>
          </Col>
        </Row>
  
      </div>
  
  
  
      <div class="modal fade" id="factoryModal" tabIndex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog intermodal modal-dialog-centered" role="document">
          <div class="modal-content w-auto">
            <div class="modal-body">
      
              <div id="myCarouselB" class="carousel slide gallery-carousel" data-interval="false">
                {/* Indicators */}
                {/* <ol class="carousel-indicators">
                  <li data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                  <li data-target="#myCarousel1" data-slide-to="1"></li>
                  <li data-target="#myCarousel1" data-slide-to="2"></li>
                  <li data-target="#myCarousel1" data-slide-to="3"></li>
                  <li data-target="#myCarousel1" data-slide-to="4"></li>
                </ol> */}

                <div class="carousel-inner">
                  <div class="carousel-item active" value="FactoryImage1">
                    <img src={g1} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage2">
                    <img src={g2} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage3">
                    <img src={g3} alt="..." />
                  </div>

                  <div class="carousel-item" value="FactoryImage4">
                    <img src={g4} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage5">
                    <img src={g5} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage6">
                    <img src={g6} alt="..." />
                  </div>

                  <div class="carousel-item" value="FactoryImage7">
                    <img src={g7} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage8">
                    <img src={g8} alt="..." />
                  </div>
                </div>
      
                {/* Left and right controls */}
                <a class="carousel-control-prev" href="#myCarouselB" data-slide="prev">
                  <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#myCarouselB" data-slide="next">
                  <span aria-hidden="true" class="carousel-control-next-icon"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Factory;
