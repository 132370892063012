import React from 'react'
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import profileImg from "../../assets/images/CEO1.jpg"

function Team01() {
    return (
        <>
            <div className="team-content">
                <Row className="team-content-row m-4 justify-center">
                    <Col md={3}>
                        <div className="team-left border-2 border-gray-200 ">
                            <div className="team-img">
                                <Image src={profileImg} className="w-full"/>
                            </div>
                            <div className="team-content">
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-2 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Name : </span>
                                    Mr. Jaipal Shah
                                </div>
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-2 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Designation : </span>
                                    Managing Director
                                </div>
                                <div className="team-text font-cario font-medium text-base md:text-lg mx-2 py-2 border-b-2 border-gray-200">
                                    <span className="font-bold">Qualification : </span>
                                    BSC (Bhavans College-1976)
                                </div>
                                <Link   to="/contact"
                                    className="submitform flex w-full p-3 font-cario font-bold text-white justify-center"
                                    style={{ backgroundColor: "var(--darkBlue)" }}
                                >
                                    CONTACT NOW
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="team-right">
                            <div className="team-thought font-poppins text-white bg-black p-4">
                                <span className="font-poppins font-bold text-4xl absolute">“</span>
                                <p className="mb-0 pl-3 pt-3">Its my job to bring out the best from my team. 
                                    I get extraordinary work done from ordinary people</p> 
                            </div>
                            <div className="team-experience">
                                {/* <span className="font-bold text-3xl">Experience</span> */}
                                <p className="font-cario font-medium text-base md:text-lg margin-t48 mb-3" style={{ color: "var(--gray)" }}>
                                An entrepreneur by passion par excellence , was in Rotaract club since 1972 to 1976, 
                                serving as president in 1975-76. Joined rotary in 1984 and became president in 1988. 
                                Assistant Governor since 2003 and district 3141 official since last 38yrs. Is also past 
                                president of Bombay Industries Association from 2004 to 2006. Joined in 1999. Founder 
                                President of GIA (Gowlais Industries Association) from 1998 - 2000. Fund raising chairman 
                                of handicap association of M/S V.S Gandhi Charitable Trust in Gujarat Kapadwanj since 2008. 
                                Collected Millions of donations for charity.
                                </p>
                                <p className="font-cario font-medium text-base md:text-lg" style={{ color: "var(--gray)" }}>
                                Given 500+ lectures on motivation the moving force and effective communication is a fine art.
                                Won 285 award certificates and honors from above associations and involved in several charitable projects. 
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Team01
