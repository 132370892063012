import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductTableHead3 from '../../ProductComponents/ProductTableHead3'
import ProductTableCol3 from '../../ProductComponents/ProductTableCol3'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/packaging-machinery/04.jpg"

function DepyrogenationTunnel() {
    return (
        <>
            <ProductDetailHead productTitle={"Depyrogenation Tunnel"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Model"} rightValue={"Belt width 450 mm, 600 mm, 750 mm & 900 mm"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Output/Size"} rightValue={"900 mm belt width - 240 vials per minute for 25 mm dia"} />
                <ProductTableCol leftValue={"Tunnel Size/ Weight"} rightValue={"900 mm belt width-.(4875L x 1800W x 2350H)/3500 Kgs"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"Unipack is leading supplier for Pharmaceutical Depyrogenation Tunnels, which are particularly used in food & beverages, cosmetics and pharmaceutical industries. Our design of tunnel meets the cGMP norms and operation of same is automatic through PLC and touch screen HMI.The Tunnel is consists following zones."} />
            <OtherDetails title={"The Drying zone:"} />
            <ProductDescription productDescription={"This is the first zone between vial washing and stabilizing zone , the drying zone creates thermal barrier to protect the vials from contamination and pre heat the vials say 80-90 degree centigrade temp. For some hot air, the pressure difference is adjusted to flow from sterilizing zone to preheating zone. Hence, drying and pre heating of the vials take place. "} />
            <OtherDetails title={"The sterilizing zone:"} />
            <ProductDescription productDescription={"After passing from Drying zone, vials are passed through sterilizing through controlling air velocity and gradual rise in temp. wire mesh conveyor speed is set so that all vials  get heated up uniformly up to 350 degree centigrade temp. Hot air is blown through turbine blower to distribute uniformly hot air jet through Hepa Filters provided at appropriate place and length of sterilizing zone is such that all vials temp. Set at any level below 350 Degree Centigrade, the sterilizing zone can be heated. The air velocity can be adjusted. To obtain a Log-3 pyrogen reduction accordingly, the thermal cycle is designed. "} />
            <OtherDetails title={"The cooling zone:"} />
            <ProductDescription productDescription={"Vials enter into cooling zone from sterilizing zone where they are subjected to cold laminar air for lowering the room temperature. Through exhaust blower fitted down the belt, the hot air can be exhausted. "} />
            <OtherDetails title={"The stabilizing zone:"} />
            <ProductDescription productDescription={"To maintain negative pressure in washing room and filling room, the stabilizing zone with cold laminar air flow stabilizing zone is used."} />
            <OtherDetails title={"The control panel:"} />
            <ProductDescription productDescription={"The Control panel S.S.304 is designed such that all set parameter controls through PLC. Interlocking and safeties are provided at for smooth operation of tunnel. Temp. indicators and air velocity dial gages are mounted on tunnel to see the status of different zones while tunnel is in operation. For higher thermal efficiency & power saving, thyristorised temperature control unit is used."} />
            <div className="px-3 my-5">
                <ProductTableHead3 head1={"Power Requirement"} head2={"Start up Load"} head3={"Regulating Load"} />
                <ProductTableCol3 leftValue={"450 mm belt width -"} middleValue={"40 HP"} rightValue={"18 HP"} />
                <ProductTableCol3 leftValue={"600 mm belt width -"} middleValue={"55 HP"} rightValue={"25 HP"} />
                <ProductTableCol3 leftValue={"750 mm belt width -"} middleValue={"65 HP"} rightValue={"30 HP"} />
                <ProductTableCol3 leftValue={"900 mm belt width -"} middleValue={"75 HP"} rightValue={"35 HP"} />
            </div>
            <OtherDetails title={"Silent Features:"} />
            <ProductDescription productDescription={"For dehydrogenation & on line sterilization for washed vials & ampoules, stericlean hot laminar flow sterilization tunnel is used for washed Vials and Ampoules.Class-100 laminar air flow throughout drying, sterilizing, cooling & stabilizing zoneThyristorised temperature control for high thermal efficiency & Power Saving with uniform temperature in hot zone Automatic Control Through PLC and online printing."} />
            <OtherDetails title={"Tunnel for Ampoule"} />
            <Lists>
                <ListValue value={`Standard model– 300 mm and 450 mm`} />
                <ListValue value={`300 mm tunnel-  Rated output 250 Ampoules per minute. (1,2,3 ml) `} />
                <ListValue value={`450 mm  tunnel– Rated out put 300 Ampoules (1,2,3 ml)`} />
                <ListValue value={`300 mm tunnel-  Rated output 180 Ampoules per minute. (5 ml)`} />
                <ListValue value={`450 mm  tunnel– Rated out put 240 Ampoules (5 ml)`} />
                <ListValue value={`300 mm tunnel- Rated output 125 Ampoules per minute.(10 ml)`} />
                <ListValue value={`450 mm tunnel– Rated out put 200 Ampoules (10 ml)`} />
            </Lists>
        </>
    )
}

export default DepyrogenationTunnel
