import React from "react";
import { Row, Col } from "react-bootstrap";
import "./gallery.css"

function Events() {
  return (
    <>
      <Row>
        <Col md={12} className="mt-4">
          <div className="text-center flex justify-center">
            <p className="text-indigo-900 text-5xl m-20 font-bold">COMING SOON...</p>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Events;
