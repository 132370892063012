import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/filter-press/02.jpg"

function PlateFrame() {
  return (
    <>
      <ProductDetailHead productTitle={"Plate & Frame Type Filter Press Machine"} productPrice={"Approx. Rs 5 Lakh / Onwards"} carImg={[image]}>
        <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
        <ProductTableCol leftValue={"Filtration Capacity"} rightValue={"From 1000 to 4000 liters/hr, 5000 to 25000 liter/hr"} />
        <ProductTableCol leftValue={"Body Material"} rightValue={"Stainless Steel"} />
        <ProductTableCol leftValue={"Finishing"} rightValue={"Mirror finish with electro-polished plates, frames & screen"} />
        <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
        <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
      </ProductDetailHead>
      <ProductDescription productDescription={"We are a noteworthy organization in the domain, engaged in providing a high-quality range of Frame Type Filter Press Machine. The offered filter press is broadly used in various industries for its durability. This filter press is developed by our team of skillful professionals by utilizing finest quality raw materials and latest technology in accordance with universally accepted standards. Available in diverse technical specifications, we provide this filter press in customized options in order to fulfill the needs of our valued customers."} />
      <ProductFeatures productFeatures={["Long service life", "Excellent operator efficiency", "High strength body"]} />
      <OtherDetails title={"Other Details:"} />
      <Lists>
        <ListValue value={`Microbiological reliability`}/>
        <ListValue value={`Universal application`}/>
        <ListValue value={`Double filtration in one system`}/>
        <ListValue value={`Individual adaption of outputs`}/>
        <ListValue value={`Delivery Time: 2 months`}/>
        <ListValue value={`Packaging Details: Unipack Locally transports all its machinery and also exports to various countries like Africa, UK, USA Asian countries as well`}/>
        <ListValue value={`Horizontal square body having vertical plates frames fitted one behind the other making parallel flow passage`}/>
        <ListValue value={`Design: Universally accepted vertical plate frame type`}/>
      </Lists>
      <OtherDetails title={"Additional Information:"} />
      <Lists>
          <ListValue headHighlight headValue={`Delivery Time:`} value={`2 months`} />
          <ListValue headHighlight headValue={`Packaging Details:`} value={`Unipack Locally transports all its machinery and also exports to various countries like Africa, UK, USA & Asian countries as well.`} />
      </Lists>
    </>
  )
}

export default PlateFrame
