import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from '../../ProductCard';
import ProductHeadDescription from '../../ProductHeadDescription';
import image1 from "../../../../assets/images/product/unipack-membrane-filter-holder/01.jpg";
import image2 from "../../../../assets/images/product/unipack-membrane-filter-holder/03.jpg";
import image3 from "../../../../assets/images/product/unipack-membrane-filter-holder/08.jpg";

function UnipackMembraneFilterHolder() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <ProductHeadDescription heading={"Unipack Membrane Filter Holder"} description={"Our range of products include unipack 142mm membrane filter holder and unipack 90mm membrane filter holder."} />
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image3} productName={`Unipack 293mm Membrane Filter Holder`} link={"/products/unipack-membrane-filter-holder/unipack-293mm-membrane-filter-holder"} />
                    </Col>
                    <Col md={4} >
                        <ProductCard productImg={image1} productName={`Unipack 142mm Membrane Filter Holder`} link={"/products/unipack-membrane-filter-holder/unipack-142mm-membrane-filter-holder"} />
                    </Col>
                    <Col md={4}>
                        <ProductCard productImg={image2} productName={`Unipack 90mm Membrane Filter Holder`} link={"/products/unipack-membrane-filter-holder/unipack-90mm-membrane-filter-holder"} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UnipackMembraneFilterHolder
