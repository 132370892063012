import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/packaging-machinery/02.png"


function SyrupBlendingMachine() {
    return (
        <>
            <ProductDetailHead productTitle={"Syrup Blending Machine"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Material"} rightValue={"Stainless Steel"} />
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Speed"} rightValue={"Geared Motor Agitator 52 RPM"} />
                <ProductTableCol leftValue={"Applications"} rightValue={"In Pharmaceutical"} />
                <ProductTableCol leftValue={"Material Grade"} rightValue={"SS 304"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"By keeping track with the market developments, we are involved in offering Syrup Blending Machine. Our offered item is developed under the management of skilled professionals according to the set industry norms by utilizing the quality proven basic material. For ensure their quality, the complete range is severely checked on diverse parameters. Offered item is accessible in various stipulations and is also provided in customized options for gaining the complete satisfaction of our customers."} />
            <ProductFeatures productFeatures={["High efficiency", "Sturdiness","Dimensional accuracy"]} />
            <OtherDetails title={"Specifications:"} />
            <Lists>
                <ListValue value={`SS304 Construction Un-insulated Tank`} />
                <ListValue value={`SS304 shell SS304 Top and Bottom Conical Dish/Cone end`} />
                <ListValue value={`Suitable SS304 Legs 3/4 Nos. With height adjusting Ball feet`} />
                <ListValue value={`Geared Motor Agitator 52 RPM mounted on SS frame housing`} />
                <ListValue value={`Manhole with Sight Glass. (For Visual Inspection.)`} />
                <ListValue value={`Light Glass Toughened Hygienic Calibrated Sight Glass tube for Level Measurement of CIP Connection`} />
                <ListValue value={`CIP Spray Ball for Tank Cleaning. Inlet/Outlet connections.`} />
                <ListValue value={`Air Vent with Safety Cap. Other Accessories -Lifting Hooks 2 Nos`} />
                <ListValue value={`Radial Jet mixing system also offered`} />
            </Lists>
        </>
    )
}

export default SyrupBlendingMachine