import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image1 from "../../../../assets/images/product/unipack-tape-slicing-machine/01.jpg"
import image2 from "../../../../assets/images/product/unipack-tape-slicing-machine/02.jpg"

function TapeSlicingMachine() {
    return (
        <>
            <ProductDetailHead productTitle={"Unipack Automatic Adhesive Tape Slicing Machine"} productPrice={""} carImg={[image1]}>
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Power"} rightValue={"7 Kw"} />
                <ProductTableCol leftValue={"Voltage"} rightValue={"415 V"} />
                <ProductTableCol leftValue={"Maximum Width"} rightValue={"1.6 m, 3 mm (Min. Slice)"} />
                <ProductTableCol leftValue={"Diameter"} rightValue={"300 mm (Roll to Slice), 76 mm (Paper Core)"} />
                <ProductTableCol leftValue={"Model Name/Number"} rightValue={"UMP-SM-1.6"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Application"} rightValue={"Slicing of PVC adhesive tape like foam, masking tape, double-sided tissue tape. polyester tape etc."} />
                <ProductTableCol leftValue={"Frequency"} rightValue={"50 Hz"} />
                <ProductTableCol leftValue={"Phase"} rightValue={"Three Phase"} />
            </ProductDetailHead>
            <OtherDetails title={"Features:"} />
            <Lists>
                <ListValue value={`High precision cutting width, user-friendly, servo motor for pitch shaft as well as cutter assy`} />
                <ListValue value={`Circular knife is driven by infinite speed control through variable frequency drive. Variable speed controller for machine main shaft. `} />
                <ListValue value={`Circular cutter provided with dual cooling by liquid as well as moist air.`} />
                <ListValue value={`Utilities requirement- Dry air at 6 bar 5 CFM `} />
                <ListValue value={`Machine is self supporting and does not call for foundation with height adjusting bolts and floor plates.`} />
                <ListValue value={`Machine can adapt without extended core log rolls to get better yield of sliced product. `} />
                <ListValue value={`Operation of machine is automatic through PLC and touch screen HMI.`} />
                <ListValue value={`Diff. slices width facility in PLC so that without stopping machine desired width of slice can be possible. `} />
            </Lists>
        </>
    )
}

export default TapeSlicingMachine
