import React from "react";
import { Row, Col } from "react-bootstrap";
import c1 from "../../assets/images/certificate1.jpg";
import c2 from "../../assets/images/certificate2.jpg";
import c3 from "../../assets/images/certificate3.jpg";
import c4 from "../../assets/images/certificate4.jpg";
function CertificateCar() {
  // const PrevArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       onClick={onClick}
  //       className={`cusArr ${className}`}
  //       style={{ ...style, display: "block" }}
  //     >
  //       <img src={indicator} alt="ind" className={`indClass`} />
  //     </div>
  //   );
  // };
  // const NextArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       onClick={onClick}
  //       className={`cusArr ${className}`}
  //       style={{ ...style, display: "block" }}
  //     >
  //       <img
  //         src={indicator}
  //         alt="ind"
  //         className={`indClass transform rotate-180`}
  //       />
  //     </div>
  //   );
  // };
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   nextArrow: <NextArrow className="cusArr" />,
  //   prevArrow: <PrevArrow className="cusArr" />,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       }
  //     }
  //   ]
  // };
  return (
    <>
      {/* <div>
        <Slider {...settings}>
          <div>
            <img src={cert1} alt="cert" className="" />
          </div>
          <div>
            <img src={cert2} alt="cert" className="" />
          </div>
          <div>
            <img src={cert3} alt="cert" className="" />
          </div>
          <div>
            <img src={cert1} alt="cert" className="" />
          </div>
          <div>
            <img src={cert2} alt="cert" className="" />
          </div>
          <div>
            <img src={cert3} alt="cert" className="" />
          </div>
          <div>
            <img src={cert1} alt="cert" className="" />
          </div>
          <div>
            <img src={cert2} alt="cert" className="" />
          </div>
          <div>
            <img src={cert3} alt="cert" className="" />
          </div>
        </Slider>
      </div> */}

      <div data-toggle="modal" data-target="#certificateModal">
        <Row className="mx-0">
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className="certificate-item" >
              <a href={c1} class="thumbnail" value="FactoryImage1">
                <img src={c1} alt="" className="h-auto md:h-full cert-img object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="0"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className="certificate-item" >
              <a href={c2} class="thumbnail" value="FactoryImage2">
                <img src={c2} alt="" className="h-auto md:h-full cert-img object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="1"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className="certificate-item" >
              <a href={c3} class="thumbnail" value="FactoryImage3">
                <img src={c3} alt="" className="h-auto md:h-full cert-img object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="2"/>
              </a>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="px-0">
            <div className="certificate-item" >
              <a href={c4} class="thumbnail" value="FactoryImage4">
                <img src={c4} alt="" className="h-auto md:h-full cert-img object-cover cursor-pointer" data-target="#myCarouselB" data-slide-to="3"/>
              </a>
            </div>
          </Col>
        </Row>
      </div>
  
  
  
      <div class="modal fade fade-scale" id="certificateModal" tabIndex="-1" role="dialog" aria-hidden="true" style={{padding:"0px"}}>
        <div class="modal-dialog intermodal modal-dialog-centered justify-center" role="document">
          <div class="modal-content w-auto">
            {/* <div class="modal-header"> */}
              {/* <h5 class="modal-title">Modal title</h5> */}
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            {/* </div> */}
            <div class="modal-body certificate-modal-body" style={{padding:"0px",border:"7px solid #262476"}}>
              <button type="button" class="close cert-close-btn" data-dismiss="modal" aria-hidden="true">×</button>
              <div id="myCarouselB" class="carousel slide carousel-fade gallery-carousel" data-interval="false" data-ride="carousel">
                {/* Indicators */}
                {/* <ol class="carousel-indicators">
                  <li data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                  <li data-target="#myCarousel1" data-slide-to="1"></li>
                  <li data-target="#myCarousel1" data-slide-to="2"></li>
                  <li data-target="#myCarousel1" data-slide-to="3"></li>
                  <li data-target="#myCarousel1" data-slide-to="4"></li>
                </ol> */}

                <div class="carousel-inner">
                  <div class="carousel-item active" value="FactoryImage1">
                    <img src={c1} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage2">
                    <img src={c2} alt="..." />
                  </div>
      
                  <div class="carousel-item" value="FactoryImage3">
                    <img src={c3} alt="..." />
                  </div>

                  <div class="carousel-item" value="FactoryImage4">
                    <img src={c4} alt="..." />
                  </div>
                </div>
      
                {/* Left and right controls */}
                <a class="carousel-control-prev" href="#myCarouselB" data-slide="prev" id="cert-carousel-prev">
                  <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#myCarouselB" data-slide="next" id="cert-carousel-next">
                  <span aria-hidden="true" class="carousel-control-next-icon"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CertificateCar;
