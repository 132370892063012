import React from 'react'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import OtherDetails from '../../ProductComponents/OtherDetails'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import image from "../../../../assets/images/product/acid-filtration-system/01.jpg"
function AcidFiltrationSystem() {
    return (
        <>
            <ProductDetailHead productTitle={"Acid Filtration System"} productPrice={""} carImg={[image]}>
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack Machines"} />
                <ProductTableCol leftValue={"Material"} rightValue={"High Alloy Stainless Steel"} />
                <ProductTableCol leftValue={"Application"} rightValue={"These vessels are used in Membrane filtration system"} />
                <ProductTableCol leftValue={"Toughened Calibrated Side Glass Tube size"} rightValue={"75 mm"} />
                <ProductTableCol leftValue={"Inner shell thickness"} rightValue={"For 500 L, 1, 1.5, 2 kl - 2 mm; For 3 kl- 2.5 mm"} />
                <ProductTableCol leftValue={"Orientation"} rightValue={"Vertical"} />
            </ProductDetailHead>
            <ProductDescription productDescription={"With the help of our worker's professionals, we provide our clients a high-quality Acid Filtration System. The offered items offered by us are developed by utilizing top grade material and advance technology in accordance with latest industrial standards. Along with that, these items are widely known in the market for its durability. Customers can take the complete range of items at nominal rates."} />
            <ProductFeatures productFeatures={["Highly durable", "Corrosion-resistance", "Sturdy design"]} />
            <OtherDetails title={"Specifications:"} />
            <Lists>
                <ListValue value={`SS304 Construction Insulated Tank.`} />
                <ListValue value={`SS304 Top and Bottom Cone/ Dish end (2 mm up to 1.5 kl 2.5 for 2-3 kl respectively)`} />
                <ListValue value={`Suitable SS304 Legs 3/4 Nos. with height adjusting Ball feet`} />
                <ListValue value={`SS304 Outer Cladding up to top Dish end [For 1 1.5 kl 1.6 mm, For 2 kl – 3 kl- 2 mm Thick]`} />
                <ListValue value={`Rockwool Insulation 75 mm Toughened Calibrated Side Glass Tube for Level Measurement`} />
                <ListValue value={`SS304 Magnetic Float ball Micro Switches vertical type (For High Level and Low level)`} />
                <ListValue value={`Inlet/Outlet connections. Bottom Drain. Overflow. Air Vent with Safety valve. Other Accessories Connections`} />
                <ListValue value={`Thermowell Pocket Sampling valve Lifting Hooks 2 Nos.`} />
            </Lists>
        </>
    )
}

export default AcidFiltrationSystem
