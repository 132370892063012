import React from 'react'
import Modal from 'react-bootstrap/Modal'
import "./homeAbout.css"

function IFrameModal3(props) {

    return (
        <>
            <Modal
                {...props}
                dialogClassName="video-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="iframe-content">
                        <iframe 
                            src="https://www.youtube.com/embed/iLYFgNu4BZg" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default IFrameModal3
