import React from 'react'
import ProductDescription from '../../ProductComponents/ProductDescription'
import ProductDetailHead from '../../ProductComponents/ProductDetailHead'
import ProductTableCol from '../../ProductComponents/ProductTableCol'
import ProductFeatures from '../../ProductComponents/ProductFeatures'
import OtherDetails from '../../ProductComponents/OtherDetails'
import Lists from '../../../../components/Lists/Lists'
import ListValue from '../../../../components/Lists/ListValue'
import image from "../../../../assets/images/product/filter-press/01.jpg"

function ZeroHoldupFilterPress() {
    return (
        <>
            <ProductDetailHead productTitle={"Zero Hold Up Filter Press Machine"} productPrice={"Approx. Rs 3 Lakh / Onwards"} carImg={[image]}>
                <ProductTableCol leftValue={"Automation Grade"} rightValue={"Automatic"} />
                <ProductTableCol leftValue={"Brand"} rightValue={"Unipack"} />
                <ProductTableCol leftValue={"Country of Origin"} rightValue={"Made in India"} />
                <ProductTableCol leftValue={"Material"} rightValue={"AISI S.S. 304 / AISI SS 316 / AISI SS 316L"} />
                <ProductTableCol leftValue={"Motor power"} rightValue={"1,2,3,5 HP"} />
                <ProductTableCol leftValue={"Type"} rightValue={"Portable"} />
            </ProductDetailHead>
            {/* <ProductDescription productDescription={"We are instrumental in providing our clients a comprehensive range of excellent quality Zero Hold Up Filter Press Machine. Our complete range of filter press is designed with top quality of material in accordance with the market norms. The offered filter press is broadly applauded for its performance long life also examined on quality parameters for ensuring its flawless range. Clientele can purchase the whole range of filter press at pocket-friendly rates."} />
            <ProductFeatures productFeatures={["Low noise operation", "High output results", "Consume less power","Finish: Mirror finish"]} />
            <OtherDetails title={"Description :"} />
            <Lists>
                <ListValue value={`Zero hold up Horizontal plate closed pressure filter that meets all the demands for a filtration which would discharge solid free filtrate`} />
                <ListValue value={`Design of filter press is according to cGMP FDA approval`} />
            </Lists>
            <OtherDetails title={"Other Details :"} />
            <Lists>
                <ListValue value={`Components are argon arc welded polished to mirror finish`} />
                <ListValue value={`SS jacket can be provided for hot filtration. (Optional)`} />
                <ListValue value={`Provides crystal clear filtration by filtering particles up to 1-micron size`} />
                <ListValue value={`No Scavenger arrangement is required`} /> 
                <ListValue value={`Top dome structure ensures fast easy removal of cartridge assembly cleaning of cake`} />
                <ListValue value={`Uniform filter aids cake distribution`} />
                <ListValue value={`SS pump mounted on SS trolley with heavy duty thrust ball bearing caster wheels for easy movement`} />                
                <ListValue value={`Provided with different types of pumps depending on the viscosity of liquid output i.e. Centrifugal Pump, Gear Pump, Lobe Pump, Roto Pump Etc`} />
                <ListValue value={`Provided with bypass system, safety valve sample collecting device`} />
                <ListValue value={`No contamination of internal surface of the shell`} />
                <ListValue value={`Horizontal plate Zero Hold Up Filter Press is constructed in such a manner that it prevents evaporation, Oxidation, Leakage Fumes escaping from product`} />
                <ListValue value={`Plates are available in two sizes: Deep plate for higher percentage of caking holding capacity shallow plate for lesser percentage of cake holding capacity`} />
                <ListValue value={`Filter plate thickness of cake prevents the cake dropping as well as cracking, thus assuring better filtrate quality`} />
                <ListValue value={`According to requirement filtration area cake holding capacity can be increased or decreased`} />
            </Lists> */}
            <ProductDescription productDescription={`"UNIPACK" Zero Hold-Up Horizontal plate closed pressure filter that meets all the demands for a filtration which would discharge solid free filtrate. Design of Filter Press is according to CGMP & FDA approval.`}/>
            <OtherDetails title={"Salient Features:"}/>
            <Lists>
                <ListValue value={`All Contact Parts are in AISI SS 304 / AISI SS 316 / AISI SS 316L.`}/>
                <ListValue value={`Components are Argon Arc welded & polished to Mirror finish.`}/>
                <ListValue value={`SS Jacket can be provided for hot filtration. (Optional).`}/>
                <ListValue value={`Provides crystal clear filtration by filtering particles upto 5 Micron Size.`}/>
                <ListValue value={`No Scavenger arrangement is required.`}/>
                <ListValue value={`No liquid between the Tank & the Cartridge.`}/>
                <ListValue value={`Top dome structure ensures fast & easy removal of cartridge assembly & cleaning of cake.`}/>
                <ListValue value={`Uniform filter aids & cake distribution.`}/>
                <ListValue value={`SS Pump mounted on SS Trolley with "UNIPACK" make heavy duty thrust ball bearing castor wheels for easy movement.`}/>
                <ListValue value={`Provided with different types of pumps depending on the viscosity of liquid & output i.e. Centrifugal Pump, Gear Pump, Lobe Pump, Roto Pump etc.`}/>
                <ListValue value={`Provided with Safety valve, air vent valve, pressure gauge and sampling valve.`}/>
                <ListValue value={`No contamination of internal surface of the shell.`}/>
            </Lists>
            <OtherDetails title={"Special Features:"} />
            <Lists>
                <ListValue value={`Horizontal Plate Zero Hold-Up Filter Press is constructed in such a manner that it prevents evaporation, oxidation, leakage & fumes escaping from product.`}/>
                <ListValue value={`Plates are available in two sizes - Deep Plate for higher percentage of caking holding capacity & Shallow Plate for lesser percentage of cake holding capacity.`}/>
                <ListValue value={`Filter plate & thickness of cake prevents the cake dropping as well as cracking, thus assuring better filtrate quality.`}/>
                <ListValue value={`According to requirement of filtration area & cake holding capacity, rated output of filtrate liquid can be increased or decreased (Refer table for diff. models VS diff. capacities)`}/>
            </Lists>
        </>
    )
}

export default ZeroHoldupFilterPress
