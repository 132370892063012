import "./App.css";
import {BrowserRouter as Router,Route,Switch} from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import Contact from "./pages/contact/Contact";
import Enquiry from "./components/Enquiry/Enquiry";
import Careers from "./pages/careers/Careers";
import Gallery from "./pages/gallery/Gallery";
import History from "./pages/about/History";
import Clients from "./pages/clients/Clients";
import Products from "./pages/Products/Products";
// import ZeroHold from "./pages/Products/AllProducts/ZeroHold";
import FilterPress from "./pages/Products/AllProducts/FilterPress/FilterPress";
import MembraneFilterHolder from "./pages/Products/AllProducts/Membrane-filter-holder/MembraneFilterHolder";
import Filtrationequipments from "./pages/Products/AllProducts/filtration-equipments/Filtration-equipments";
import UnipackFilterPress from "./pages/Products/AllProducts/unipack-filter-press/UnipackFilterPress";
import Packagingmachinery from "./pages/Products/AllProducts/packaging-machinery/Packagingmachinery";
import Sterilitytestingunit from "./pages/Products/AllProducts/sterility-testing-unit/Sterilitytestingunit";
import Inlinefilterholder from "./pages/Products/AllProducts/inline-filter-holder/Inlinefilterholder";
import Pharmaceuticalmachinery from "./pages/Products/AllProducts/pharmaceutical-machinery/Pharmaceuticalmachinery";
import UnipackFilterHolder from "./pages/Products/AllProducts/unipack-filter-holder/UnipackFilterHolder";
import UnipackTestingUnit from "./pages/Products/AllProducts/unipack-testing-unit/UnipackTestingUnit";
import UnipackMembraneFilterHolder from "./pages/Products/AllProducts/unipack-membrane-filter-holder/UnipackMembraneFilterHolder";
import TapeSlicingMachine from "./pages/Products/AllProducts/tape-slicing-machine/TapeSlicingMachine";
import FoodMachine from "./pages/Products/AllProducts/Food-Machine/FoodMachine";
import AcidFiltrationSystem from "./pages/Products/AllProducts/Acid-Filtration-System/AcidFiltrationSystem";
import StorageVessel from "./pages/Products/AllProducts/Storage-Vessel/StorageVessel";
// import ProjectManagement from "./pages/Products/AllProducts/project-management/ProjectManagement";
import PlateFrame from "./pages/Products/AllProducts/FilterPress/PlateFrame";
import PlateFrameFilter from "./pages/Products/AllProducts/FilterPress/PlateFrameFilter";
import Polypropylene from "./pages/Products/AllProducts/FilterPress/Polypropylene";
import PlateFilterPress from "./pages/Products/AllProducts/FilterPress/PlateFilterPress";
import SparklerFilterPress from "./pages/Products/AllProducts/FilterPress/SparklerFilterPress";
import MembraneFilter90 from "./pages/Products/AllProducts/Membrane-filter-holder/MembraneFilter90";
import BtaDiaphragm from "./pages/Products/AllProducts/Membrane-filter-holder/BtaDiaphragm";
import Bta142mm from "./pages/Products/AllProducts/Membrane-filter-holder/Bta142mm";
import Bta90mm from "./pages/Products/AllProducts/Membrane-filter-holder/Bta90mm";
import MembraneHolders from "./pages/Products/AllProducts/Membrane-filter-holder/MembraneHolders";
import MembraneHolders293 from "./pages/Products/AllProducts/Membrane-filter-holder/MembraneHolders293";
import TurnKeyProject from "./pages/Products/AllProducts/project-management/TurnKeyProject";
import Unipack293mm from "./pages/Products/AllProducts/unipack-membrane-filter-holder/Unipack293mm";
import Unipack142mm from "./pages/Products/AllProducts/unipack-membrane-filter-holder/Unipack142mm";
import Unipack90mm from "./pages/Products/AllProducts/unipack-membrane-filter-holder/Unipack90mm";
import UnipackSingle from "./pages/Products/AllProducts/unipack-testing-unit/UnipackSingle";
import UnipackThree from "./pages/Products/AllProducts/unipack-testing-unit/UnipackThree";
import UnipackSix from "./pages/Products/AllProducts/unipack-testing-unit/UnipackSix";
import Unipack25mm from "./pages/Products/AllProducts/unipack-filter-holder/Unipack25mm";
import Unipack13mm from "./pages/Products/AllProducts/unipack-filter-holder/Unipack13mm";
import Unipack47mm from "./pages/Products/AllProducts/unipack-filter-holder/Unipack47mm";
import BottleWashing from "./pages/Products/AllProducts/pharmaceutical-machinery/BottleWashing";
import FillingMachine from "./pages/Products/AllProducts/pharmaceutical-machinery/FillingMachine";
import VialFilling from "./pages/Products/AllProducts/pharmaceutical-machinery/VialFilling";
import WireMesh from "./pages/Products/AllProducts/pharmaceutical-machinery/WireMesh";
import InlineFilterHolders from "./pages/Products/AllProducts/inline-filter-holder/InlineFilterHolders";
import SSInline from "./pages/Products/AllProducts/inline-filter-holder/SSInline";
import InlineHolder from "./pages/Products/AllProducts/inline-filter-holder/InlineHolder";
import InlineHolder13 from "./pages/Products/AllProducts/inline-filter-holder/InlineHolder13";
import SparklerFilter from "./pages/Products/AllProducts/filtration-equipments/SparklerFilter";
import SterilizingTunnel from "./pages/Products/AllProducts/filtration-equipments/SterilizingTunnel";
import GlucoseSyrupFiltration from "./pages/Products/AllProducts/filtration-equipments/GlucoseSyrupFiltration";
import SterilePressureVessel from "./pages/Products/AllProducts/filtration-equipments/SterilePressureVessel";
import Environment from "./pages/about/Environment";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Testimonial from "./pages/testimonial/Testimonial";
import DepyrogenerationTunnel from "./pages/Products/AllProducts/filtration-equipments/DepyrogenerationTunnel";
import SterilityTestingUnit from "./pages/Products/AllProducts/filtration-equipments/SterilityTestingUnit";
import SterilityTestingUnits from "./pages/Products/AllProducts/filtration-equipments/SterilityTestingUnits";
import UnipackHydraulic from "./pages/Products/AllProducts/unipack-filter-press/UnipackHydraulic";
import UnipackHydraulicSilicon from "./pages/Products/AllProducts/unipack-filter-press/UnipackHydraulicSilicon";
import UnipackManual from "./pages/Products/AllProducts/unipack-filter-press/UnipackManual";
import UnipackManualSilicon from "./pages/Products/AllProducts/unipack-filter-press/UnipackManualSilicon";
import Mission from "./pages/about/Mission";
import AutomaticTapeSlicing from "./pages/Products/AllProducts/packaging-machinery/AutomaticTapeSlicing";
import SyrupBlendingMachine from "./pages/Products/AllProducts/packaging-machinery/SyrupBlendingMachine";
import MilkStorageTanks from "./pages/Products/AllProducts/packaging-machinery/MilkStorageTanks";
import DepyrogenationTunnel from "./pages/Products/AllProducts/packaging-machinery/DepyrogenationTunnel";
import AutomaticTwoHeadFilling from "./pages/Products/AllProducts/packaging-machinery/AutomaticTwoHeadFilling";
import SterilityTestUnitSingle from "./pages/Products/AllProducts/sterility-testing-unit/SterilityTestUnitSingle";
import SterilityTestingUnitProduct from "./pages/Products/AllProducts/sterility-testing-unit/SterilityTestingUnitProduct";
import SterilityTestingEquipment from "./pages/Products/AllProducts/sterility-testing-unit/SterilityTestingEquipment";
import SugarDissolvingTank from "./pages/Products/AllProducts/sterility-testing-unit/SugarDissolvingTank";
import SterilityTestUnit from "./pages/Products/AllProducts/sterility-testing-unit/SterilityTestUnit";
import ZeroHoldupFilterPress from "./pages/Products/AllProducts/FilterPress/ZeroHoldupFilterPress";
import SparklerTypeFilterPress from "./pages/Products/AllProducts/FilterPress/SparklerTypeFilterPress";
import PlateFrameFilterMachine from "./pages/Products/AllProducts/FilterPress/PlateFrameFilterMachine";
import MembraneFilter293STD from "./pages/Products/AllProducts/Membrane-filter-holder/MembraneFilter293STD";
import MembraneFilter from "./pages/Products/AllProducts/Membrane-filter-holder/MembraneFilter";
import Team from "./pages/about/Team";
import TurnkeyServices from "./pages/turnkey/TurnkeyServices";
import Research from "./pages/rnd/Research";
import PlateandFrameNew from "./pages/Products/AllProducts/FilterPress/PlateandFrameNew";
import SterilityTestNew from "./pages/Products/AllProducts/filtration-equipments/SterilityTestNew";
import InlineFilterNew from "./pages/Products/AllProducts/filtration-equipments/InlineFilterNew";
import MembraneFilterNew from "./pages/Products/AllProducts/filtration-equipments/MembraneFilterNew";
import SugarSyrupNew from "./pages/Products/AllProducts/filtration-equipments/SugarSyrupNew";
import InfoOnRequest from "./pages/Products/ProductComponents/InfoOnRequest";

library.add(fab,fas)

function App() {
  return (
    <div className="app">

  <Router basename={"/umpl"}>
    <Header/>
      <Switch>
        <Route path="/" exact>
           
          <Home/>
          <Enquiry/>
           
        </Route>
        <Route path="/contact" exact>
           
          <Contact/>
           
        </Route>
        <Route path="/rnd" exact>
           
          <Research/>
          <Enquiry/>
           
        </Route>
        <Route path="/careers" exact>
           
          <Careers/>
          <Enquiry/>
           
        </Route>
        <Route path="/turnkey-services" exact>
           
          <TurnkeyServices/>
          <Enquiry/>
           
        </Route>
        <Route path="/gallery" exact>
           
          <Gallery/>
          <Enquiry/>
           
        </Route>
        <Route path="/history" exact>
           
          <History/>
          <Enquiry/>
           
        </Route>
        <Route path="/team" exact>
           
          <Team/>
          <Enquiry/>
           
        </Route>
        <Route path="/environment-responsibility" exact>
           
          <Environment/>
          <Enquiry/>
           
        </Route>
        <Route path="/mission" exact>
         
          <Mission/>
          <Enquiry/>
           
        </Route>
        <Route path="/clients" exact>
           
          <Clients/>
          <Enquiry/>
           
        </Route>
        <Route path="/testimonials" exact>
           
          <Testimonial/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press" exact>
           
          <Products whichProduct={<FilterPress/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/zero-holdup-filter-press" exact>
           
          <Products whichProduct={<ZeroHoldupFilterPress/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/plate-frame-type-filter-press-machine" exact>
           
          <Products whichProduct={<PlateFrame/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/plate-and-frame-filter-press" exact>
           
          {/* <Products whichProduct={<PlateFrameFilter/>}/> */}
          <Products whichProduct={<PlateandFrameNew/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/polypropylene-filter-press" exact>
           
          <Products whichProduct={<Polypropylene/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/plate-filter-press" exact>
           
          <Products whichProduct={<PlateFilterPress/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/sparkler-filter-press" exact>
           
          <Products whichProduct={<SparklerFilterPress/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/sparkler-type-filter-press" exact>
           
          <Products whichProduct={<SparklerTypeFilterPress/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filter-press/plate-frame-filter-press-machine" exact>
           
          <Products whichProduct={<PlateFrameFilterMachine/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/membrane-filter-holder" exact>
           
          {/* <Products whichProduct={<MembraneFilterHolder/>}/> */}
          <Products whichProduct={<MembraneFilterNew/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter-holder-90mm" exact>
           
          <Products whichProduct={<MembraneFilter90/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter-holder-bta-with-diaphragm-valve-gm-valve" exact>
           
          <Products whichProduct={<BtaDiaphragm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter-holder-142mm-bta" exact>
           
          <Products whichProduct={<Bta142mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter-holder-90mm-bta" exact>
           
          <Products whichProduct={<Bta90mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter-holders" exact>
           
          <Products whichProduct={<MembraneHolders/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/293-membrane-filter-holders" exact>
           
          <Products whichProduct={<MembraneHolders293/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter-holder-293mm" exact>
           
          <Products whichProduct={<MembraneFilter293STD/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/membrane-filter-holder/membrane-filter" exact>
           
          <Products whichProduct={<MembraneFilter/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments" exact>
           
          <Products whichProduct={<Filtrationequipments/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/sparkler-filter" exact>
           
          <Products whichProduct={<SparklerFilter/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/sterilizing-tunnel" exact>
           
          <Products whichProduct={<SterilizingTunnel/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/glucose-syrup-filtrattion" exact>
           
          <Products whichProduct={<GlucoseSyrupFiltration/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/sterile-pressure-vessel" exact>
           
          <Products whichProduct={<SterilePressureVessel/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/depyrogeneration-tunnel" exact>
           
          <Products whichProduct={<DepyrogenerationTunnel/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/sterility-testing-unit" exact>
           
          {/* <Products whichProduct={<SterilityTestingUnit/>}/> */}
          <Products whichProduct={<SterilityTestNew/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/sterility-testing-units" exact>
           
          <Products whichProduct={<SterilityTestingUnits/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-press" exact>
           
          <Products whichProduct={<UnipackFilterPress/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-press/unipack-hydraulic-closing" exact>
           
          <Products whichProduct={<UnipackHydraulic/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-press/unipack-hydraulic-closing-silicon" exact>
           
          <Products whichProduct={<UnipackHydraulicSilicon/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-press/unipack-manual-closing" exact>
           
          <Products whichProduct={<UnipackManual/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-press/unipack-manual-closing-silicon" exact>
           
          <Products whichProduct={<UnipackManualSilicon/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/packaging-machinery" exact>
           
          <Products whichProduct={<Packagingmachinery/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/packaging-machinery/automatic-adhesive-tape-slicing" exact>
           
          <Products whichProduct={<AutomaticTapeSlicing/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/packaging-machinery/syrup-blending-machine" exact>
           
          <Products whichProduct={<SyrupBlendingMachine/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/packaging-machinery/zero-hold/milk-storage-tanks" exact>
           
          <Products whichProduct={<MilkStorageTanks/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/packaging-machinery/depyrogenation-tunnel" exact>
           
          <Products whichProduct={<DepyrogenationTunnel/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/packaging-machinery/automatic-two-head-filling" exact>
           
          <Products whichProduct={<AutomaticTwoHeadFilling/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/sterility-testing-unit" exact>
           
          <Products whichProduct={<Sterilitytestingunit/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/sterility-testing-unit/sterility-test-unit-single-place" exact>
           
          <Products whichProduct={<SterilityTestUnitSingle/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/sterility-testing-unit/sterility-testing-unit" exact>
           
          <Products whichProduct={<SterilityTestingUnitProduct/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/sterility-testing-unit/sterility-testing-equipment" exact>
           
          <Products whichProduct={<SterilityTestingEquipment/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/sugar-syrup-filtration" exact>
           
          {/* <Products whichProduct={<SugarDissolvingTank/>}/> */}
          <Products whichProduct={<SugarSyrupNew/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/sterility-testing-unit/sterility-test-unit" exact>
           
          <Products whichProduct={<SterilityTestUnit/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/filtration-equipments/inline-filter-holder" exact>
           
          {/* <Products whichProduct={<Inlinefilterholder/>}/> */}
          <Products whichProduct={<InlineFilterNew/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/inline-filter-holder/inline-filter-holders" exact>
           
          <Products whichProduct={<InlineFilterHolders/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/inline-filter-holder/ss-inline-filter-holder" exact>
           
          <Products whichProduct={<SSInline/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/inline-filter-holder/inline-filter-holder" exact>
           
          <Products whichProduct={<InlineHolder/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/inline-filter-holder/inline-filter-holder-13" exact>
           
          <Products whichProduct={<InlineHolder13/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/pharmaceutical-machinery/linear-bottle-washing-machines" exact>
           
          <Products whichProduct={<BottleWashing/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/pharmaceutical-machinery" exact>
           
          <Products whichProduct={<Pharmaceuticalmachinery/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/pharmaceutical-machinery/powder-filling-machine-rubber-stoppering-and-cap-sealing-machine" exact>
           
          <Products whichProduct={<FillingMachine/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/pharmaceutical-machinery/vial-filling-machine" exact>
           
          <Products whichProduct={<VialFilling/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/pharmaceutical-machinery/stainless-steel-wire-mesh-conveyor" exact>
           
          <Products whichProduct={<WireMesh/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-holder" exact>
           
          <Products whichProduct={<UnipackFilterHolder/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-holder/unipack-25mm-inline-filter-holder" exact>
           
          <Products whichProduct={<Unipack25mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-holder/unipack-47mm-inline-filter-holder" exact>
           
          <Products whichProduct={<Unipack47mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-holder/unipack-13mm-inline-filter-holder" exact>
           
          <Products whichProduct={<Unipack13mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-Unit" exact>
           
          <Products whichProduct={<UnipackTestingUnit/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-Unit/unipack-single-place-sterility-testing-unit" exact>
           
          <Products whichProduct={<UnipackSingle/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-Unit/unipack-three-place-sterility-testing-unit" exact>
           
          <Products whichProduct={<UnipackThree/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-filter-Unit/unipack-six-place-sterility-testing-unit" exact>
           
          <Products whichProduct={<UnipackSix/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-membrane-filter-holder" exact>
           
          <Products whichProduct={<UnipackMembraneFilterHolder/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-membrane-filter-holder/unipack-293mm-membrane-filter-holder" exact>
           
          <Products whichProduct={<Unipack293mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-membrane-filter-holder/unipack-142mm-membrane-filter-holder" exact>
           
          <Products whichProduct={<Unipack142mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-membrane-filter-holder/unipack-90mm-membrane-filter-holder" exact>
           
          <Products whichProduct={<Unipack90mm/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/tape-slicing-machine" exact>
           
          <Products whichProduct={<TapeSlicingMachine/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/zero-hold-up-filter-press-machine-for-food-industry" exact>
           
          <Products whichProduct={<FoodMachine/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/acid-filtration-system" exact>
           
          <Products whichProduct={<AcidFiltrationSystem/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/unipack-pressure-vessel-and-storage-vessel" exact>
           
          <Products whichProduct={<StorageVessel/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/turn-key-project-management" exact>
           
          <Products whichProduct={<TurnKeyProject/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/powder-filling-machine/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/vial-filling-machine/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/wire-mesh-conveyor/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/syrup-blending-machine/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/milk-storage-tank/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/depyrogenation-tunnel/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        <Route path="/products/two-head-vial-filling/info-on-request" exact>
           
          <Products whichProduct={<InfoOnRequest/>}/>
          <Enquiry/>
           
        </Route>
        
      </Switch>
    <Footer/>
  </Router>
    </div>
  );
}

export default App;
