import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Innerbanner from "../../components/InnerBanner/Innerbanner";
import "./testimonial.css";
import historybg from "../../assets/images/banner/testBanner.jpg";
import Carousel from 'react-bootstrap/Carousel'
import profile1 from '../../assets/images/repute1.jpg'
import profile2 from '../../assets/images/unicorn1.png'
import profile3 from '../../assets/images/hccb1.jpg'
import TestimonialContent from './TestimonialContent';

function Testimonial() {
    return (
        <>
            <Innerbanner banner={historybg} heading={`testimonials`}/>
            <Container className="cusContainer my-8">
                <Row>
                    <Col md={12}>
                        <div className="text-center">
                            <h3
                                style={{ borderColor: "var(--gray)" }}
                                className="cert relative font-poppins font-semibold text-lg md:text-2xl border-b pb-2 w-full md:w-96 mx-auto uppercase"
                            >
                                Testimonials
                            </h3>
                        </div>
                    </Col>
                    <Col md={12} className="margin-t48">
                        <div className="text-center">
                            <p className="font-cario font-medium text-base md:text-lg" style={{ color: "var(--gray)" }}>
                                We, Unipack Machines Private Limited, established our operations
                                in the year 1989, as one of the trustworthy manufacturers,
                                suppliers, distributors and exporters of a massive compilation
                                of Machinery and Equipment.
                            </p>
                        </div>
                    </Col>
                    <Col md={12} className="margin-t48">
                        <Carousel className="testCarousel-desktop" variant="dark">
                            <Carousel.Item>
                                <Row>
                                    <Col md={6}>
                                        <TestimonialContent
                                            profImg={profile1}
                                            profQuote={["M/s Unipack Machines Pvt Ltd., Mumbai is an approved vendor for Plate and Frame Filter press and allied equipment for all our CSD and Juice Plants in India since last 10 years to our company M/s Repute Engineers Pvt Ltd.",
                                            "M/s Unipack Machines Pvt Ltd, Mumbai has supplied over 50 numbers of Sparkler type Filter Press and Plate and Frame Filter Presses of capacity ranging from 1000 to 12000 LPH for our various installations in India.", 
                                            "We would like to add that the filtration systems supplied and installed by M/s Unipack Machines Pvt Ltd. Mumbai are working satisfactorily since 2011 till date.", 
                                            "We are very happy with their work and certify that their filter presses are of top quality as per international standards.", 
                                            "They are an ISO 2000 2019 ISO and CE certified company of Repute and our esteemed supplier."]}
                                            profName={"DINESH NAMBIAR"}
                                            profTitle={"Managing Director at Repute Engineers Pvt Ltd"}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TestimonialContent
                                            profImg={profile2}
                                            profQuote={["M/s Unipack Machines Pvt Ltd., Mumbai is an approved vendor for Plate and Frame Filter press and allied equipment for all our CSD and Juice Plants in India since last 31 years to our company Unicorn Industries Private Limited. A Member of KRONES Group.",
                                            "M/s Unpack Machines Pvt Itd, Mumbai has supplied over 50 numbers of Plate and Frame Filter Presses of capacity ranging from 5000 to 30000 LPH for our various installations in India.",
                                            "We would like to add that the filtration systems supplied and installed by M/s Unipack Machines Pvt Ltd. Mumbai are working satisfactorily till date.",
                                            "We are very happy with their work and certify that their filter presses are of top quality as per international standards.",
                                            "They are an ISO 2000 2019 ISO and CE certified company of Repute and our esteemed supplier."]}
                                            profName={"RAJESH SANGHANI"}
                                            profTitle={"Managing Director at Unicorn Industries Private Limited"}
                                        />
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col md={6}>
                                        <TestimonialContent
                                            profImg={profile3}
                                            profQuote={["M/s UNIPACK MACHINES PVT LTD, Mumbai represented by Mr Jaipal Shah and Mr Sacchidanand Shah supplying Plate and Frame Filter Presses to our Bottling Plants for Sugar Syrup Filtration systems from Year 1993 onwards of various capacities are working satisfactorily."]}
                                            profName={"V KISHAN"}
                                            profTitle={"Vice President(Technical) at Hindustan Coca-Cola Bevarages Pvt Ltd"}
                                        />
                                    </Col>
                                    {/* <Col md={6}>
                                        <TestimonialContent
                                            profImg={profile1}
                                            profQuote={` “ During the time I have worked with SCG on this project,
                                            I have found Mike Campbell and his team to be highly knowledgeable and results-driven.
                                            Based on preliminary pilot testing and a strong understanding of subsurface.
                                            conditions, in particular fractured bedrock environments. ased on their work on this project. " `}
                                            profName={"DEREK AUCOIN 1"}
                                            profTitle={"Defence Construction Canada"}
                                        />
                                    </Col> */}
                                </Row>
                            </Carousel.Item>
                        </Carousel>





                        <Carousel className="testCarousel-mobile" variant="dark">
                            <Carousel.Item>
                                <Row>
                                    <Col md={12}>
                                        <TestimonialContent
                                            profImg={profile1}
                                            profQuote={["M/s Unipack Machines Pvt Ltd., Mumbai is an approved vendor for Plate and Frame Filter press and allied equipment for all our CSD and Juice Plants in India since last 10 years to our company M/s Repute Engineers Pvt Ltd.",
                                            "M/s Unipack Machines Pvt Ltd, Mumbai has supplied over 50 numbers of Sparkler type Filter Press and Plate and Frame Filter Presses of capacity ranging from 1000 to 12000 LPH for our various installations in India.", 
                                            "We would like to add that the filtration systems supplied and installed by M/s Unipack Machines Pvt Ltd. Mumbai are working satisfactorily since 2011 till date.", 
                                            "We are very happy with their work and certify that their filter presses are of top quality as per international standards.", 
                                            "They are an ISO 2000 2019 ISO and CE certified company of Repute and our esteemed supplier."]}
                                            profName={"DINESH NAMBIAR"}
                                            profTitle={"Managing Director at Repute Engineers Pvt Ltd"}
                                        />
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col md={12}>
                                        <TestimonialContent
                                            profImg={profile2}
                                            profQuote={["M/s Unipack Machines Pvt Ltd., Mumbai is an approved vendor for Plate and Frame Filter press and allied equipment for all our CSD and Juice Plants in India since last 31 years to our company Unicorn Industries Private Limited. A Member of KRONES Group.",
                                            "M/s Unpack Machines Pvt Itd, Mumbai has supplied over 50 numbers of Plate and Frame Filter Presses of capacity ranging from 5000 to 30000 LPH for our various installations in India.",
                                            "We would like to add that the filtration systems supplied and installed by M/s Unipack Machines Pvt Ltd. Mumbai are working satisfactorily till date.",
                                            "We are very happy with their work and certify that their filter presses are of top quality as per international standards.",
                                            "They are an ISO 2000 2019 ISO and CE certified company of Repute and our esteemed supplier."]}
                                            profName={"RAJESH SANGHANI"}
                                            profTitle={"Managing Director at Unicorn Industries Private Limited"}
                                        />
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col md={12}>
                                        <TestimonialContent
                                            profImg={profile3}
                                            profQuote={["M/s UNIPACK MACHINES PVT LTD, Mumbai represented by Mr Jaipal Shah and Mr Sacchidanand Shah supplying Plate and Frame Filter Presses to our Bottling Plants for Sugar Syrup Filtration systems from Year 1993 onwards of various capacities are working satisfactorily."]}
                                            profName={"V KISHAN"}
                                            profTitle={"Vice President(Technical) at Hindustan Coca-Cola Bevarages Pvt Ltd"}
                                        />
                                    </Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Testimonial
